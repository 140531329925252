import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import AuthRoute from './util/AuthRoute'
import { authHandler } from './util/AuthHandler'

import axios from 'axios';

import home from './pages/home'
import login from './pages/login'
import signup from './pages/signup'
import passwordReset from './pages/passwordReset'
import profile from './pages/profile'
import profileEdit from './pages/profileEdit'
import friends from './pages/friends'
import publicPage from './pages/publicPage'
import privatePage from './pages/privatePage'
import newWishlist from './pages/newWishlist'
import wishlist from './pages/wishlist'
import editWishlist from './pages/editWishlist'
import ProfileSkeleton from './Components/skeletons/ProfileHeaderSkeleton'
import faq from './pages/faq'
import noMatch from './pages/noMatch'
import NavBar from './Components/NavBar'
import ScrollToTop from './util/ScrollToTop'
import SnackbarUtil from './util/Snackbar'

import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from './util/theme'

import { Helmet } from 'react-helmet'

axios.defaults.baseURL = 'https://wishlist-wishit.herokuapp.com/api';

authHandler();

class App extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>WishIt | The Ultimate Wishlist</title>
        </Helmet>
        <Provider store={store}>
          <Router>
            <ScrollToTop />
            <CssBaseline>
              <NavBar />
              <div className='conatiner' style={{marginTop: '0px'}}>
                <Switch>
                  <Route exact path='/' component={home} />
                  <Route exact path='/login' component={login} />
                  <Route exact path='/signup' component={signup} />
                  <Route exact path='/reset/:token' component={passwordReset}/>
                  <Route exact path='/@:userHandle' component={profile} />
                  <AuthRoute exact path='/@:userHandle/settings' component={profileEdit} />
                  <AuthRoute exact path='/friends' component={friends} />
                  <Route exact path='/skeleton' component={ProfileSkeleton} />
                  <Route exact path='/public' component={publicPage} />
                  <AuthRoute exact path='/private' component={privatePage} />
                  <AuthRoute exact path='/wishlist/new' component={newWishlist} />
                  <AuthRoute exact path='/@:userHandle/wishlist/:id/edit' component={editWishlist} />
                  <Route exact path='/@:userHandle/wishlist/:id' component={wishlist} />
                  <Route exact path='/faq' component={faq} />
                  <Route component={noMatch} />
                </Switch>
              </div>
              <SnackbarUtil />
            </CssBaseline>
          </Router>
        </Provider>
      </ThemeProvider>
    );
  }
}

export default App;
