import { SET_AUTHENTICATED, SET_UNAUTHENTICATED, SET_USER, DECREASE_NOTIFICATION, SET_ERRORS, CLEAR_ERRORS, SET_SNACKBAR, CLEAR_SNACKBAR } from '../types';

const defaultState = {
    authenticated: false,
    user: {},
    errors: null,
    snackbarOpen: false,
    snackbarMessage: null,
    snackbarSeverity: 'success'
}

export const userReducer = (state = defaultState, action) => {
    switch(action.type) {
        case SET_AUTHENTICATED:
            return {
                ...state,
                authenticated: true
            }
        case SET_UNAUTHENTICATED: 
            return defaultState;
        case SET_USER:
            return {
                ...state,
                user: action.user
            }
        case DECREASE_NOTIFICATION:
            return {
                ...state,
                user: {
                    ...state.user,
                    notifications: state.user.notifications - 1
                }
            }
        case SET_ERRORS:
            return {
                ...state,
                errors: action.error
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                errors: null
            }
        case SET_SNACKBAR:
            return {
                ...state,
                snackbarOpen: true,
                snackbarMessage: action.message,
                snackbarSeverity: action.severity
            }
        case CLEAR_SNACKBAR:
            return {
                ...state,
                snackbarOpen: false,
                snackbarMessage: null,
                snackbarSeverity: 'success'
            }
        default:
            return state;
    }
}
