import React, { Component } from 'react'

export class publicPage extends Component {
    render() {
        return (
            <div>
                This is some NOT cool public info...
            </div>
        )
    }
}

export default publicPage
