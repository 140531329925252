import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

import axios from 'axios'

import Collaborator from './Collaborator'

const styles = theme => ({
    spacer: {
        marginTop: '25px'
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        fontWeight: 'bold'
    }
})

export class CollaboratorSearch extends Component {
    constructor(props) {
        super(props)

        this.state = {
            search: '',
            searchedFriends: [],
            firstSearch: true,
            noneFound: false,
            typingTimeout: 0
        }
        
        this.handleDialogClose = this.handleDialogClose.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.handleRemove = this.handleRemove.bind(this)
    }

    handleDialogClose() {
        this.props.handleClose()
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout)
        }

        if (value != '') {
            this.setState({
                [name]: value,
                errors: {
                    ...this.state.errors,
                    [name]: null
                },
                typingTimeout: setTimeout(() => {
                    this.handleSearch()
                }, 1000)
            })
        } else {
            this.setState({
                [name]: value,
                errors: {
                    ...this.state.errors,
                    [name]: null
                }
            })
        }
    }

    handleSearch() {
        axios.get('/searchFriends', {
            params: {
                searchTerm: this.state.search,
                ownerId: this.props.user.uid
            }
        })
        .then(data => {
            console.log(data.data.friends)
            let users = data.data.friends
            if (users.length > 0) {

                for (let x = 0; x < users.length; x++) {
                    let { collaborators } = this.props
                    let found = false
                    for (let i = 0; i < collaborators.length; i++) {
                        if (users[x]._id === collaborators[i]._id) {
                            users[x] = {
                                ...users[x],
                                status: 'added'
                            }
                            found = true
                            break
                        }
                    }

                    if (!found) {
                        users[x] = {
                            ...users[x],
                            status: 'notAdded'
                        }
                    }

                };

                this.setState({ searchedFriends: users, firstSearch: false, noneFound: false })
            } else {
                this.setState({ searchedFriends: [], firstSearch: false, noneFound: true })
            }

        })
        .catch(err => {
            console.log(err)
        })
    }

    handleAdd(friend) {
        let friends = this.state.searchedFriends

        for (let i = 0; i < friends.length; i++) {
            if (friends[i]._id === friend._id) {
                friends[i].status = 'added'
                break
            }
        }

        this.setState({ searchedFriends: [...friends] })
        this.props.handleCollaboratorAdd(friend)
    }

    handleRemove(friend) {
        let friends = this.state.searchedFriends

        for (let i = 0; i < friends.length; i++) {
            if (friends[i]._id === friend._id) {
                friends[i].status = 'notAdded'
                break
            }
        }

        this.setState({ searchedFriends: [...friends] })
        this.props.handleCollaboratorRemove(friend)
    }

    render() {
        const { classes } = this.props
        const friends = this.state.searchedFriends

        return (
            <Dialog
                open={this.props.open} 
                onClose={this.handleDialogClose} 
                maxWidth='md'
                scroll='body'
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Add Collaborators</DialogTitle>
                <DialogContent style={{padding: '25px'}}>
                    <DialogContentText>
                        Search for any friends you would like to add as a collaborator
                    </DialogContentText>
                        <Grid className={classes.spacer} container direction='column' justify='center' alignItems='flex-start' spacing={5}>
                        <Grid item container justify='center' alignItems='stretch'>
                            <Grid item xs={12}>
                                <TextField
                                    placeholder='Search for Friends'
                                    name='search'
                                    fullWidth
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><SearchRoundedIcon /></InputAdornment>,
                                    }}
                                    variant="outlined"
                                    onChange={this.handleInputChange}
                                />
                            </Grid>
                        </Grid>
                        {this.state.firstSearch ? <Grid item><Typography style={{textAlign: 'center'}}>Use the search bar to search for friends!</Typography></Grid> : null}
                        {this.state.noneFound ? 
                        <Grid item>
                            <Typography style={{textAlign: 'center'}}>No friends found :( | <Link className={classes.link} to='/friends'>Add More Friends</Link></Typography>
                        </Grid>
                        :
                            <Grid item container direction='column'>
                                {friends.map(friend => {
                                    return <Collaborator key={friend._id} friend={friend} handleCollaboratorRemove={this.handleRemove} handleCollaboratorAdd={this.handleAdd} />
                                })}
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleDialogClose}>
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.userReducer.user
    }
}

export default connect(mapStateToProps, null)(withStyles(styles)(CollaboratorSearch))
