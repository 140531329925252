import React, { Component } from 'react'

export class privatePage extends Component {
    render() {
        return (
            <div>
                This is SUPER cool PRIVATE info! Look at you!
            </div>
        )
    }
}

export default privatePage
