import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Grid, TextField, Typography, Button, Container } from '@material-ui/core';
import moment from '@date-io/moment'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Switch from '@material-ui/core/Switch';
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'

import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';

import axios from 'axios'
import Collaborator from '../Components/Wishlists/Collaborator';
import CollaboratorSearch from '../Components/Wishlists/CollaboratorSearch';

const styles = {
    container: {
        marginTop: '50px',
        paddingBottom: '50px'
    },
    gridContainer: {
        overflowX: 'hidden',
        padding: '0'
    },
    titleInput: {
        width: '250px'
    },
    dateInput: {
        width: '250px',
        marginBottom: '30px'
    },
    privacyActive: {
        fontWeight: '600'
    },
    privacyInactive: {
        fontWeight: 'light',
        color: 'lightgrey'
    },
    privacyCaption: {
        width: '200px',
        textAlign: 'center'
    },
    helpIcon: {
        color: 'grey',
        fontSize: '1rem',
        cursor: 'pointer'
    },
    submitButton: {
        marginTop: '35px',
        width: '150px'
    },
    cancelButton: {
        marginTop: '10px',
        width: '150px'
    }
}

const isEmpty = (string) => {
    if (string.trim() === '') {
        return true
    } else {
        return false
    }
}

export class newWishlist extends Component {
    constructor(props) {
        super(props);

        this.state = {
            listTitle: '',
            selectedDate: null,
            private: false,
            collab: false,
            collaborators: [],
            open: false,
            errors: {},
            success: false,
            newWishlistId: null
        }

        this.handleTitleChange = this.handleTitleChange.bind(this)
        this.handleDateChange = this.handleDateChange.bind(this)
        this.handleCheckChange = this.handleCheckChange.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleCollaboratorAdd = this.handleCollaboratorAdd.bind(this)
        this.handleCollaboratorRemove = this.handleCollaboratorRemove.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.createNewList = this.createNewList.bind(this)
    }

    handleTitleChange(e) {
        this.setState({ listTitle: e.target.value, errors: {} })
    }

    handleDateChange(date) {
        this.setState({ selectedDate: date });
    }

    handleCheckChange(e) {
        this.setState({ [e.target.name]: e.target.checked })
    }

    handleOpen() {
        this.setState({ open: true })
    }

    handleClose() {
        this.setState({ open: false })
    }

    handleCollaboratorAdd(friend) {
        this.setState({ collaborators: [...this.state.collaborators, friend] })
    }

    handleCollaboratorRemove(friend) {
        let collaborators = this.state.collaborators
        let index = 0

        for (let i = 0; i < collaborators.length; i++) {
            if (collaborators[i]._id === friend._id) {
                index = i
                break
            }
        }

        collaborators.splice(index, 1)
        this.setState({ collaborators: [...collaborators] })
    }

    handleSubmit(e) {
        let newErrors = {};
        let collab = this.state.collab
        let collaborators = []
        if (this.state.collaborators.length > 0 && collab) {
            for (let i = 0; i < this.state.collaborators.length; i++) {
                let id = this.state.collaborators[i]._id
                collaborators.push(id)
            }
        } else {
            collab = false
        }

        const listData = {
            ownerId: this.props.user.uid,
            name: this.state.listTitle,
            date: this.state.selectedDate,
            private: this.state.private,
            collab: collab,
            collaborators: collaborators
        };

        if (isEmpty(listData.name)) {
            newErrors.name = 'Give your list a title!'
        }

        if (Object.keys(newErrors).length > 0) {
            this.setState({ errors: newErrors })
        } else {
            this.createNewList(listData)
        }
    }

    createNewList(listData) {
        axios.post('/createWishlist', listData)
        .then(wishlist => {
            this.setState({ success: true, newWishlistId: wishlist.data._id })
        })
        .catch(err => {
            console.error(err)
        })
    }

    render() {
        const { classes } = this.props;
        let year = new Date().getFullYear()
        
        if (!this.state.success) {
        return (
            <Container className={classes.container}>
                <Grid container direction="column" justify="center" alignItems="center" spacing={5} className={classes.gridContainer}>
                    <Typography>New Wishlist</Typography>
                    <Typography variant='h5' >Let's get some details</Typography>
                    <TextField 
                        label='Title' 
                        placeholder={' i.e Birthday ' + year} 
                        helperText={this.state.errors.name}
                        error={this.state.errors.name ? true: false}
                        className={classes.titleInput} 
                        onChange={this.handleTitleChange}
                     />
                    <MuiPickersUtilsProvider utils={moment}>
                        <KeyboardDatePicker
                            className={classes.dateInput}
                            margin="normal"
                            id="date-picker-dialog"
                            label="Event Date"
                            placeholder=" Optional"
                            format="MM/DD/yyyy"
                            value={this.state.selectedDate}
                            onChange={this.handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <Typography variant='h6'>Privacy</Typography>

                    <Grid container item direction='row' justify="center" alignItems="center" style={{marginTop: '-15px'}} >
                        <Grid item>
                            <Typography className={ this.state.private ? classes.privacyInactive : classes.privacyActive }>
                                Public
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Switch
                                checked={this.state.private}
                                onChange={this.handleCheckChange}
                                color="primary"
                                name="private"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography className={ this.state.private ? classes.privacyActive : classes.privacyInactive }>
                                Private
                            </Typography>
                        </Grid>
                    </Grid>
                    <Typography variant='caption' className={classes.privacyCaption}>
                        { this.state.private ?
                        'Only those with a direct link will be able to see your list and claim gifts' :
                        'Your friends will be able to see your list on your profile' }
                    </Typography>

                    <Typography variant='h6' style={{marginTop: '35px'}}>Collab <Tooltip title='Add friends as collaborators who can add and edit wishes on your list' placement='top'><HelpIcon className={classes.helpIcon} /></Tooltip></Typography>
                    <Grid container item direction='row' justify="center" alignItems="center" style={{marginTop: '-15px'}}>
                        <Grid item>
                            <Typography className={ this.state.collab ? classes.privacyInactive : classes.privacyActive }>
                                Off
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Switch
                                checked={this.state.collab}
                                onChange={this.handleCheckChange}
                                color="primary"
                                name="collab"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography className={ this.state.collab ? classes.privacyActive : classes.privacyInactive }>
                                On
                            </Typography>
                        </Grid>
                    </Grid>
                    { this.state.collab ?
                    <Grid item container direction='column' justify='center' alignItems='center' style={{marginTop: '-25px'}}>
                        <Grid item>
                            <Button variant='outlined' color='primary' onClick={this.handleOpen}>Add Friends</Button>
                        </Grid>
                        <Container maxWidth='xs' style={{marginTop: '25px'}}>
                            {this.state.collaborators.map(friend => {
                                return <Collaborator key={friend._id} friend={friend} handleCollaboratorRemove={this.handleCollaboratorRemove} />
                            })}
                        </Container>
                    </Grid> 
                    :
                    null
                    }

                    <Button onClick={this.handleSubmit} variant='contained' color='primary' className={classes.submitButton} >Create List</Button>
                    <Button className={classes.cancelButton} component={Link} to={`/@${this.props.user.handle}`} >Go Back</Button>
                </Grid>
                <CollaboratorSearch open={this.state.open} collaborators={this.state.collaborators} handleClose={this.handleClose} handleCollaboratorRemove={this.handleCollaboratorRemove} handleCollaboratorAdd={this.handleCollaboratorAdd} />
            </Container>
        )
                        } else {
                            return <Redirect to={`/@${this.props.user.handle}/wishlist/${this.state.newWishlistId}`} />
                        }
    }
}

const mapStateToProps = state => {
    return {
        user: state.userReducer.user
    }
}

newWishlist.propTypes = {
    classes: PropTypes.object.isRequired
}

export default connect(mapStateToProps, null)(withStyles(styles)(newWishlist))
