
import axios from 'axios';
import { SET_AUTHENTICATED, SET_UNAUTHENTICATED, SET_USER, SET_ERRORS, CLEAR_ERRORS, SET_SNACKBAR, CLEAR_SNACKBAR } from '../types';

const setUser = (userInfo) => {
    return {
        type: SET_USER,
        user: userInfo
    }
}

const desetUser = () => {
    return {
        type: SET_UNAUTHENTICATED
    }
}

export const signupUser = (userInfo) => dispatch => {
    userInfo.handle = `${userInfo.firstName}${userInfo.lastName}`;
    axios.post('/signup', userInfo)
    .then(res => {
        axios.defaults.headers.common['x-access-token'] = res.data.accessToken;
        localStorage.setItem("token", res.data.accessToken);
        const userInfo = {
            uid: res.data.user._id,
            email: res.data.user.email,
            firstName: res.data.user.firstName,
            lastName: res.data.user.lastName,
            handle: res.data.user.handle,
            profileImage: res.data.user.profileImage
        }
        localStorage.setItem("authUser", JSON.stringify(userInfo));
        sessionStorage.setItem("loggedIn", 'true');
        dispatch({ type: SET_AUTHENTICATED })
        dispatch({ type: CLEAR_ERRORS })
        dispatch(setUser(res.data.user));
        window.location.href = `/@${res.data.user.handle}`;
    })
    .catch(err => {
        console.log(err);
        dispatch({ type: SET_ERRORS, error: err.response.data.error })
    })
}

export const loginUser = (userInfo) => dispatch => {
    axios.post('/login', userInfo)
    .then(res => {
        axios.defaults.headers.common['x-access-token'] = res.data.accessToken
        localStorage.setItem("token", res.data.accessToken);
        const userInfo = {
            uid: res.data.user._id,
            email: res.data.user.email,
            firstName: res.data.user.firstName,
            lastName: res.data.user.lastName,
            handle: res.data.user.handle,
            profileImage: res.data.user.profileImage,
            notifications: res.data.notifications
        }
        localStorage.setItem("authUser", JSON.stringify(userInfo));
        sessionStorage.setItem("loggedIn", 'true');
        dispatch({ type: SET_AUTHENTICATED })
        dispatch({ type: CLEAR_ERRORS })
        dispatch(setUser(res.data.user));
        window.location.href = `/@${userInfo.handle}`;
    })
    .catch(err => {
        console.log(err);
        dispatch({ type: SET_ERRORS, error: err.response.data.error })
    })
}

export const getCurrentUserData = (userId) => dispatch => {
    axios.get('/current-user', {
        params: {
            id: userId
        }
    })
    .then(res => {
        const userInfo = {
            uid: res.data.user._id,
            email: res.data.user.email,
            firstName: res.data.user.firstName,
            lastName: res.data.user.lastName,
            handle: res.data.user.handle,
            profileImage: res.data.user.profileImage
        }
        localStorage.setItem("authUser", JSON.stringify(userInfo));
        sessionStorage.setItem("loggedIn", 'true');
        dispatch({ type: SET_AUTHENTICATED })
        dispatch(setUser(userInfo));
    })
    .catch(err => {
        console.log(err);
    })
}

export const logoutUser = () => dispatch => {
    axios.defaults.headers.common['x-access-token'] = null;
    localStorage.clear();
    sessionStorage.clear();
    dispatch(desetUser());
    window.location.href = '/';
}

export const setSnackbarMessage = (message, severity) => {
    return {
        type: SET_SNACKBAR,
        message: message,
        severity: severity
    }
}

export const clearSnackbar = () => {
    return {
        type: CLEAR_SNACKBAR
    }
}
