import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Skeleton from '@material-ui/lab/Skeleton'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import WishlistSkeleton from './WishlistSkeleton'

const Styles = {
    container: {
        backgroundColor: '#dfe6e9',
        paddingBottom: '45px',
        marginTop: '-75px'
    },
    spacer: {
        height: '100px'
    },
    avatarHolder: {
        height: '150px',
        width: '150px',
        backgroundColor: 'grey',
        border: '5px solid white',
        borderRadius: '100%',
        overflow: 'hidden'
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    button: {
        width: '150px',
        marginTop: '15px'
    }
}

export class ProfileHeaderSkeleton extends Component {
    render() {
        const { classes } = this.props;

        return (
            <div>
                <Container maxWidth='xl' className={classes.container} >
                    <div className={classes.spacer}></div>
                    <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                        <Grid item>
                            <Skeleton variant='circle' width={150} height={150} />
                        </Grid>
                        <Grid item>
                            <Skeleton variant="text" width={200} height={40} />
                        </Grid>
                    </Grid>
                </Container>
                <WishlistSkeleton />
            </div>
        )
    }
}

ProfileHeaderSkeleton.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(Styles)(ProfileHeaderSkeleton)
