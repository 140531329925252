import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputAdornment from '@material-ui/core/InputAdornment';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography, ButtonBase, IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import giftImage from '../../imgs/gift.png'
import ClaimButtons from './ClaimButtons'
import WishImage from '../Utils/WishImage'

const styles = {
    imgHolder: {
        width: '100%'
    },
    image: {
        maxHeight: '300px',
        width: '100%',
        objectFit: 'cover',
        borderRadius: '10px'
    },
    notesHolder: {
        position: 'relative',
        maxHeight: '100px',
        backgroundColor: '#eeeeee',
        borderRadius: '10px',
        overflowY: 'auto'
    }
}

const parseSource = storeUrl => {
    
    let split = storeUrl.split('.')
    let website
    let rest
    if (split.length > 2) {
        website = split[1]
        rest = split[2]
    } else {
        website = split[0]
        rest = split[1]
    }
    split = rest.split('/')
    let source = website + '.' + split[0]

    return source
}

export class WishDialog extends Component {
    constructor(props) {
        super(props)

        this.handleModalClose = this.handleModalClose.bind(this)
        this.getClaimedMessaged = this.getClaimedMessaged.bind(this)
    }

    handleModalClose() {
        this.props.handlePinErrorReset()
        this.props.handleClose()
    }

    getClaimedMessaged() {
        if (this.props.claimed) {
            if (this.props.user) {
                if (this.props.claimed === this.props.user.uid) {
                    return <Typography>You have claimed this wish!</Typography>
                } else {
                    return <Typography>Someone has already claimed this wish</Typography>
                }
            } else {
                return <Typography>Someone has already claimed this wish</Typography>
            }
        } else {
            return <Typography>Ready to claim this wish?</Typography>
        }
    }

    render() {
        const { classes } = this.props

        return (
            <div>
                <Dialog 
                    open={this.props.open} 
                    onClose={this.handleModalClose} 
                    fullWidth
                    maxWidth='md'
                    scroll='body'
                    aria-labelledby="form-dialog-title">
                        <DialogContent dividers={true}>
                            <Grid container style={{padding: '25px 0px'}} direction='row' justify='center' alignItems='center' spacing={3}>
                                <Grid item sm={4}>
                                    <div className={classes.imgHolder}>
                                        <WishImage src={this.props.image ? this.props.image : giftImage} rounded />
                                    </div>
                                </Grid>
                                <Grid item sm={8} container direction='column' justify='stretch' alignItems='center' spacing={2}>
                                    <Grid item>
                                        <Typography variant='h6' style={{textAlign: 'center'}}>{this.props.name}</Typography>
                                    </Grid>
                                    <Grid item container direction='row' justify='center' alignItems='center'>
                                        { this.props.price ? 
                                        <Grid item>
                                            <Typography>${this.props.price}{this.props.storeUrl ? ' | ' : null}</Typography>
                                        </Grid> :
                                        null }
                                        { this.props.storeUrl ?
                                        <Grid item>
                                            <Typography>{parseSource(this.props.storeUrl)}</Typography>
                                        </Grid> :                                       
                                        null}    
                                    </Grid>
                                    <Grid item container direction='column' justify='stretch' alignItems='stretch'>
                                        <Typography variant='subtitle2' >Notes</Typography>
                                        <div className={classes.notesHolder}>
                                            <Typography fullWidth style={{padding: '10px'}}>
                                                {this.props.notes ? 
                                                this.props.notes :
                                                <Typography style={{color:'grey'}} >None</Typography>}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    { this.props.storeUrl ? 
                                    <Grid item>
                                        <a target='_blank' href={this.props.storeUrl} style={{textDecoration: 'none'}}>
                                            <Button variant='outlined' color='primary'>Check It Out</Button>
                                        </a>
                                    </Grid> :                                   
                                    null }
                                    <Grid item>
                                        {this.props.myProfile || this.props.editor ? 
                                        null :
                                        this.getClaimedMessaged()
                                        }
                                    </Grid>
                                    { !this.props.myProfile && !this.props.editor ? 
                                    <Grid item >
                                        <ClaimButtons wishId={this.props.wishId} claimed={this.props.claimed} pinError={this.props.pinError} handlePinErrorReset={this.props.handlePinErrorReset} handleClaimWish={this.props.handleClaimWish} handleUnclaimWish={this.props.handleUnclaimWish} />
                                    </Grid> :
                                    null }
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions style={{padding: '15px'}}>
                            <Grid item container direction='row' justify='space-between' alignItems='center'>
                                <Grid item xs={3}>
                                    {
                                        this.props.auth ?
                                        <IconButton variant='contained' onClick={this.props.handleCopyOpen}>
                                            <FileCopyIcon />
                                        </IconButton> 
                                        :
                                        null
                                    }

                                </Grid>
                                <Grid item container direction='row' justify='flex-end' alignItems='center' spacing={3} xs={9}>
                                {this.props.myProfile || this.props.editor ? 
                                    <Grid item>
                                        <Button variant='contained' disableRipple onClick={this.props.handleEditOpen}>Edit Wish</Button>
                                    </Grid>:
                                null}
                                <Grid item>
                                    <Button disableElevation disableRipple onClick={this.handleModalClose}>Close</Button>
                                </Grid>
                            </Grid>
                            </Grid>

                        </DialogActions>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.userReducer.user,
        auth: state.userReducer.authenticated
    }
}

WishDialog.propTyopes = {
    classes: PropTypes.object.isRequired
}

export default connect(mapStateToProps, null)(withStyles(styles)(WishDialog))
