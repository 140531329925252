import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Skeleton from '@material-ui/lab/Skeleton'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

const styles = {
    container: {
        marginTop: '45px'
    },
    preview: {
        borderRadius: '10px'
    }
}

export class WishlistSkeleton extends Component {
    render() {
        const { classes } = this.props;

        return (
            <Container maxWidth='lg' className={classes.container}>
                <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                    <Grid item >
                        <Skeleton className={classes.preview} variant='rect' height={200} width={300} />
                        <Skeleton variant='text' width={100} /> 
                        <Skeleton variant='text' width={40} height={15} /> 
                    </Grid>
                    <Grid item >
                        <Skeleton className={classes.preview} variant='rect' height={200} width={300} />
                        <Skeleton variant='text' width={100} /> 
                        <Skeleton variant='text' width={40} height={15} /> 
                    </Grid>
                    <Grid item >
                        <Skeleton className={classes.preview} variant='rect' height={200} width={300} />
                        <Skeleton variant='text' width={100} /> 
                        <Skeleton variant='text' width={40} height={15} /> 
                    </Grid>
                </Grid>
        </Container>
        )
    }
}

WishlistSkeleton.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(WishlistSkeleton)
