import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';

const styles = {
    
}

export class UrlDialog extends Component {
    render() {
        return (
            <div>
                <DialogTitle id="form-dialog-title">Add A New Wish</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Toss us a link to your product and we'll crunch some numbers
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="url"
                        name='url'
                        label="Product URL"
                        placeholder="https://"
                        type="text"
                        fullWidth
                        helperText={this.props.errors.url}
                        error={this.props.errors.url ? true: false}
                        onChange={this.props.handleChange}
                    />
                    <Typography variant='caption'>
                        No link? No worries! Enter it manually <span onClick={this.props.handleManual} style={{fontWeight: 'bold', cursor: 'pointer'}}>here</span>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button variant='contained' onClick={this.props.handleClick} color="primary" disabled={this.props.loading}>
                        Next
                        {
                        this.props.loading ? 
                        <CircularProgress size={24} style={{position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px'}} /> :
                        null
                    }
                    </Button>
                </DialogActions>
            </div>
        )
    }
}

export default withStyles(styles)(UrlDialog)
