import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'

const styles = {
    holder: {
        marginTop: '100px'
    },
    title: {
        fontWeight: 'bold'
    }
}

const smartButton = (friendStatus, user, props) => {
    switch(friendStatus) {
        case 'notFriends':
            return (
                <Grid item>
                    <Button variant='contained' color='primary' onClick={props.sendFriendRequest}>Send Friend Request</Button>
                </Grid>
            )
        case 'sent':
            return (
                <div>
                    <Grid item>
                        <Typography style={{marginBottom: '25px'}}>Your sent friend request is pending</Typography>
                    </Grid>
                    <Grid item align='center'>
                        <Button variant='outlined' onClick={props.cancelFriendRequest} >Cancel Friend Request</Button>
                    </Grid>
                </div>
            )
        case 'pending':
            return (
                <div>
                    <Grid item  align='center'>
                        <Typography style={{marginBottom: '25px'}}>{user.firstName} {user.lastName} has sent you a friend request!</Typography>
                    </Grid>
                    <Grid container item direction='column' justify="center" alignItems="center" spacing={2} >
                        <Grid item>
                            <Button variant='contained' color='primary' onClick={props.acceptFriendRequest}>Accept Friend Request</Button>
                        </Grid>
                        <Grid item>
                            <Button variant='outlined' onClick={props.denyFriendRequest}>Deny The Friendship :(</Button>
                        </Grid>
                    </Grid>
                </div>
            )
        default:
            return null
    }
}

export class FriendButtons extends Component {

    render() {
        const {classes} = this.props;

        return (
            <Grid container style={{marginTop: '50px'}} direction='column' justify="center" alignItems="center" spacing={3}>
                <Grid item>
                    <Typography style={{fontWeight: 'bold', textAlign: 'center'}}>You must be friends with {this.props.user.firstName} {this.props.user.lastName} to view their wishlists</Typography>
                </Grid>
                {smartButton(this.props.friendStatus, this.props.user, this.props)}
            </Grid>
        )
    }
}

FriendButtons.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FriendButtons)
