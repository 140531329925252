import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { signupUser } from '../redux/actions/userActions'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';

import { CLEAR_ERRORS } from '../redux/types'
import { Container } from '@material-ui/core'

const isEmpty = (string) => {
    if (string.trim() === '') {
        return true
    } else {
        return false
    }
}

const isValidPassword = (string) => {
    if (string.trim().length < 6) {
        return false
    } else {
        return true
    }
}

const isValidEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export class signup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            showPassword: false,
            showConfirm: false,
            loading: false,
            errors: {}
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.handleClickShowConfirm = this.handleClickShowConfirm.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.clearErrors()
    }

    componentDidUpdate() {
        if (this.state.loading && this.props.errors != null) {
            this.setState({ loading: false })
        }
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            errors: {
                ...this.state.errors,
                [name]: null
            }
        })
    }

    handleClickShowPassword() {
        this.state.showPassword ? this.setState({ showPassword: false }) : this.setState({ showPassword: true })
    }

    handleClickShowConfirm() {
        this.setState({ showConfirm: !this.state.showConfirm })
    }

    handleSubmit(e) {
        e.preventDefault();
        let valid = true;
        let newErrors = {};
        const userData = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email.toLowerCase(),
            password: this.state.password,
            confirmPassword: this.state.confirmPassword
        };

        if (isEmpty(userData.email)) {
            newErrors.email = 'Email must not be empty'
            valid = false
        } else if (!isValidEmail(userData.email)){
            newErrors.email = 'Email must be valid'
            valid = false
        }

        if (isEmpty(userData.firstName)) {
            newErrors.firstName = 'Must not be empty'
            valid = false
        }

        if (isEmpty(userData.lastName)) {
            newErrors.lastName = 'Must not be empty'
            valid = false
        }

        if (isEmpty(userData.password)) {
            newErrors.password = 'Password must not be empty'
            valid = false
        } else if (!isValidPassword(userData.password)) {
            newErrors.password = 'Password must be at least 6 characters long'
            valid = false
        }

        if (userData.password !== userData.confirmPassword){
            newErrors.confirmPassword = 'Must match password'
            valid = false
        }

        if (valid) {
            this.props.clearErrors()
            this.props.signupUser(userData);
        } else {
            this.setState({ errors: newErrors })
        }
    }

    render() {
        return (
            <form>
                <Container maxWidth='xs'>
            <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                <Grid item>
                    <Typography variant='h2'>
                        Sign Up
                    </Typography>
                </Grid>
                <Grid item container direction='row' justify='center' alignItems='center' spacing={2}>
                    <Grid item>
                        <TextField 
                            name='firstName' 
                            label='First Name*' 
                            placeholder='First' 
                            variant='outlined' 
                            fullWidth
                            style={{width: '262px'}}
                            helperText={this.state.errors.firstName}
                            error={this.state.errors.firstName ? true: false}
                            onChange={this.handleInputChange} 
                        />
                    </Grid>
                    <Grid item>
                        <TextField 
                            name='lastName' 
                            label='Last Name*' 
                            placeholder='Last' 
                            variant='outlined' 
                            style={{width: '262px'}}
                            helperText={this.state.errors.lastName}
                            error={this.state.errors.lastName ? true: false}
                            onChange={this.handleInputChange} 
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <TextField 
                        name='email' 
                        label='Email*' 
                        placeholder='your@email.com' 
                        variant='outlined' 
                        fullWidth
                        style={{width: '262px'}}
                        helperText={this.state.errors.email}
                        error={this.state.errors.email ? true: false}
                        onChange={this.handleInputChange} 
                    />
                </Grid>
                <Grid item>
                    <TextField 
                        name='password' 
                        label='Password*' 
                        placeholder='*********' 
                        variant='outlined' 
                        type={this.state.showPassword ? 'text' : 'password'}
                        helperText={this.state.errors.password}
                        error={this.state.errors.password ? true: false} 
                        onChange={this.handleInputChange}
                        InputProps={{
                            endAdornment:                          
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    >
                                    {this.state.showPassword ? <VisibilityRoundedIcon /> : <VisibilityOffRoundedIcon />}
                                    </IconButton>
                                </InputAdornment>
                        }} />
                </Grid>
                <Grid item>
                    <TextField 
                        name='confirmPassword' 
                        label='Confirm Password*' 
                        placeholder='*********' 
                        type={this.state.showConfirm ? 'text' : 'password'}
                        variant='outlined' 
                        helperText={this.state.errors.confirmPassword}
                        error={this.state.errors.confirmPassword ? true: false}
                        onChange={this.handleInputChange}
                        InputProps={{
                            endAdornment:                          
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowConfirm}
                                    >
                                    {this.state.showConfirm ? <VisibilityRoundedIcon /> : <VisibilityOffRoundedIcon />}
                                    </IconButton>
                                </InputAdornment>
                        }} />
                </Grid>
                {
                    this.props.errors ? 
                    <Grid item>
                        <Typography variant='caption' style={{color: 'red'}}>{this.props.errors}</Typography>
                    </Grid> :
                    null
                }
                <Grid item style={{position: 'relative'}}>
                    <Button variant='contained' color='primary' type='submit' onClick={this.handleSubmit} disabled={this.state.loading}>
                        Submit
                    </Button>
                    {
                        this.state.loading ? 
                        <CircularProgress size={24} style={{position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px'}} /> :
                        null
                    }
                </Grid>
                <br />
                <small>
                    Already have an account? Login <Link to='/login'>here</Link>
                </small>
            </Grid>
            </Container>
            </form>
        )
    }
}

const mapStateToProps = state => ({
    user: state.userReducer.user,
    errors: state.userReducer.errors
})

const mapDispatchToProps = dispatch => {
    return {
        signupUser: (userInfo) => {
            dispatch(signupUser(userInfo))
        },
        clearErrors: () => {
            dispatch({ type: CLEAR_ERRORS })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(signup)
