import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Friend from './Friend'

const styles = {
    spacer: {
        marginTop: '25px'
    }
}

export class PendingFriendsList extends Component {
    render() {
        const { classes, friends } = this.props

        return (
            <Grid className={classes.spacer} container direction='column' justify='center' alignItems='flex-start' spacing={5}>
                <Grid item>
                    <Typography variant='h4' >{this.props.type === 'pending' ? 'Pending Friends' : 'Sent Requests'}</Typography>
                </Grid>
                { friends.length > 0 ? 
                    <Grid item container direction='column'>
                        { friends.map(friend => {
                            return <Friend 
                                        key={friend._id} 
                                        friend={friend} 
                                        acceptFriendRequest={this.props.acceptFriendRequest} 
                                        cancelFriendRequest={this.props.cancelFriendRequest}
                                        declineFriendRequest={this.props.declineFriendRequest}
                                     />
                        }) }
                    </Grid>
                :
                <Grid item container direction='column' style={{borderTop: '1px solid lightgrey'}}>
                    <Typography>{this.props.type === 'pending' ? "You don't have any pending friend requests" : "You don't have any sent friend requests"}</Typography>
                </Grid>
                }

            </Grid>
        )
    }
}

PendingFriendsList.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PendingFriendsList)
