import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import axios from 'axios'

import ListPreview from '../ListPreview'
import AddNewListButton from './AddNewListButton'
import { FriendButtons } from './FriendButtons'
import WishlistSkeleton from '../skeletons/WishlistSkeleton'

const styles = {
    container: {
        marginTop: '45px',
        marginBottom: '45px'
    }
}


export class AuthLists extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            wishlists: {}
        }

        this.getWishlists = this.getWishlists.bind(this)
        this.getPublicWishlists = this.getPublicWishlists.bind(this)
    }

    componentDidMount() {
        if (this.props.myProfile) {
            this.getWishlists(this.props.user.userId)
        } else {
            this.getPublicWishlists(this.props.user.userId)
        }
    }

    componentDidUpdate() {
        if (this.props.refresh) {
            if (this.props.myProfile) {
                this.getWishlists(this.props.user.userId)
            } else {
                this.getPublicWishlists(this.props.user.userId)
            }
            this.props.resetRefresh()
        }
    }

    getWishlists(ownerId) {
        axios.get('/getWishlistsByOwner', {
            params: {
                ownerId: ownerId
            }
        })
        .then(wishlists => {
            this.setState({
                loading: false,
                wishlists: wishlists.data
            })
        })
        .catch(err => {
            console.log(err)
            this.setState({
                loading: false
            })
        })
    }

    getPublicWishlists(ownerId) {
        axios.get('/getPublicWishlists', {
            params: {
                ownerId: ownerId
            }
        })
        .then(wishlists => {
            this.setState({
                loading: false,
                wishlists: wishlists.data
            })
        })
        .catch(err => {
            console.log(err)
            this.setState({
                loading: false
            })
        })
    }

    render() {
        const { classes } = this.props;

        if (this.props.myProfile) {
            if (!this.state.loading) {
                return (
                    <Container maxWidth='lg' className={classes.container}>
                        <Grid container direction="row" justify='center' alignItems="center" spacing={5}>
                            <AddNewListButton />
                            {this.state.wishlists.map((list) => (
                                <ListPreview 
                                    key={list._id} 
                                    userHandle={this.props.user.handle}
                                    listId={list._id}
                                    listName={list.name} 
                                    giftCount={list.giftCount} 
                                />
                                )
                            )}
                        </Grid>
                    </Container>
                )
            }  else {
                return (
                    <WishlistSkeleton />
                )
            }

        } else if (this.props.friendStatus === 'friends') {
            if (!this.state.loading && Object.keys(this.state.wishlists).length > 0) {
                return (
                    <Container maxWidth='lg' className={classes.container}>
                        <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
                            {this.state.wishlists.map((list) => (
                                <ListPreview 
                                    key={list._id} 
                                    userHandle={this.props.user.handle}
                                    listId={list._id}
                                    listName={list.name} 
                                    giftCount={list.giftCount} 
                                />
                                )
                            )}
                        </Grid>
                    </Container>
                )
            } else if (!this.state.loading) {
                return (
                    <Grid container style={{marginTop: '50px'}} direction='column' justify="center" alignItems="center" spacing={3}>
                        <Grid item>
                            <Typography style={{marginBottom: '25px'}}>Looks like {this.props.user.firstName} {this.props.user.lastName} hasn't made any public wishlists quite yet</Typography>
                        </Grid>
                    </Grid>
                )
            } else {
                return (
                    <WishlistSkeleton />
                )
            }
        } else if (!this.props.auth) {
            return (
                <Grid container style={{marginTop: '50px'}} direction='column' justify="center" alignItems="center" spacing={3}>
                    <Grid item>
                        <Typography style={{marginBottom: '25px'}}>You must be signed in to view {this.props.user.firstName} {this.props.user.lastName}'s wishlists</Typography>
                    </Grid>
                    <Grid item container direction='row' justify='center' alignItems='center' spacing={3}>
                        <Grid item align='center'>
                            <Button variant='outlined' component={Link} to='/login' >Log In</Button>
                        </Grid>
                        <Grid item align='center'>
                            <Button variant='contained' color='primary' component={Link} to='/signup' >Signup</Button>
                        </Grid>
                    </Grid>
                </Grid>
            )
        } else {
            return (
                <FriendButtons 
                    user={this.props.user} 
                    friendStatus={this.props.friendStatus} 
                    sendFriendRequest={this.props.sendFriendRequest}
                    acceptFriendRequest={this.props.acceptFriendRequest}
                    cancelFriendRequest={this.props.cancelFriendRequest}
                    denyFriendRequest={this.props.denyFriendRequest} />
            )
        }
    }
}

AuthLists.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        authUser: state.userReducer.user
    }
}

export default connect(mapStateToProps, null)(withStyles(styles)(AuthLists))
