import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Grid, TextField, Typography, Button } from '@material-ui/core';
import moment from '@date-io/moment'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Switch from '@material-ui/core/Switch';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import Container from '@material-ui/core/Container'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';

import Collaborator from '../Components/Wishlists/Collaborator';
import CollaboratorSearch from '../Components/Wishlists/CollaboratorSearch';

import axios from 'axios'

const styles = {
    container: {
        marginTop: '50px',
        paddingBottom: '50px'
    },
    gridContainer: {
        overflowX: 'hidden',
        padding: '0'
    },
    titleInput: {
        width: '250px'
    },
    dateInput: {
        width: '250px',
        marginBottom: '30px'
    },
    privacyActive: {
        fontWeight: '600'
    },
    privacyInactive: {
        fontWeight: 'light',
        color: 'lightgrey'
    },
    privacyCaption: {
        width: '200px',
        textAlign: 'center'
    },
    helpIcon: {
        color: 'grey',
        fontSize: '1.1rem',
        cursor: 'pointer'
    },
    submitButton: {
        marginTop: '35px',
        width: '150px'
    },
    cancelButton: {
        marginTop: '10px',
        width: '150px'
    },
    deleteButton: {
        marginTop: '25px'
    }
}

const isEmpty = (string) => {
    if (string.trim() === '') {
        return true
    } else {
        return false
    }
}

export class editWishlist extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ownerId: null,
            listTitle: '',
            selectedDate: null,
            private: false,
            errors: {},
            success: false,
            listId: null,
            wishlist: {},
            loading: true,
            open: false,
            collabOpen: false,
            deleted: false
        }

        this.getWishlist = this.getWishlist.bind(this)
        this.getCollaborators = this.getCollaborators.bind(this)
        this.handleTitleChange = this.handleTitleChange.bind(this)
        this.handleDateChange = this.handleDateChange.bind(this)
        this.handleCheckChange = this.handleCheckChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.updateList = this.updateList.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleCollaboratorAdd = this.handleCollaboratorAdd.bind(this)
        this.handleCollaboratorRemove = this.handleCollaboratorRemove.bind(this)
        this.handleCollabOpen = this.handleCollabOpen.bind(this)
        this.handleCollabClose = this.handleCollabClose.bind(this)
        this.handleDeleteList = this.handleDeleteList.bind(this)
        this.handleHashChange = this.handleHashChange.bind(this)
    }

    componentDidMount() {
        window.addEventListener("hashchange", this.handleHashChange);
        let path = this.props.location.pathname
        let split = path.split('/')
        const id = split[3]
        this.getWishlist(id);
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', this.handleHashChange);
    }
    
    handleHashChange() {
        if (window.location.hash === '') {
            this.handleCollabClose()
        }
    }

    getWishlist(id) {
        axios.get('/getWishlistById', {
            params: {
                id: id
            }
        })
        .then(wishlist => {
            console.log(wishlist.data)

            if (!wishlist.data.wishlist.collab) {
                this.setState({
                    ownerId: wishlist.data.wishlist.ownerId,
                    listId: wishlist.data.wishlist._id,
                    listTitle: wishlist.data.wishlist.name, 
                    selectedDate: wishlist.data.wishlist.date,
                    private: wishlist.data.wishlist.private,
                    collab: wishlist.data.wishlist.collab,
                    collaborators: wishlist.data.wishlist.collaborators,
                    loading: false
                })
            } else {
                this.getCollaborators(wishlist.data.wishlist)
            }

        })
        .catch(err => {
            console.error(err)
            this.setState({
                wishlist: {},
                loading: false
            })
        })
    }

    getCollaborators(wishlist) {
        axios.get('/getCollaborators', {
            params: {
                ids: wishlist.collaborators
            }
        })
        .then(data => {
            console.log(data.data.collaborators)
            let collaborators = data.data.collaborators
            
            for (let i = 0; i < collaborators.length; i++) {
                collaborators[i] = {
                    ...collaborators[i],
                    status: 'added'
                }
            }

            this.setState({
                ownerId: wishlist.ownerId,
                listId: wishlist._id,
                listTitle: wishlist.name, 
                selectedDate: wishlist.date,
                private: wishlist.private,
                collab: wishlist.collab,
                collaborators: collaborators,
                loading: false
            })
        })
        .catch(err => {
            console.log(err)
        })
    }

    handleTitleChange(e) {
        this.setState({ listTitle: e.target.value, errors: {} })
    }

    handleDateChange(date) {
        this.setState({ selectedDate: date });
    }

    handleCheckChange(e) {
        this.setState({ [e.target.name]: e.target.checked })
    }

    handleSubmit(e) {
        let newErrors = {};
        let collab = this.state.collab
        let collaborators = []
        if (this.state.collaborators.length > 0 && collab) {
            for (let i = 0; i < this.state.collaborators.length; i++) {
                let id = this.state.collaborators[i]._id
                collaborators.push(id)
            }
        } else {
            collab = false
        }

        const listData = {
            listId: this.state.listId,
            ownerId: this.props.user.uid,
            name: this.state.listTitle,
            date: this.state.selectedDate,
            private: this.state.private,
            collab: collab,
            collaborators: collaborators
        };

        if (isEmpty(listData.name)) {
            newErrors.name = 'Give your list a title!'
        }

        if (Object.keys(newErrors).length > 0) {
            this.setState({ errors: newErrors })
        } else {
            this.updateList(listData)
        }
    }

    updateList(listData) {
        axios.post('/updateWishlist', listData)
        .then(wishlist => {
            this.setState({ success: true })
        })
        .catch(err => {
            console.error(err)
        })
    }

    handleOpen() {
        this.setState({ open: true })
    }

    handleClose() {
        this.setState({ open: false })
    }

    handleCollabOpen() {
        window.location.hash = '#collab'
        this.setState({ collabOpen: true })
    }

    handleCollabClose() {
        if (window.location.hash !== '') {
            window.history.back()
        }
        this.setState({ collabOpen: false })
    }

    handleCollaboratorAdd(friend) {
        this.setState({ collaborators: [...this.state.collaborators, friend] })
    }

    handleCollaboratorRemove(friend) {
        let collaborators = this.state.collaborators
        let index = 0

        for (let i = 0; i < collaborators.length; i++) {
            if (collaborators[i]._id === friend._id) {
                index = i
                break
            }
        }

        collaborators.splice(index, 1)
        this.setState({ collaborators: [...collaborators] })
    }

    handleDeleteList() {
        axios.post('/deleteWishlist', {
            listId: this.state.listId,
            ownerId: this.props.user.uid
        })
        .then(() => {
            this.setState({ deleted: true })
        })
        .catch(err => {
            console.log(err)
        })
    }

    render() {
        const { classes, theme } = this.props;
        const palette = theme.palette
        let year = new Date().getFullYear()
        if (!this.state.loading) {
        if (this.state.ownerId === this.props.user.uid) {
        if (!this.state.deleted) {
        if (!this.state.success) {
        return (
            <Container className={classes.container}>
                <Grid container direction="column" justify="center" alignItems="center" spacing={5} className={classes.gridContainer}>
                    <Typography>Edit Wishlist</Typography>
                    <Typography variant='h5' >Let's edit some details</Typography>
                    <TextField 
                        label='Title' 
                        placeholder={' i.e Birthday ' + year} 
                        value={this.state.listTitle}
                        helperText={this.state.errors.name}
                        error={this.state.errors.name ? true: false}
                        className={classes.titleInput} 
                        onChange={this.handleTitleChange}
                     />
                    <MuiPickersUtilsProvider utils={moment}>
                        <KeyboardDatePicker
                            className={classes.dateInput}
                            margin="normal"
                            id="date-picker-dialog"
                            label="Event Date"
                            placeholder=" Optional"
                            format="MM/DD/yyyy"
                            value={this.state.selectedDate}
                            onChange={this.handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <Typography variant='h6'>Privacy</Typography>

                    <Grid container item direction='row' justify="center" alignItems="center" >
                        <Grid item>
                            <Typography className={ this.state.private ? classes.privacyInactive : classes.privacyActive }>
                                Public
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Switch
                                checked={this.state.private}
                                onChange={this.handleCheckChange}
                                color="primary"
                                name="private"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography className={ this.state.private ? classes.privacyActive : classes.privacyInactive }>
                                Private
                            </Typography>
                        </Grid>
                    </Grid>
                    <Typography variant='caption' className={classes.privacyCaption}>
                        { this.state.private ?
                        'Only those with a direct link will be able to see your list and claim gifts' :
                        'Your friends will be able to see your list on your profile' }
                    </Typography>

                    <Tooltip disableFocusListener  title='Add friends as collaborators who can add and edit wishes on your list' placement='top'><Typography variant='h6' style={{marginTop: '35px'}}>Collab <HelpIcon className={classes.helpIcon} /></Typography></Tooltip>
                    <Grid container item direction='row' justify="center" alignItems="center" style={{marginTop: '-15px'}}>
                        <Grid item>
                            <Typography className={ this.state.collab ? classes.privacyInactive : classes.privacyActive }>
                                Off
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Switch
                                checked={this.state.collab}
                                onChange={this.handleCheckChange}
                                color="primary"
                                name="collab"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography className={ this.state.collab ? classes.privacyActive : classes.privacyInactive }>
                                On
                            </Typography>
                        </Grid>
                    </Grid>
                    { this.state.collab ?
                    <Grid item container direction='column' justify='center' alignItems='center' style={{marginTop: '-25px'}}>
                        <Grid item>
                            <Button variant='outlined' color='primary' onClick={this.handleCollabOpen}>Add Friends</Button>
                        </Grid>
                        <Container maxWidth='xs' style={{marginTop: '25px'}}>
                            {this.state.collaborators.map(friend => {
                                return <Collaborator key={friend._id} friend={friend} handleCollaboratorRemove={this.handleCollaboratorRemove} />
                            })}
                        </Container>
                    </Grid> 
                    :
                    null
                    }

                    <Button onClick={this.handleSubmit} variant='contained' color='primary' className={classes.submitButton} >Update Wishlist</Button>
                    <Button className={classes.cancelButton} component={Link} to={`/@${this.props.user.handle}/wishlist/${this.state.listId}`} >Go Back</Button>
                    <Button 
                        className={classes.deleteButton} 
                        style={{color: palette.error.main}} 
                        startIcon={<DeleteForeverIcon />}
                        onClick={this.handleOpen}
                    >
                        Delete Wishlist
                    </Button>
                </Grid>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    maxWidth='xs'
                    fullWidth
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle style={{textAlign: 'center'}} id="alert-dialog-title">Delete this wishlist?</DialogTitle>
                    <DialogContent>
                    <DialogContentText style={{textAlign: 'center'}} id="alert-dialog-description">
                        Are you sure? Deleting this wishlist will:
                        <List style={{color: palette.error.main}} aria-label="main mailbox folders">
                            <ListItem>
                                <ListItemIcon>
                                    <HighlightOffRoundedIcon style={{color: palette.error.main}} />
                                </ListItemIcon>
                                <ListItemText primary="Permanently delete this wishlist" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <HighlightOffRoundedIcon style={{color: palette.error.main}} />
                                </ListItemIcon>
                                <ListItemText primary="Permanently delete all wishes part of this wishlist" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <HighlightOffRoundedIcon style={{color: palette.error.main}} />
                                </ListItemIcon>
                                <ListItemText primary="Permanently delete all wishes from this list that have been claimed" />
                            </ListItem>
                        </List>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleClose} style={{position: 'absolute', left: '10px'}} color="primary" variant='outlined'>
                        Cancel
                    </Button>
                    <Button onClick={this.handleDeleteList} variant='outlined' style={{color: palette.error.main, marginLeft: '25px'}}>
                        Yes, Delete this List
                    </Button>
                    </DialogActions>
                </Dialog>
                <CollaboratorSearch open={this.state.collabOpen} collaborators={this.state.collaborators} handleClose={this.handleCollabClose} handleCollaboratorRemove={this.handleCollaboratorRemove} handleCollaboratorAdd={this.handleCollaboratorAdd} />
            </Container>
        )
            } else {
                return <Redirect to={`/@${this.props.user.handle}/wishlist/${this.state.listId}`} />
            }
        } else {
            let path = this.props.location.pathname
            let split = path.split('/')
            const handle = split[1]
            return <Redirect to={`/${handle}`} />
        }
        } else {
            let path = this.props.location.pathname
            let split = path.split('/')
            const handle = split[1]
            return <Redirect to={`/${handle}/wishlist/${this.state.listId}`} />
        }
        } else {
            return <p>Loading...</p>
        }
    }
}

const mapStateToProps = state => {
    return {
        user: state.userReducer.user
    }
}

editWishlist.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withRouter(connect(mapStateToProps, null)(withStyles(styles, { withTheme: true })(editWishlist)))
