import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import FavoriteIcon from '@material-ui/icons/Favorite';

import giftImage from '../../imgs/gift.png'
import WishDialog from './WishDialog'
import EditWishDialog from './EditWishDialog'
import CopyWishDialog from './CopyWishDialog'
import WishImage from '../Utils/WishImage'

import axios from 'axios'
import firebase from '../../util/firebase'
import imageCompression from 'browser-image-compression'

import { setSnackbarMessage, clearSnackbar } from '../../redux/actions/userActions'
import { CLEAR_SNACKBAR } from '../../redux/types';

const verifyUrl = url => {
    let re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    return re.test(url)
}

const styles = {
    link: {
        textDecoration: 'none',
        color: 'black',
        borderRadius: '20px',
        cursor: 'pointer',
        transition: '0.25s',
        '&:hover': {
            backgroundColor: '#eee'
        }
    },
    holder: {
        position: 'relative',
        height: '200px',
        width: '200px',
        borderRadius: '10px',
        overflow: 'hidden',
        cursor: 'pointer'
    },
    main: {
        position: 'absolute',
        height: '200px',
        width: '200px',
        overflow: 'hidden'
    },
    claimedHolder: {
        position: 'absolute',
        height: '200px',
        width: '200px',
        background: 'rgb(151,51,238)',
        background: 'linear-gradient(45deg, rgba(151,51,238,0.75) 0%, rgba(218,34,255,0.75) 100%)',
        top: '0'
    },
    claimedInner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'Translate(-50%, -50%)',
        textAlign: 'center',
        color: 'white',
        fontWeight: 'bold'
    },
    claimedIcon: {
        fontSize: '2rem'
    },
    topRight: {
        position: 'absolute',
        right: '-2px',
        height: '100px',
        width: '100px',
        overflow: 'hidden'
    },
    bottomRight: {
        position: 'absolute',
        right: '-3px',
        bottom: '-3px',
        height: '100px',
        width: '100px',
        overflow: 'hidden'
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    infoHolder: {
        position: 'relative',
        marginLeft: '8px',
        maxWidth: '190px'
    },
    title: {
        fontWeight: 'bold',
        lineHeight: '1',
        overflowWrap: 'break-word'
    },
    mostDesired: {
        display: 'inline-block',
        height: '.75rem'
    },
    items: {
        position: 'absolute',
        color: 'grey',
        lineHeight: '1'
    }
}

const fileTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/svg+xml",
    "image/webp"
];

const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  }

const trimLongNames = (string) => {
    if (string.length > 82) {
        let shorten = string
        shorten = shorten.slice(0, 83)
        shorten += '...'
        return shorten
    } else {
        return string
    }
}

export class Wish extends Component {
    constructor(props) {
        super(props)

        this.state = {
            open: false,
            editOpen: false,
            copyOpen: false,
            productData: {
                images: []
            },
            errors: {}
        }

        this.handleDialogOpen = this.handleDialogOpen.bind(this)
        this.handleDialogClose = this.handleDialogClose.bind(this)
        this.handleEditOpen = this.handleEditOpen.bind(this)
        this.handleEditClose = this.handleEditClose.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleActiveImage = this.handleActiveImage.bind(this)
        this.handleNewImage = this.handleNewImage.bind(this)
        this.handleMostDesired = this.handleMostDesired.bind(this)
        this.handleEditWishSubmit = this.handleEditWishSubmit.bind(this)
        this.handleHashChange = this.handleHashChange.bind(this)
        this.handleTotalClose = this.handleTotalClose.bind(this)
    }

    componentDidMount() {
        window.addEventListener("hashchange", this.handleHashChange);
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', this.handleHashChange);
    }

    handleHashChange() {
        if (window.location.hash === '') {
            this.handleTotalClose()
        }
    }

    handleDialogOpen() {
        window.location.hash = `#${this.props.wish._id}`
        this.setState({ open: true })
    }

    handleDialogClose() {
        if (window.location.hash !== '') {
            window.history.back()
        }
        this.setState({ open: false })
    }

    handleEditOpen() {
        if (this.props.wish.imageUrl) {
            this.setState({ 
                open: false, 
                editOpen: true, 
                productData: { ...this.props.wish, images: [this.props.wish.imageUrl], activeImageIndex: 1, activeImage: this.props.wish.imageUrl }
            })
        } else {
            this.setState({ 
                open: false, 
                editOpen: true, 
                productData: { ...this.props.wish, images: [], activeImageIndex: 0 }
            })
        }

    }

    handleEditClose() {
        this.setState({ open: true, editOpen: false, productData: {} })
    }

    handleCopyOpen = () => {
        if (this.props.wish.imageUrl) {
            this.setState({ 
                open: false, 
                copyOpen: true, 
                productData: { 
                    ...this.props.wish, 
                    notes: null, 
                    mostDesired: false,
                    images: [this.props.wish.imageUrl], 
                    activeImageIndex: 1, 
                    activeImage: this.props.wish.imageUrl
                 }
            })
        } else {
            this.setState({ 
                open: false, 
                copyOpen: true, 
                productData: { 
                    ...this.props.wish, 
                    notes: null, 
                    mostDesired: false,
                    images: [], 
                    activeImageIndex: 0 
                }
            })
        }
    }

    handleCopyClose = () => {
        this.setState({ open: true, copyOpen: false, productData: {} })
    }

    handleTotalClose() {
        if (window.location.hash !== '') {
            window.history.back()
        }
        this.setState({ open: false, editOpen: false, copyOpen: false, productData: {} })
    }

    handleInputChange(e) {
        const target = e.target
        const value = target.value
        const name = target.name

        this.setState(prevState => ({
            productData: {
                ...prevState.productData,
                [name]: value
            },
            errors: {
                ...this.state.errors,
                [name]: null
            }
        }))
    }

    handleActiveImage(imageUrl, imageIndex) {
        let newImage = false
        if (!verifyUrl(imageUrl) && imageIndex !== 0) {
            newImage = true
        }

        this.setState(prevState => ({
            productData: {
                ...prevState.productData,
                activeImage: imageUrl,
                newImage: newImage,
                activeImageIndex: imageIndex
            }
        }))
    }

    handleNewImage(file) {
        const image = file

        if (!image) return 

        if (fileTypes.includes(image.type)) {

            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 512
            }

            imageCompression(image, options)
            .then(compressedFile => {
                var src = URL.createObjectURL(compressedFile)
                let images = this.state.productData.images
                images.push(src)
                var split = image.name.split('.')
                this.setState(prevState => ({ 
                    productData: {
                        ...prevState.productData,
                        images: images,
                        imageFile: compressedFile,
                        imageFileType: split[split.length-1]
                    }
                }))
            })
            .catch(err => {
                console.log(err.message)
            })

        }
    }

    handleMostDesired() {
        if (this.state.productData.mostDesired) {
            this.setState(prevState => ({ 
                productData: {
                    ...prevState.productData,
                    mostDesired: false
                }
            }))
        } else {
            this.setState(prevState => ({ 
                productData: {
                    ...prevState.productData,
                    mostDesired: true
                }
            }))
        }
    }

    handleEditWishSubmit() {
        let finalProductData = {}
        let productData = this.state.productData

        finalProductData.wishId = this.props.wish._id
        finalProductData.storeUrl = productData.storeUrl
        finalProductData.name = productData.name
        finalProductData.price = productData.price
        finalProductData.notes = productData.notes
        if (productData.mostDesired) {
            finalProductData.mostDesired = productData.mostDesired
        } else {
            finalProductData.mostDesired = false
        }
        

        if (productData.newImage) {
            var storageRef = firebase.storage().ref()
            var image = productData.imageFile
            let randomId = getRandomInt(100000000000000000)
            var imageRef = storageRef.child(`${this.props.owner.userId}/wishImages/${randomId}.${productData.imageFileType}`)

            imageRef.put(image)
            .then((snapshot) => {
                snapshot.ref.getDownloadURL().then(downloadURL => {
                    finalProductData.imageUrl = downloadURL

                    axios.post('/updateWish', finalProductData)
                    .then(data => {
                        console.log(data.data)
                        this.props.replaceEditedWish(data.data)
                        this.handleEditClose()
                    })
                    .catch(err => {
                        console.log(err)
                    })
                })
            })
        } else {
            finalProductData.imageUrl = productData.activeImage

            axios.post('/updateWish', finalProductData)
                .then(data => {
                    console.log(data.data)
                    this.props.replaceEditedWish(data.data)
                    this.handleEditClose()
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    handleCopyWishSubmit = (wishlistId, ownerId, wishlistName) => {
        let finalProductData = {}
        let productData = this.state.productData

        finalProductData.ownerId = ownerId
        finalProductData.listId = wishlistId
        finalProductData.storeUrl = productData.storeUrl
        finalProductData.name = productData.name
        finalProductData.price = productData.price
        finalProductData.notes = productData.notes
        if (productData.mostDesired) {
            finalProductData.mostDesired = productData.mostDesired
        } else {
            finalProductData.mostDesired = false
        }
        

        if (productData.newImage) {
            var storageRef = firebase.storage().ref()
            var image = productData.imageFile
            let randomId = getRandomInt(100000000000000000)
            var imageRef = storageRef.child(`${this.props.owner.userId}/wishImages/${randomId}.${productData.imageFileType}`)

            imageRef.put(image)
            .then((snapshot) => {
                snapshot.ref.getDownloadURL().then(downloadURL => {
                    finalProductData.imageUrl = downloadURL

                    axios.post('/newWish', finalProductData)
                    .then(data => {
                        this.handleTotalClose()
                        this.props.setSnackbarMessage(`Wish added to ${wishlistName}!`, 'success')
                    })
                    .catch(err => {
                        console.log(err)
                    })
                })
            })
        } else {
            finalProductData.imageUrl = productData.activeImage

            axios.post('/newWish', finalProductData)
                .then(data => {
                    this.handleTotalClose()
                    this.props.setSnackbarMessage(`Wish added to ${wishlistName}!`, 'success')
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    render() {
        const { classes, wish } = this.props;

        return (
            <Grid item className={classes.link} >
                <div className={classes.holder} onClick={this.handleDialogOpen}>
                    <div className={classes.main}>
                        <WishImage src={this.props.wish.imageUrl ? this.props.wish.imageUrl : giftImage} />
                        {this.props.wish.claimed && !this.props.myProfile && !this.props.editor ?
                        <div className={classes.claimedHolder}>
                            <div className={classes.claimedInner}>
                                <i className={"fas fa-gift " + classes.claimedIcon}></i>
                                <p>Wish Already Claimed</p>
                            </div>
                        </div> :
                        null }
                    </div>
                </div>
                <div className={classes.infoHolder}>
                    <p className={classes.title}>{ this.props.wish.mostDesired ? <FavoriteIcon className={classes.mostDesired} color='primary' /> : null }{trimLongNames(this.props.wish.name)}</p>
                    <small className={classes.items}>{this.props.wish.price ? '$' : null }{this.props.wish.price}</small>
                </div>
                <WishDialog 
                    open={this.state.open} 
                    handleClose={this.handleDialogClose} 
                    name={wish.name}
                    image={wish.imageUrl}
                    storeUrl={wish.storeUrl}
                    price={wish.price}
                    mostDesired={wish.mostDesired}
                    notes={wish.notes}
                    ownerName={this.props.owner.firstName}
                    editor={this.props.editor}
                    claimed={wish.claimed}
                    wishId={wish._id}
                    myProfile={this.props.myProfile}
                    pinError={this.props.pinError}
                    handlePinErrorReset={this.props.handlePinErrorReset}
                    handleEditOpen={this.handleEditOpen}
                    handleCopyOpen={this.handleCopyOpen}
                    handleClaimWish={this.props.handleClaimWish}
                    handleUnclaimWish={this.props.handleUnclaimWish} />
                <EditWishDialog 
                    open={this.state.editOpen}
                    productData={this.state.productData}
                    errors={this.state.errors}
                    owner={this.props.owner}
                    handleChange={this.handleInputChange}
                    handleImageChange={this.handleActiveImage}
                    handleNewImage={this.handleNewImage}
                    handleMostDesired={this.handleMostDesired}
                    handleEditWishSubmit={this.handleEditWishSubmit}
                    handleDeleteWish={this.props.handleDeleteWish}
                    handleClose={this.handleEditClose} />
                <CopyWishDialog 
                    open={this.state.copyOpen}
                    productData={this.state.productData}
                    errors={this.state.errors}
                    owner={this.props.owner}
                    handleChange={this.handleInputChange}
                    handleImageChange={this.handleActiveImage}
                    handleNewImage={this.handleNewImage}
                    handleMostDesired={this.handleMostDesired}
                    handleCopyWishSubmit={this.handleCopyWishSubmit}
                    handleClose={this.handleCopyClose} />
            </Grid>
        )
    }
}

Wish.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        user: state.userReducer.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setSnackbarMessage: (message, severity) => {
            dispatch(setSnackbarMessage(message, severity))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Wish))
