import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputAdornment from '@material-ui/core/InputAdornment';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import NoImageButton from './NoImageButton'
import ImageButton from './ImageButton'
import AddNewImageButton from './AddNewImageButton'

import axios from 'axios'


const styles = {
    imageHolder: {
        height: '75px',
        width: '75px',
        borderRadius: '10px',
        backgroundColor: 'grey',
        cursor: 'pointer'
    },
    img: {
        height: '100%',
        width: '100%',
        objectFit: 'cover'
    },
    active: {
        padding: '2px',
        borderRadius: '10px',
        border: '3px solid #6c5ce7'
    },
    mostDesired: {
        position: 'relative',
        top: '29%'
    },
    icon: {
        display: 'inline-block',
        position: 'relative',
        cursor: 'pointer',
        color: '#6c5ce7'
    },
    iconText: {
        display: 'inline-block',
        position: 'relative',
        transform: 'TranslateY(-25%)',
        marginLeft: '10px'
    },
    selectList: {
        width: '100%'
    }
}

export class CopyWishDialog extends Component {
    constructor(props) {
        super(props)

        this.state = {
            open: false,
            wishlists: null,
            selectedWishlistId: null,
            selectedWishlistName: null,
            errors: {
                wishlist: null
            }
        }
    }

    componentDidMount() {
        this.getWishlists(this.props.user.uid)
    }

    getWishlists = (ownerId) => {
        axios.get('/getWishlistsByOwner', {
            params: {
                ownerId: ownerId
            }
        })
        .then(wishlists => {
            this.setState({
                loading: false,
                wishlists: wishlists.data
            })
        })
        .catch(err => {
            console.log(err)
            this.setState({
                loading: false
            })
        })
    }

    handleWishlistChange = (e) => {
        var target = e.nativeEvent;
        this.setState({ 
                        selectedWishlistId: e.target.value.split('-')[0], 
                        ownerId: e.target.value.split('-')[1],
                        selectedWishlistName: target.srcElement.innerText,
                        errors: {
                            wishlist: null
                        }
                    })
    }

    handleCopyWishlist = () => {
        if (this.state.selectedWishlistId) {
            this.props.handleCopyWishSubmit(this.state.selectedWishlistId, this.state.ownerId, this.state.selectedWishlistName)
        } else {
            this.setState({ errors: { wishlist: 'Please select a wishlist' } })
        }  
    }

    render() {
        const { classes, productData, theme } = this.props
        const palette = theme.palette

        return (
            <div>
                <Dialog 
                    open={this.props.open} 
                    onClose={this.props.handleClose} 
                    fullWidth
                    maxWidth='sm'
                    scroll='body'
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Copy Wish</DialogTitle>
                <DialogContent>
                    <Grid container direction='column' justify='center' alignItems='stretch' spacing={2}>
                        <Grid item >
                            <TextField
                                margin="dense"
                                id="name"
                                name='name'
                                label="Wish Name"
                                type="text"
                                fullWidth
                                variant='outlined'
                                value={productData.name}
                                helperText={this.props.errors.name}
                                error={this.props.errors.name ? true: false}
                                onChange={this.props.handleChange}
                            />
                        </Grid>
                        <Grid item container direction='row' justify='space-between' alignItems='stretch' alignContent='stretch'>
                            <Grid item sm={6}>
                                <TextField
                                    margin="dense"
                                    id="price"
                                    name='price'
                                    label="Price"
                                    placeholder='0.00'
                                    type="text"
                                    fullWidth
                                    variant='outlined'
                                    value={productData.price}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }} 
                                    helperText={this.props.errors.name}
                                    error={this.props.errors.name ? true: false}
                                    onChange={this.props.handleChange}
                                />
                            </Grid>
                            <Grid item>
                                <div className={classes.mostDesired}>
                                    { productData.mostDesired ? 
                                    <FavoriteRoundedIcon className={classes.icon} onClick={this.props.handleMostDesired} /> :
                                    <FavoriteBorderRoundedIcon className={classes.icon} onClick={this.props.handleMostDesired} /> }
                                    <Typography className={classes.iconText}>Most Desired</Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <TextField 
                                margin="dense"
                                id="url"
                                name='storeUrl'
                                label="URL"
                                type="text"
                                fullWidth
                                variant='outlined'
                                placeholder='Link to a product (Not Required)'
                                value={productData.storeUrl}
                                helperText={this.props.errors.name}
                                error={this.props.errors.name ? true: false}
                                onChange={this.props.handleChange}
                            />
                        </Grid>
                        <Grid item>
                            <FormControl variant='outlined' className={classes.selectList}>
                                <InputLabel id="demo-simple-select-label">Select Wishlist</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.selectedWishlist}
                                    onChange={this.handleWishlistChange}
                                    label='Select Wishlist'
                                >
                                    {
                                    this.state.wishlists ?
                                    this.state.wishlists.map(wishlist => {
                                        if (wishlist._id !== productData.listId) {
                                            return <MenuItem value={`${wishlist._id}-${wishlist.ownerId}`} name={wishlist.name}>{wishlist.name}</MenuItem>
                                        }
                                    })
                                    :
                                        <MenuItem value={'loading'}>Loading Wishlists...</MenuItem>
                                    }
                                </Select>
                                {
                                    this.state.errors.wishlist ?
                                <FormHelperText style={{color: 'red'}}>{this.state.errors.wishlist}</FormHelperText> :
                                    null
                                }
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Typography variant='subtitle1' style={{color: 'grey', textAlign: 'center'}}>Pick an image for your wish</Typography>
                        </Grid>
                        <Grid container item direction='row' justify='center' alignItems='center' spacing={3} >
                            <NoImageButton activeImageIndex={productData.activeImageIndex} handleClick={this.props.handleImageChange} />
                            { this.props.productData.images ? this.props.productData.images.map((image, i) => {
                                return <ImageButton key={i} imageIndex={i+1} activeImageIndex={productData.activeImageIndex} image={image} handleClick={this.props.handleImageChange} />
                            }) : null }
                            <AddNewImageButton handleNewImage={this.props.handleNewImage} />
                        </Grid>
                        <Grid item>
                            <TextField 
                                variant='outlined' 
                                name='notes'
                                fullWidth 
                                label='Wish Notes' 
                                placeholder="For example: 'This one in red', or 'Size medium, please!'" 
                                multiline 
                                rows={3} 
                                value={productData.notes}
                                onChange={this.props.handleChange}
                            />
                        </Grid>
                    </Grid>
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button variant='contained' onClick={this.handleCopyWishlist} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.userReducer.user
    }
}

export default connect(mapStateToProps, null)(withStyles(styles, { withTheme: true })(CopyWishDialog))
