import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Friend from './Friend'

const styles = theme => ({
    spacer: {
        marginTop: '25px'
    }
})

export class FriendsList extends Component {

    render() {
        const { classes, friends } = this.props

        return (
            <Grid className={classes.spacer} container direction='column' justify='center' alignItems='flex-start' spacing={5}>
                <Grid item>
                    <Typography variant='h4' >My Friends</Typography>
                </Grid>
                <Grid item container direction='column'>
                    { friends.map(friend => {
                        return <Friend key={friend._id} friend={friend} unfriend={this.props.unfriend} />
                    }) }
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        authenticated: state.userReducer.authenticated,
        user: state.userReducer.user
    }
}

FriendsList.propTypes = {
    classes: PropTypes.object.isRequired
}

export default connect(mapStateToProps, null)(withStyles(styles)(FriendsList))
