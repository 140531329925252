import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog';

import UrlDialog from './UrlDialog'
import ConfirmDetailsDialog from './ConfirmDetailsDialog'

import axios from 'axios'
import firebase from '../../util/firebase'
import imageCompression from 'browser-image-compression'

const verifyUrl = url => {
    let re = /^https?:\/\/(\w\w\w\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
    return re.test(url)
}

const styles = {
    test: {
        width: '25%',
        height: '25px',
        backgroundColor: 'black'
    }
}

const initialState = {
    stage: 'url',
    url: null,
    productData: {},
    loadingProduct: false,
    errors: {}
}

const fileTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/svg+xml",
    "image/webp"
];

const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  }

export class NewWish extends Component {
    constructor(props) {
        super(props)

        this.state = {
            stage: 'url',
            url: null,
            productData: {},
            loadingProduct: false,
            errors: {}
        }

        this.handleModalClose = this.handleModalClose.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.fetchProductData = this.fetchProductData.bind(this)
        this.handleActiveImage = this.handleActiveImage.bind(this)
        this.handleNewImage = this.handleNewImage.bind(this)
        this.handleMostDesired = this.handleMostDesired.bind(this)
        this.handleNext = this.handleNext.bind(this)
        this.handleManual = this.handleManual.bind(this)
        this.handleNewWishSubmit = this.handleNewWishSubmit.bind(this)
        this.handleHashChange = this.handleHashChange.bind(this)
    }

    componentDidMount() {
        window.addEventListener("hashchange", this.handleHashChange);
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', this.handleHashChange);
    }
    
    handleHashChange() {
        if (window.location.hash === '') {
            this.handleModalClose()
        }
    }

    handleModalClose() {
        this.setState({ ...initialState })
        this.props.handleClose()
    }

    handleInputChange(e) {
        const target = e.target
        const value = target.value
        const name = target.name

        this.setState(prevState => ({
            productData: {
                ...prevState.productData,
                [name]: value
            },
            errors: {
                ...this.state.errors,
                [name]: null
            }
        }))
    }

    fetchProductData() {
        let url = this.state.productData.url
        let cleanUrl = url.split('#:~:')[0]
        if (!verifyUrl(cleanUrl)) {
            this.setState({ errors: { url: 'Please enter a valid URL' } })
        } else {
            this.setState({ loadingProduct: true })
            axios.get('/fetchMetadata', {
                params: {
                    url: cleanUrl
                }
            })
            .then(data => {
                this.parseProductData(data.data)
            })
            .catch(err => {
                console.log(err)
                this.handleModalClose()
                this.setState({ loadingProduct: false })
            })
        }
    }

    parseProductData(data) {
        let productData = {}

        productData.url = data.url
        productData.images = []

        if (data.title !== '') {
            productData.name = data.title
        } else if (data['og:title'] !== '') {
            productData.name = data['og:title']
        }

        if (data.image !== '' && verifyUrl(data.image)) {
            productData.images[0] = data.image
        } else if (data['og:image'] !== '' && verifyUrl(data['og:image'])) {
            productData.images[0] = data['og:image']
        }

        productData.activeImageIndex = 0

        if (data.price !== '' && data.price !== '0') {
            productData.price = data.price

        } else if (data['og:price'] !== '' && data['og:price'] !== '0') {
            productData.price = data['og:price']

        } else if (data['og:price:amount'] !== '' && data['og:price:amount'] !== '0') {
            productData.price = data['og:price:amount']

        } else if (data['og:price:standard_amount'] !== '' && data['og:price:standard_amount'] !== '0') {
            productData.price = data['og:price:standard_amount']

        }

        this.setState({
            productData: productData,
            loadingProduct: false
        })
        this.handleNext()
    }

    handleActiveImage(imageUrl, imageIndex) {
        let newImage = false
        if (!verifyUrl(imageUrl) && imageIndex !== 0) {
            newImage = true
        }

        this.setState(prevState => ({
            productData: {
                ...prevState.productData,
                activeImage: imageUrl,
                newImage: newImage,
                activeImageIndex: imageIndex
            }
        }))
    }

    handleNewImage(file) {
        const image = file

        if (!image) return 

        if (fileTypes.includes(image.type)) {

            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 512
            }

            imageCompression(image, options)
            .then(compressedFile => {
                var src = URL.createObjectURL(compressedFile)
                let images = this.state.productData.images
                images.push(src)
                var split = image.name.split('.')
                this.setState(prevState => ({ 
                    productData: {
                        ...prevState.productData,
                        images: images,
                        imageFile: compressedFile,
                        imageFileType: split[split.length-1]
                    }
                }))
            })
            .catch(err => {
                console.log(err.message)
            })

        }
    }

    handleMostDesired() {
        if (this.state.productData.mostDesired) {
            this.setState(prevState => ({ 
                productData: {
                    ...prevState.productData,
                    mostDesired: false
                }
            }))
        } else {
            this.setState(prevState => ({ 
                productData: {
                    ...prevState.productData,
                    mostDesired: true
                }
            }))
        }
    }

    handleNext() {
        this.setState({ stage: 'confirm' })
    }

    handleManual() {
        this.setState({ stage: 'manual' })
    }

    handleNewWishSubmit() {
        // TODO: Take Care of Errors Later!!!!!

        let finalProductData = {}
        let productData = this.state.productData

        finalProductData.ownerId = this.props.owner._id
        finalProductData.listId = this.props.wishlistId
        finalProductData.storeUrl = productData.url
        finalProductData.name = productData.name
        finalProductData.price = productData.price
        finalProductData.notes = productData.notes
        if (productData.mostDesired) {
            finalProductData.mostDesired = productData.mostDesired
        } else {
            finalProductData.mostDesired = false
        }
        

        if (productData.newImage) {
            var storageRef = firebase.storage().ref()
            var image = productData.imageFile
            let randomId = getRandomInt(100000000000000000)
            var imageRef = storageRef.child(`${this.props.owner._id}/wishImages/${randomId}.${productData.imageFileType}`)

            imageRef.put(image)
            .then((snapshot) => {
                snapshot.ref.getDownloadURL().then(downloadURL => {
                    finalProductData.imageUrl = downloadURL

                    axios.post('/newWish', finalProductData)
                    .then(data => {
                        this.props.insertNewWish(data.data)
                        this.handleModalClose()
                    })
                    .catch(err => {
                        console.log(err)
                    })
                })
            })
        } else {
            finalProductData.imageUrl = productData.activeImage
            console.log(finalProductData)

            axios.post('/newWish', finalProductData)
                .then(data => {
                    this.props.insertNewWish(data.data)
                    this.handleModalClose()
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    render() {
        return (
            <div>
                <Dialog 
                    open={this.props.open} 
                    onClose={this.handleModalClose} 
                    fullWidth
                    maxWidth='sm'
                    scroll='body'
                    aria-labelledby="form-dialog-title">
                    {this.state.stage === 'url' ?
                        <UrlDialog 
                            handleClose={this.handleModalClose} 
                            handleChange={this.handleInputChange} 
                            handleClick={this.fetchProductData}
                            handleManual={this.handleManual} 
                            handleNext={this.handleNext}
                            loading={this.state.loadingProduct}
                            errors={this.state.errors} 
                        />
                    :
                        <ConfirmDetailsDialog 
                            productData={this.state.productData}
                            stage={this.state.stage}
                            handleClose={this.handleModalClose} 
                            handleChange={this.handleInputChange} 
                            handleClick={this.fetchProductData} 
                            handleImageChange={this.handleActiveImage}
                            handleNewImage={this.handleNewImage}
                            handleMostDesied={this.handleMostDesired}
                            handleNewWishSubmit={this.handleNewWishSubmit}
                            errors={this.state.errors} 
                        />
                    }
                </Dialog>
            </div>
        )
    }
}

NewWish.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NewWish)
