import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

import NewWish from './NewWish'

const styles = {
    link: {
        textDecoration: 'none',
        color: 'black',
        borderRadius: '20px',
        cursor: 'pointer',
        transition: '0.25s',
        '&:hover': {
            backgroundColor: '#eee'
        }
    },
    holder: {
        height: '200px',
        width: '200px',
        border: '2px solid grey',
        borderRadius: '10px',
        textAlign: 'center',
        cursor: 'pointer'
    },
    addIcon: {
        position: 'relative',
        top: '50%',
        transform: 'TranslateY(-50%)',
        fontSize: '40px'
    },
    title: {
        fontWeight: 'bold',
        lineHeight: '0.1',
        marginLeft: '5px'
    }
}

export class AddNewWishButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        }

        this.handleOpen = this.handleOpen.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleHashChange = this.handleHashChange.bind(this)
    }
    
    componentDidMount() {
        window.addEventListener("hashchange", this.handleHashChange);
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', this.handleHashChange);
    }
    
    handleHashChange() {
        if (window.location.hash === '') {
            this.handleClose()
        }
    }

    handleOpen() {
        window.location.hash = '#new'
        this.setState({
            open: true
        })
    }

    handleClose() {
        if (window.location.hash !== '') {
            window.history.back()
        }
        this.setState({
            open: false
        })
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item className={classes.link}>
                <Grid item  onClick={this.handleOpen}>
                    <div className={classes.holder} >
                        <AddCircleRoundedIcon className={classes.addIcon} color='primary' />
                    </div>
                    <p className={classes.title}>Add New Wish</p>
                    
                </Grid>
                <NewWish 
                    open={this.state.open} 
                    insertNewWish={this.props.insertNewWish} 
                    handleClose={this.handleClose} 
                    owner={this.props.owner} 
                    wishlistId={this.props.wishlistId} />
            </Grid>
        )
    }
}

AddNewWishButton.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AddNewWishButton)
