import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import MoreVertIcon from '@material-ui/icons/MoreVert'

const styles = theme => ({
    buttonHolder: {
        position: 'absolute',
        right: '0px',
        top: '43%',
        transform: 'TranslateY(-50%)'
    }
})

export class FriendOptions extends Component {
    constructor(props) {
        super(props)

        this.state = {
            open: false,
            status: ''
        }

        this.handleMoreOptions = this.handleMoreOptions.bind(this)
        this.friendButtons = this.friendButtons.bind(this)
        this.handleSendRequest = this.handleSendRequest.bind(this)
        this.handleAccept = this.handleAccept.bind(this)
        this.handleUnfriend = this.handleUnfriend.bind(this)
        this.handleCancelRequest = this.handleCancelRequest.bind(this)
        this.handleDecline = this.handleDecline.bind(this)
    }

    componentDidMount() {
        this.setState({ status: this.props.status })
    }

    handleMoreOptions() {
        this.state.open ? this.setState({ open: false }) : this.setState({ open: true })
    }

    handleSendRequest() {
        this.props.sendFriendRequest(this.props.friend)
        this.setState({ status: 'sent' })
    }

    handleAccept() {
        this.props.acceptFriendRequest(this.props.friend._id)
        this.setState({ status: 'friend' })
    }

    handleUnfriend() {
        this.props.unfriend(this.props.friend._id)
        this.setState({ status: 'notFriends' })
    }

    handleCancelRequest() {
        this.props.cancelFriendRequest(this.props.friend._id)
        this.setState({ status: 'notFriends' })
    }

    handleDecline() {
        this.props.declineFriendRequest(this.props.friend._id)
        this.setState({ status: 'notFriends' })
    }

    friendButtons() {
        switch(this.state.status) {
            case 'friend':
                return (
                    <Grid item container direction='row' alignItems='center'>
                        { this.state.open ? 
                        <Grid item>
                            <Button size='small' variant='outlined' style={{color: 'red'}} onClick={this.handleUnfriend} >Unfriend</Button>
                        </Grid> :
                         null }
                        <Grid item>
                            <IconButton onClick={this.handleMoreOptions}>
                                { this.state.open ? <CloseRoundedIcon /> : <MoreVertIcon /> }
                            </IconButton>
                        </Grid>
                    </Grid>
                )
            case 'pending':
                return (
                    <Grid item container direction='row' alignItems='center'>
                        <Grid item style={{marginRight: '10px'}}>
                            <Button size='small' variant='outlined' style={{color: 'red'}} onClick={this.handleDecline} >Decline</Button>
                        </Grid> 
                         <Grid item>
                            <Button size='small' variant='contained' color='primary' onClick={this.handleAccept}>Accept</Button>
                        </Grid>
                    </Grid>
                )
            case 'sent':
                return (
                    <Grid item container direction='row' alignItems='center'>
                        <Grid item>
                            <Button size='small' variant='outlined' style={{color: 'red'}} onClick={this.handleCancelRequest}>Cancel</Button>
                        </Grid> 
                    </Grid>
                )
            case 'notFriends':
                return (
                    <Grid item container direction='row' alignItems='center'>
                        <Grid item>
                            <Button size='small' variant='contained' color='primary' onClick={this.handleSendRequest}>Add</Button>
                        </Grid>
                    </Grid>
                )
        }
    }

    render() {
        const { classes } = this.props

        return (
            <Grid item className={classes.buttonHolder}>
                {this.friendButtons()}
            </Grid>
        )
    }
}

FriendOptions.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FriendOptions)
