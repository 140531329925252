import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import giftImage from '../../imgs/gift.png'

const styles = {
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    rounded: {
        maxHeight: '300px',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '10px'
    }
}

export class WishImage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            imageSrc: null
        }
    }

    handleImageError = () => {
        this.setState({ imageSrc: giftImage })
    }

    render() {
        const { classes } = this.props

        return (
            <img 
                className={this.props.rounded ? classes.rounded : classes.image} 
                src={this.state.imageSrc ? this.state.imageSrc : this.props.src} 
                onError={this.handleImageError}
            />
        )
    }
}

WishImage.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(WishImage)
