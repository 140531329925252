import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Conatiner from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ShareIcon from '@material-ui/icons/Share';
import EditIcon from '@material-ui/icons/Edit';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import IconButton from '@material-ui/core/IconButton';

import ShareDialog from './ShareDialog'

import moment from 'moment'

import {Helmet} from 'react-helmet'

const styles = theme => ({
    container: {
        backgroundColor: '#dfe6e9',
        paddingBottom: '45px',
        marginTop: '-75px'
    },
    spacer: {
        height: '100px'
    },
    avatarHolder: {
        height: '75px',
        width: '75px',
        backgroundColor: theme.palette.primary.main,
        border: '5px solid white',
        borderRadius: '100%',
        overflow: 'hidden',
        cursor: 'pointer',
        textDecoration: 'none'
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    initials: {
        textAlign: 'center',
        position: 'relative',
        top: '50%',
        transform: 'TranslateY(-50%)',
        color: 'white',
        fontSize: '2rem',
        userSelect: 'none',
        textDecoration: 'none',
        underline: 'none'
    },
    button: {
        width: '150px',
        marginTop: '15px'
    }
})

const daysRemaining = (wishlistDate) => {
    let eventDate = moment(wishlistDate)
    let todaysDate = moment()
    return eventDate.diff(todaysDate, 'days')
}

const formatDaysToGo = (daysRemaining) => {
    if (daysRemaining > 10) {
        return (
            <p><b>{daysRemaining}</b> Days To Go</p>
        )
    } else if (daysRemaining > 1) {
        return (
            <p>Only <b>{daysRemaining}</b> Days Left</p>
        )
    } else if (daysRemaining == 1) {
        return (
            <p>Only <b>{daysRemaining}</b> Day Left</p>
        )
    } else if (daysRemaining == 0) {
        return (
            <b>Today!</b>
        )
    } else {
        return (
            <p><b>{daysRemaining * -1}</b> Day{daysRemaining == -1 ? null : 's'} Ago</p>
        )
    }
}

const formatCollabNames = (owner, collaborators) => {
    let names = owner.firstName

    if (collaborators.length === 1) {
        names += ` and ${collaborators[0].firstName}'s`
    } else {
        for (let i = 0; i < collaborators.length; i++) {
            if ( i === collaborators.length - 1) {
                names += `, and ${collaborators[i].firstName}'s`
            } else {
                names += `, ${collaborators[i].firstName}`
            }
        }
    }

    return names
}

export class WishlistHeader extends Component {
    constructor(props) {
        super(props)

        this.state = {
            shareOpen: false,
            settingsOpen: false,
            confirm: false
        }

        this.handleShareOpen = this.handleShareOpen.bind(this)
        this.handleShareClose = this.handleShareClose.bind(this)
        this.handleConfirmOpen = this.handleConfirmOpen.bind(this)
        this.handleConfirmClose = this.handleConfirmClose.bind(this)
        this.handleRemoveCollaborator = this.handleRemoveCollaborator.bind(this)
        this.handleHashChange = this.handleHashChange.bind(this)
    }

    componentDidMount() {
        window.addEventListener("hashchange", this.handleHashChange);
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', this.handleHashChange);
    }
    
    handleHashChange() {
        if (window.location.hash === '') {
            this.handleShareClose()
        }
    }

    handleShareOpen() {
        window.location.hash = '#share'
        this.setState({ shareOpen: true })
    }

    handleShareClose() {
        if (window.location.hash !== '') {
            window.history.back()
        }
        this.setState({ shareOpen: false })
    }

    handleConfirmOpen() {
        this.setState({ confirm: true })
    }

    handleConfirmClose() {
        this.setState({ confirm: false })
    }

    handleRemoveCollaborator() {
        this.props.removeCollaborator(this.props.user.uid)
    }

    render() {
        const { classes, wishlist } = this.props;

        let editor = false

        if (!this.props.myProfile) {
            if (this.props.authenticated) {
                let collabs = []
                for (let i = 0; i < this.props.collaborators.length; i++){
                    collabs.push(this.props.collaborators[i]._id)
                }
    
                if (collabs.includes(this.props.user.uid)) {
                    editor = true
                }
            }
        }


        return (
            <Conatiner maxWidth='xl' className={classes.container} >
                <Helmet>
                    <title>WishIt | {wishlist.name}</title>
                </Helmet>
                <div className={classes.spacer}></div>
                <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                    <Grid item container direction='row' justify='center' alignItems='center' spacing={2}>
                        <Grid item component={Link} to={`/@${this.props.owner.handle}`} style={{textDecoration: 'none'}}>
                            <div className={classes.avatarHolder} >
                            { !this.props.owner.profileImage ?
                                <Typography variant='h3' className={classes.initials}>{this.props.owner.firstName[0]}{this.props.owner.lastName[0]}</Typography> : 
                                <img className={classes.image} src={this.props.owner.profileImage} /> }
                            </div>
                        </Grid>
                        { this.props.collaborators.map(collaborator => {
                            return (
                                <Grid item component={Link} to={`/@${collaborator.handle}`} style={{textDecoration: 'none'}}>
                                    <div className={classes.avatarHolder} >
                                    { !collaborator.profileImage ?
                                        <Typography variant='h3' className={classes.initials}>{collaborator.firstName[0]}{collaborator.lastName[0]}</Typography> : 
                                        <img className={classes.image} src={collaborator.profileImage} /> }
                                    </div>
                                </Grid>
                            )
                        })
                        }
                    </Grid>
                    <Grid item>
                        { this.props.wishlist.collab ?
                        <Typography variant='h4' style={{textAlign: 'center'}}>{formatCollabNames(this.props.owner, this.props.collaborators)}</Typography>
                        :
                        <Typography variant='h4' style={{textAlign: 'center'}}>{this.props.owner.firstName} {this.props.owner.lastName}'s</Typography>
                        }
                        <Typography variant='h4' style={{textAlign: 'center'}}>{wishlist.name} wishlist</Typography>
                    </Grid>
                    { wishlist.date ? 
                    <Grid container direction="column" justify="center" alignItems="center" spacing={0}>
                        <Grid item>
                            <Typography variant='h6'>{moment(wishlist.date).format('MMMM Do, YYYY')}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='caption'>{formatDaysToGo(daysRemaining(wishlist.date))}</Typography>
                        </Grid>
                    </Grid>
                     :
                    null }
                    { this.props.myProfile || editor ?
                    <Grid item container direction='row' justify='center' alignItems='center' spacing={2}>
                        <Grid item>
                            <Button variant='contained' color='primary' startIcon={<ShareIcon />} onClick={this.handleShareOpen} >
                                Share Wishlist
                            </Button>
                        </Grid>
                        { this.props.myProfile ?
                        <Grid item>
                            <Button variant='outlined' color='primary' component={Link} to={`/@${this.props.user.handle}/wishlist/${this.props.wishlist._id}/edit`} startIcon={<EditIcon />} >
                                Edit Wishlist
                            </Button>
                        </Grid>
                        :
                        null
                        }
                        <ShareDialog open={this.state.shareOpen} handleClose={this.handleShareClose} handle={this.props.user.handle} wishlist={this.props.wishlist} />
                    </Grid>
                    :
                    null }
                    { editor ? 
                        <Grid item container direction='column' justify='center' alignItems='center' spacing={1}>
                            <Grid item>
                                <Typography variant='caption'>Remove yourself as a collaborator?</Typography>
                            </Grid>
                            { !this.state.confirm ?
                            <Grid item container direction='row' justify='center' alignItems='center' spacing={2}>
                                <Grid item>
                                    <Button variant='outlined' size='small' onClick={this.handleConfirmOpen} style={{color: 'red'}} >
                                        Remove
                                    </Button>
                                </Grid>
                            </Grid>
                            :
                            <Grid item container direction='row' justify='center' alignItems='center' spacing={2}>
                                <Grid item>
                                    <Button variant='outlined' size='small' onClick={this.handleConfirmClose} >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' size='small' style={{color: 'red'}} onClick={this.handleRemoveCollaborator} >
                                        Yes, remove
                                    </Button>
                                </Grid>
                            </Grid>
                            }                            

                        </Grid>
                    :
                        null
                    }
                </Grid>
            </Conatiner>
        )
    }
}

WishlistHeader.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        authenticated: state.userReducer.authenticated,
        user: state.userReducer.user
    }
}

export default connect(mapStateToProps, null)(withStyles(styles)(WishlistHeader))
