import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import ContactsRoundedIcon from '@material-ui/icons/ContactsRounded';
import AnnouncementRoundedIcon from '@material-ui/icons/AnnouncementRounded';
import Badge from '@material-ui/core/Badge';

import FriendsList from '../Components/Friends/FriendsList'
import PendingFriendsList from '../Components/Friends/PendingFriendsList'
import FriendSearch from '../Components/Friends/FriendSearch'

import { DECREASE_NOTIFICATION } from '../redux/types'

import axios from 'axios'

const styles = {
    badge: {
        top: '-30px',
        right: '12px',
        pointerEvents: 'none'
    }
}

export class friends extends Component {
    constructor(props) {
        super(props)

        this.state = {
            value: 1,
            friends: [],
            pendingFriends: [],
            sentFriends: []
        }

        this.handleChange = this.handleChange.bind(this)
        this.fetchFriends = this.fetchFriends.bind(this)
        this.getView = this.getView.bind(this)
        this.acceptFriendRequest = this.acceptFriendRequest.bind(this)
        this.unfriend = this.unfriend.bind(this)
        this.cancelFriendRequest = this.cancelFriendRequest.bind(this)
        this.declineFriendRequest = this.declineFriendRequest.bind(this)
        this.sendFriendRequest = this.sendFriendRequest.bind(this)
    }

    componentDidMount() {
        this.fetchFriends()
    }

    handleChange(e, newValue) {
        this.setState({ value:  newValue })
    }

    fetchFriends() {
        axios.get('/getFriends', {
            params: {
                ownerId: this.props.user.uid
            }
        })
        .then(data => {
            let friendData = data.data.friends
            let pendingData = data.data.pending
            let sentData = data.data.sent

            if (!friendData) {
                friendData = []
            }

            if (!pendingData) {
                pendingData = []
            }

            if (!sentData) {
                sentData = []
            }

            this.setState({ friends: friendData, pendingFriends: pendingData, sentFriends: sentData })
        })
        .catch(err => {
            console.log(err)
        })
    }

    acceptFriendRequest(friendId) {
        axios.post('/acceptFriendRequest', {
            params: {
                requesterId: friendId,
                acceptingId: this.props.user.uid
            }
        })
        .then(res => {
            let friends = this.state.friends
            let pending = this.state.pendingFriends
            let index;
            let friend;

            for (let i = 0; i < pending.length; i++) {
                if (pending[i]._id === friendId) {
                    index = i;
                    friend = {
                        ...pending[i],
                        status: 'friend'
                    }
                    break;
                }
            }

            pending.splice(index, 1)
            friends.unshift(friend)

            this.props.decreaseNotification()
            this.setState({ friends: [...friends], pendingFriends: pending })
            localStorage.setItem("authUser", JSON.stringify(this.props.user));
        })
        .catch(err => {
            console.log(err)
        })
    }

    unfriend(friendId) {
        axios.post('/removeFriend', {
            ownerId: this.props.user.uid,
            friendId: friendId
        })
        .then(() => {
            let friends = this.state.friends

            for (let i = 0; i < friends.length; i++) {
                if (friends[i]._id === friendId) {
                    friends.splice(i, 1)
                    break;
                }
            }

            this.setState({ friends: [...friends] })
        })
        .catch(err => {
            console.log(err)
        })
    }

    cancelFriendRequest(friendId) {
        axios.delete('/cancelFriendRequest', {
            params: {
                id: this.props.user.uid,
                friendId: friendId
            }
        })
        .then(() => {
            let sent = this.state.sentFriends

            for (let i = 0; i < sent.length; i++) {
                if (sent[i]._id === friendId) {
                    sent.splice(i, 1)
                    break
                }
            }

            this.setState({ sentFriends: sent })
        })
        .catch(err => {
            console.log(err)
        })
    }

    declineFriendRequest(friendId) {
        axios.delete('/cancelFriendRequest', {
            params: {
                id: friendId,
                friendId: this.props.user.uid
            }
        })
        .then(() => {
            let pending = this.state.pendingFriends

            for (let i = 0; i < pending.length; i++) {
                if (pending[i]._id === friendId) {
                    pending.splice(i, 1)
                    break
                }
            }

            this.props.decreaseNotification()
            this.setState({ pendingFriends: [...pending] })
            localStorage.setItem("authUser", JSON.stringify(this.props.user));
        })
        .catch(err => {
            console.log(err)
        })
    }

    sendFriendRequest(friend) {
        axios.post('/newFriendRequest', {
            params: {
                id: this.props.user.uid,
                friendId: friend._id
            }
        })
        .then(res => {
            friend.status = 'sent'
            this.setState({ sentFriends: [ ...this.state.sentFriends, friend ] })
        })
        .catch(err => {
            console.log(err)
        })
    }

    getView() {
        switch(this.state.value) {
            case 0:
                return (
                    <FriendSearch 
                        friends={this.state.friends} 
                        pending={this.state.pendingFriends} 
                        sent={this.state.sentFriends} 
                        sendFriendRequest={this.sendFriendRequest}
                        acceptFriendRequest={this.acceptFriendRequest} 
                        unfriend={this.unfriend} 
                        cancelFriendRequest={this.cancelFriendRequest}
                        declineFriendRequest={this.declineFriendRequest}
                        sendFriendRequest={this.sendFriendRequest}
                    />
                )
            case 1:
                return (
                    <FriendsList friends={this.state.friends} unfriend={this.unfriend} />
                )
            case 2:
                return ( 
                    <div>
                        <PendingFriendsList friends={this.state.pendingFriends} type='pending' acceptFriendRequest={this.acceptFriendRequest} declineFriendRequest={this.declineFriendRequest} />
                        <PendingFriendsList friends={this.state.sentFriends} type='sent' cancelFriendRequest={this.cancelFriendRequest} />
                    </div>
                )
        }
    }

    render() {
        const { classes } = this.props

        return (
            <Container maxWidth='sm'>
                    <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab icon={<SearchRoundedIcon />} label="Search" />
                        <Tab icon={<ContactsRoundedIcon />} label="My Friends" />
                        <Tab icon={<AnnouncementRoundedIcon />} label={<Badge badgeContent={this.props.user.notifications} color="secondary" classes={{badge: classes.badge}}>
                            Pending
                        </Badge>} />
                    </Tabs>
                    {this.getView()}
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.userReducer.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        decreaseNotification: () => {
            dispatch({ type: DECREASE_NOTIFICATION })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(friends))
