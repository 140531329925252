import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'

import axios from 'axios'

const isEmpty = (string) => {
    if (string.trim() === '') {
        return true
    } else {
        return false
    }
}

export class passwordReset extends Component {
    constructor(props) {
        super(props)

        this.state = {
            token: null,
            password: '',
            confirmPassword: '',
            loading: false,
            errors: {}
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        this.setState({ token: this.props.match.params.token })
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            errors: {
                ...this.state.errors,
                [name]: null
            }
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true })
        let valid = true;
        let newErrors = {};
        const userData = {
            password: this.state.password,
            confirmPassword: this.state.confirmPassword
        };

        if (isEmpty(userData.password)) {
            newErrors.password = 'Password must not be empty'
            valid = false
        }

        if (userData.password !== userData.confirmPassword){
            newErrors.confirmPassword = 'Must match password'
            valid = false
        }

        if (!valid) {
            this.setState({ errors: newErrors, loading: false })
        } else {
            axios.post('/passwordReset', {
                token: this.state.token,
                password: userData.password
            })
            .then(data => {
                this.setState({ success: true, loading: false })
            })
            .catch(err => {
                console.log(err)
                this.setState({ errors: {tokenError: err.response.data.error}, loading: false })
            })
        }
    }

    render() {
        return (
            <div style={{marginTop: '75px'}}>
                <Container maxWidth='xs'>
                <form>
                <Grid container direction='column' justify='center' alignItems='stretch' spacing={3}>
                    <Grid item style={{textAlign: 'center'}}>
                        <Typography variant='h3'>Password Reset</Typography>
                    </Grid>
                    { this.state.errors.tokenError ? 
                    <Grid item>
                        <Typography style={{color: 'red', textAlign: 'center'}}>{this.state.errors.tokenError}</Typography>
                    </Grid>
                     :
                    null }
                    <Grid item>
                        <TextField 
                            name='password' 
                            label='New Password' 
                            placeholder='******' 
                            variant='outlined' 
                            type='password'
                            fullWidth
                            disabled={this.state.success}
                            helperText={this.state.errors.password}
                            error={this.state.errors.password ? true: false}
                            onChange={this.handleInputChange} 
                        />
                    </Grid>
                    <Grid item>
                        <TextField 
                            name='confirmPassword' 
                            label='Confirm Password' 
                            placeholder='******' 
                            variant='outlined' 
                            type='password'
                            fullWidth
                            disabled={this.state.success}
                            helperText={this.state.errors.confirmPassword}
                            error={this.state.errors.confirmPassword ? true: false}
                            onChange={this.handleInputChange} 
                        />
                    </Grid>
                    { this.state.success ? 
                    <Grid item>
                        <Typography style={{color: 'mediumseagreen', textAlign: 'center'}}>Password successfully reset! Please login with your new password</Typography>
                    </Grid>
                     :
                    null }
                    { !this.state.success ? 
                    <Grid item>
                        <Button variant='contained' color='primary' type='submit' onClick={this.handleSubmit} disabled={this.state.loading} fullWidth>
                            Reset Password
                        </Button>
                        {
                            this.state.loading ? 
                            <CircularProgress size={24} style={{position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px'}} /> :
                            null
                        }
                    </Grid>
                    :
                    <Grid item>
                        <Button variant='contained' color='primary' component={Link} to='/login' fullWidth>
                            Log In Now
                        </Button>
                    </Grid>
                    }
                </Grid>
                </form>
                </Container>
            </div>
        )
    }
}

export default passwordReset
