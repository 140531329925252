import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import AddNewWishButton from './AddNewWishButton'
import Wish from './wish'

import axios from 'axios'
import { Helmet } from 'react-helmet'

const styles = {
    container: {
        marginTop: '45px',
        marginBottom: '45px'
    },
    link: {
        textDecoration: 'none',
        color: 'black',
        borderRadius: '20px',
        transition: '0.25s',
        '&:hover': {
            backgroundColor: '#eee'
        }
    },
    holder: {
        height: '200px',
        width: '300px',
        border: '2px solid grey',
        borderRadius: '10px',
        textAlign: 'center',
        cursor: 'pointer'
    },
    addIcon: {
        position: 'relative',
        top: '50%',
        transform: 'TranslateY(-50%)',
        fontSize: '40px'
    },
    title: {
        fontWeight: 'bold',
        lineHeight: '0.1',
        marginLeft: '5px'
    }
}

export class Wishes extends Component {
    constructor(props) {
        super(props)

        this.state = {
            wishes: [],
            activeWishes: 0,
            allWishes: false,
            loadingWishes: true,
            pinError: null
        }

        this.fetchInitialWishes = this.fetchInitialWishes.bind(this)
        this.fetchMoreWishes = this.fetchMoreWishes.bind(this)
        this.handleClaimWish = this.handleClaimWish.bind(this)
        this.handleUnclaimWish = this.handleUnclaimWish.bind(this)
        this.insertNewWish = this.insertNewWish.bind(this)
        this.handlePinErrorReset = this.handlePinErrorReset.bind(this)
        this.replaceEditedWish = this.replaceEditedWish.bind(this)
        this.handleDeleteWish = this.handleDeleteWish.bind(this)
        this.removeDeletedWish = this.removeDeletedWish.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
    }

    componentDidMount() {
        this.fetchInitialWishes()
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    fetchInitialWishes() {
        axios.get('/getWishes', {
            params: {
                listId: this.props.wishlist,
                activeWishes: 0
            }
        })
        .then(wishes => {
            if (wishes.data.length == 10) {
                this.setState({ wishes: wishes.data, activeWishes: wishes.data.length, loadingWishes: false })
            } else {
                this.setState({ wishes: wishes.data, activeWishes: wishes.data.length, allWishes: true, loadingWishes: false })
            }
            this.handleScroll()
        })
        .catch(err => {
            console.log(err)
        })
    }

    handleScroll() { 
        var lastWish = document.querySelector("div#wishContainer > div > div:last-child");
        var lastWishOffset = lastWish.offsetTop + lastWish.clientHeight;
        var pageOffset = window.pageYOffset + window.innerHeight;
        if (pageOffset > lastWishOffset) {
            if (!this.state.allWishes && !this.state.loadingWishes) {
                this.setState({ loadingWishes: true })
                this.fetchMoreWishes()
            }
        }
      };

    fetchMoreWishes() {
        axios.get('/getWishes', {
            params: {
                listId: this.props.wishlist,
                activeWishes: this.state.activeWishes
            }
        })
        .then(wishes => {
            if (wishes.data.length == 10) {
                let activeWishes = this.state.activeWishes + wishes.data.length
                this.setState({ wishes: [...this.state.wishes, ...wishes.data], activeWishes: activeWishes, loadingWishes: false })
            } else {
                let activeWishes = this.state.activeWishes + wishes.data.length
                this.setState({ wishes: [...this.state.wishes, ...wishes.data], activeWishes: activeWishes, allWishes: true, loadingWishes: false })
            }
            this.handleScroll()
        })
        .catch(err => {
            console.log(err)
        })
    }

    insertNewWish(wish) {
        let index = 0
        let newWishes = [...this.state.wishes]
        newWishes.splice(index, 0, wish)

        let newWishCount = this.state.activeWishes + 1
        this.setState({ 
            wishes: newWishes,
            activeWishes: newWishCount
         })
    }

    replaceEditedWish(wish) {
        let newWishes = [...this.state.wishes]
        let index = newWishes.findIndex(oldWish => oldWish._id === wish._id)
        newWishes[index] = wish

        this.setState({
            wishes: newWishes
        })
    }

    handleClaimWish(wishId, claimId) {
        axios.post('/claimWish', {
            params: {
                wishId: wishId,
                claimId: claimId
            }
        })
        .then(wish => {
            let newWishes = [...this.state.wishes]

            for (let i = 0; i < newWishes.length; i++) {
                if (newWishes[i]._id === wish.data._id){
                    newWishes[i] = wish.data
                    break
                }
            }

            this.setState({
                wishes: newWishes
            })
        })
        .catch(err => {
            console.log(err)
        })
    }

    handleUnclaimWish(wishId, claimId) {
        axios.post('/unclaimWish', {
            params: {
                wishId: wishId,
                claimId: claimId
            }
        })
        .then(wish => {
            let newWishes = [...this.state.wishes]

            for (let i = 0; i < newWishes.length; i++) {
                if (newWishes[i]._id === wish.data._id){
                    newWishes[i] = wish.data
                    break
                }
            }

            this.setState({
                wishes: newWishes
            })
        })
        .catch(err => {
            if (err.response.data.message === 'Incorrect PIN') {
                this.setState({ pinError: err.response.data.message })
            }
        })
    }

    handlePinErrorReset() {
        this.setState({ pinError: null })
    }

    handleDeleteWish(wishId, ownerId, listId) {
        axios.post('/deleteWish', {
            wishId: wishId,
            ownerId: ownerId,
            listId: listId
        })
        .then(() => {
            this.removeDeletedWish(wishId)
        }) 
        .catch(err => {
            console.log(err)
        })
    }

    removeDeletedWish(wishId) {
        let newWishes = [...this.state.wishes]
        let index = newWishes.findIndex(oldWish => oldWish._id === wishId)
        newWishes.splice(index, 1)

        let newWishCount = this.state.activeWishes - 1

        this.setState({
            wishes: newWishes,
            activeWishes: newWishCount
        })
    }

    render() {
        const { classes } = this.props;
        let editor = false

        if (!this.props.myProfile) {
            if (this.props.auth) {
                let collabs = []
                for (let i = 0; i < this.props.collaborators.length; i++){
                    collabs.push(this.props.collaborators[i]._id)
                }
    
                if (collabs.includes(this.props.uid)) {
                    editor = true
                }
            }
        }

        return (
            <Container id='wishContainer' maxWidth='lg' className={classes.container}>
                { this.state.wishes.length > 0 ?
                    <Helmet>
                        <meta name="twitter:card" content="photo" />
                        <meta name="twitter:site" content="@example" />
                        <meta name="twitter:title" content="My picture" />
                        <meta name="twitter:description" content="A description" />
                        <meta name="twitter:image" content="http://example.com/test.jpg" />
                        <meta name="twitter:url" content={window.location.href.split('#')[0]} />
                    </Helmet>
                :
                    null
                }
                <Grid container direction="row" justify='center' alignItems="center" spacing={5}>
                    {this.props.myProfile || editor ? <AddNewWishButton owner={this.props.owner} wishlistId={this.props.wishlist} insertNewWish={this.insertNewWish}/> : null }
                    { this.state.wishes.map(wish => {
                        return (
                            <Wish 
                                key={wish._id} 
                                wish={wish} 
                                owner={this.props.owner} 
                                editor={editor}
                                myProfile={this.props.myProfile}
                                pinError={this.state.pinError}
                                handlePinErrorReset={this.handlePinErrorReset}
                                replaceEditedWish={this.replaceEditedWish}
                                handleDeleteWish={this.handleDeleteWish}
                                handleClaimWish={this.handleClaimWish}
                                handleUnclaimWish={this.handleUnclaimWish} />)
                    }) }

                </Grid>
            </Container>
        )
    }
}

Wishes.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Wishes)
