import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import store from '../redux/store'
import { getCurrentUserData } from '../redux/actions/userActions'

import { verifyUserDataInputs } from '../util/validators'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button'
import PhotoCameraRoundedIcon from '@material-ui/icons/PhotoCameraRounded';
import CircularProgress from '@material-ui/core/CircularProgress'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import axios from 'axios'
import firebase from '../util/firebase'
import imageCompression from 'browser-image-compression'

import { logoutUser } from '../redux/actions/userActions'
import { CLEAR_ERRORS } from '../redux/types'

const styles = theme => ({
    container: {
        margin: 'auto'
    },
    avatarHolder: {
        height: '150px',
        width: '150px',
        backgroundColor: theme.palette.primary.main,
        border: '5px solid white',
        borderRadius: '100%',
        overflow: 'hidden'
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    initials: {
        textAlign: 'center',
        position: 'relative',
        top: '50%',
        transform: 'TranslateY(-50%)',
        color: 'white'
    },
    deleteButton: {
        marginTop: '25px'
    }
})

const fileTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/svg+xml",
    "image/webp"
];

export class profileEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            handle: '',
            profileImage: null,
            imageFile: null,
            errors: {},
            success: null,
            sending: false,
            open: false,
            loading: false,
            firstLoad: true,
            deleted: false
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleEditPicture = this.handleEditPicture.bind(this)
        this.handleImageChange = this.handleImageChange.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.postData = this.postData.bind(this)
        this.handleDeleteOpen = this.handleDeleteOpen.bind(this)
        this.handleDeleteClose = this.handleDeleteClose.bind(this)
        this.handleDeleteAccount = this.handleDeleteAccount.bind(this)
    }

    componentDidMount() {
        let url = this.props.location.pathname
        let urlSplit = url.split('/')
        let handle = urlSplit[1]
        let handleSplit = handle.split('@')
        let trueHandle = handleSplit[1]
        console.log(trueHandle)
        this.setState({
            pathHandle: trueHandle,
            firstName: this.props.user.firstName,
            lastName: this.props.user.lastName,
            email: this.props.user.email,
            handle: this.props.user.handle,
            profileImage: this.props.user.profileImage,
            imageFile: null,
            firstLoad: false
        })
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            success: null,
            errors: {
                ...this.state.errors,
                [name]: null
            }
        })
    }

    handleEditPicture() {
        const fileInput = document.getElementById('imageInput');
        fileInput.click();
        this.setState({ 
            success: null,
            errors: {
            ...this.state.errors,
            image: null
        } })
    };

    handleImageChange(e) {
        const image = e.target.files[0];

        if (!image) return 

        if (fileTypes.includes(image.type)) {
            console.log(`originalFile size ${image.size / 1024 / 1024} MB`);

            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 512
            }

            imageCompression(image, options)
            .then(compressedFile => {
                console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
                var src = URL.createObjectURL(compressedFile)
                this.setState({ profileImage: src, imageFile: compressedFile, success: null })
            })
            .catch(err => {
                console.log(err.message)
            })

        } else {
            this.setState({ errors: { image: 'Please select a valid image file (i.e. jpg, png, etc.)' } })
        }
    }

    handleReset() {
        this.setState({
            firstName: this.props.user.firstName,
            lastName: this.props.user.lastName,
            email: this.props.user.email,
            handle: this.props.user.handle,
            profileImage: this.props.user.profileImage,
            imageFile: null,
            errors: {},
            success: null,
            sending: false
        })
    }

    handleSubmit() {
        const userData = {
            userId: this.props.user.uid,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            handle: this.state.handle,
            imageURL: this.props.user.profileImage
        }

        let errors = verifyUserDataInputs(userData)

        if (Object.keys(errors).length > 0) {
            this.setState({ errors: errors, sending: false, error: null })
        } else {
            this.postData(userData)
            this.setState({ sending: true, error: null })
        }
    }

    postData(userData) {
        if (this.state.imageFile) {
            var storageRef = firebase.storage().ref()
            var image = this.state.imageFile
            var split = image.name.split('.')
            var fileType = split[split.length-1]
            var imageRef = storageRef.child(`profile_images/${this.props.user.uid}/profilePic.${fileType}`)

            imageRef.put(image)
            .then((snapshot) => {
                console.log(snapshot)
                snapshot.ref.getDownloadURL().then(downloadURL => {
                    userData.imageURL = downloadURL

                    axios.post('/updateUserDetails', userData)
                    .then(() => {
                        store.dispatch(getCurrentUserData(userData.userId));
                        this.setState({ success: true, sending: false })
                    })
                    .catch(err => {
                        console.log(err)
                        this.setState({ 
                            success: false,
                            sending: false,
                            error: err.response.data.error
                        })
                    })
                })
            })
        } else {
            axios.post('/updateUserDetails', userData)
                .then(() => {
                    store.dispatch(getCurrentUserData(userData.userId));
                    this.setState({ success: true, sending: false })
                })
                .catch(err => {
                    console.log(err)
                    this.setState({
                        success: false,
                        sending: false,
                        error: err.response.data.error
                    })
                })
        }
    }

    handleDeleteOpen() {
        this.setState({ open: true })
    }

    handleDeleteClose() {
        this.setState({ open: false })
    }

    handleDeleteAccount() {
        axios.post('/deleteUser', {
            userId: this.props.user.uid
        })
        .then(() => {
            this.props.logoutUser()
        })
        .catch(err => {
            console.log(err)
        })
    }

    render() {
        const { classes, theme } = this.props;
        const palette = theme.palette

        if (!this.state.firstLoad) {
        if (this.state.pathHandle === this.props.user.handle || this.state.success) {
        return (
            <div>
                <Container maxWidth='xs'>
                <Grid container direction="column" justify='center' alignItems='stretch' spacing={4} >
                    <Grid item>
                        <Typography variant='h4' style={{textAlign: 'center'}} >Profile Settings</Typography>
                    </Grid>
                    <Grid item container direction='column' justify="center" alignItems="center" spacing={2} >
                        <Grid item>
                            <div className={classes.avatarHolder} >
                                { !this.state.profileImage ?
                                <Typography variant='h3' className={classes.initials}>{this.props.user.firstName[0]}{this.props.user.lastName[0]}</Typography> : 
                                <img className={classes.image} src={this.state.profileImage} alt='Profile' /> }
                            </div>
                        </Grid>
                        <Grid item>
                            <input
                                type="file"
                                id="imageInput"
                                hidden="hidden"
                                accept='image/*'
                                onChange={this.handleImageChange}
                            />
                            <Button 
                                variant='outlined'
                                color='primary'
                                startIcon={<PhotoCameraRoundedIcon />}
                                onClick={this.handleEditPicture}
                            >
                                Change Photo
                            </Button>
                        </Grid>
                        { this.state.errors.image ? 
                        <Grid item>
                            <Typography variant='caption' style={{color: palette.error.main}} >{this.state.errors.image}</Typography>
                        </Grid>
                         :
                        null}

                    </Grid>
                    <Grid item container direction='row' justify='space-between' alignItems='center' spacing={2}>
                        <Grid item md={6} xs={12}>
                            <TextField 
                                name='firstName'
                                label="First Name" 
                                variant="outlined" 
                                fullWidth
                                helperText={this.state.errors.firstName}
                                error={this.state.errors.firstName ? true: false}
                                onChange={this.handleInputChange}
                                value={this.state.firstName} />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField 
                                name='lastName'
                                label="Last Name" 
                                variant="outlined" 
                                fullWidth
                                helperText={this.state.errors.lastName}
                                error={this.state.errors.lastName ? true: false}
                                onChange={this.handleInputChange}
                                value={this.state.lastName} />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TextField 
                            name='handle'
                            label="Handle" 
                            variant="outlined" 
                            fullWidth
                            helperText={this.state.errors.handle}
                            error={this.state.errors.handle ? true: false}
                            onChange={this.handleInputChange}
                            value={this.state.handle}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">wishit.com/@</InputAdornment>,
                            }} 
                        />
                    </Grid>
                    <Grid item>
                        <TextField 
                            name='email'
                            label="Email" 
                            variant="outlined" 
                            fullWidth
                            helperText={this.state.errors.email}
                            error={this.state.errors.email ? true: false}
                            onChange={this.handleInputChange}
                            value={this.state.email} />
                    </Grid>
                    { this.state.sending ? <CircularProgress /> : null }
                    { this.state.success !== null ?
                    <Grid item container justify='center'>
                        <Typography variant='caption' style={this.state.success ? {color: palette.success.main} : {color: palette.error.main}}>
                            {this.state.success ? 'Your profile settings have been updated!' : `${this.state.error}`}
                        </Typography>
                    </Grid> :
                    <br /> }
                    <Grid item container direction='row-reverse' justify='center' alignItems='center' spacing={3}>
                        <Grid item>
                            <Button variant='contained' color='primary' onClick={this.handleSubmit} >Update Account</Button>
                        </Grid>
                        <Grid item>
                            <Button variant='outlined' onClick={this.handleReset} >Reset</Button>
                        </Grid>
                    </Grid>
                    <Grid item container justify='center'>
                        <Button 
                            className={classes.deleteButton} 
                            style={{color: palette.error.main}} 
                            startIcon={<DeleteForeverIcon />}
                            onClick={this.handleDeleteOpen}
                        >
                            Delete Account
                        </Button>
                    </Grid>
                </Grid>

                <Dialog
                    open={this.state.open}
                    onClose={this.handleDeleteClose}
                    maxWidth='xs'
                    fullWidth
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle style={{textAlign: 'center'}} id="alert-dialog-title">Delete your Account?</DialogTitle>
                    <DialogContent>
                    <DialogContentText style={{textAlign: 'center'}} id="alert-dialog-description">
                        Are you sure? Deleting your account will:
                        <List style={{color: palette.error.main}} aria-label="main mailbox folders">
                            <ListItem>
                                <ListItemIcon>
                                    <HighlightOffRoundedIcon style={{color: palette.error.main}} />
                                </ListItemIcon>
                                <ListItemText primary="Permanently delete your account" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <HighlightOffRoundedIcon style={{color: palette.error.main}} />
                                </ListItemIcon>
                                <ListItemText primary="Permanently delete all of your wishlists" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <HighlightOffRoundedIcon style={{color: palette.error.main}} />
                                </ListItemIcon>
                                <ListItemText primary="Permanently delete all of your wishes" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <HighlightOffRoundedIcon style={{color: palette.error.main}} />
                                </ListItemIcon>
                                <ListItemText primary="Permanently delete any of your wishes claimed by your friends" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <HighlightOffRoundedIcon style={{color: palette.error.main}} />
                                </ListItemIcon>
                                <ListItemText primary="Permanently delete any wishes you've claimed from your friend's wishlists" />
                            </ListItem>
                        </List>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleDeleteClose} style={{position: 'absolute', left: '10px'}} color="primary" variant='outlined'>
                        Cancel
                    </Button>
                    <Button onClick={this.handleDeleteAccount} variant='outlined' style={{color: palette.error.main, marginLeft: '25px'}}>
                        Yes, Delete my Account
                    </Button>
                    </DialogActions>
                </Dialog>
                </Container>
            </div>
        )
                    } else {
                        return <Redirect to={`/@${this.state.pathHandle}`} />
                    }
                } else {
                    return <p>Loading...</p>
                }
    }
}

const mapStateToProps = state => {
    return {
        user: state.userReducer.user,
        errors: state.userReducer.errors
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logoutUser: () => {
            dispatch(logoutUser());
        },
        clearErrors: () => {
            dispatch({ type: CLEAR_ERRORS })
        }
    }
}

profileEdit.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(profileEdit)))
