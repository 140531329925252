import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { loginUser } from '../redux/actions/userActions'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import axios from 'axios'

import { CLEAR_ERRORS } from '../redux/types'

const isEmpty = (string) => {
    if (string.trim() === '') {
        return true
    } else {
        return false
    }
}

const isValidEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}


export class login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            recoverEmail: '',
            loading: false,
            open: false,
            errors: {}
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePasswordReset = this.handlePasswordReset.bind(this);
    }

    componentDidMount() {
        this.props.clearErrors()
    }

    componentDidUpdate() {
        if (this.state.loading && this.props.errors != null) {
            this.setState({ loading: false })
        }
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            errors: {
                ...this.state.errors,
                [name]: null
            }
        })
    }

    handleOpen() {
        this.setState({ open: true })
    }

    handleClose() {
        this.setState({ open: false })
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true })
        let valid = true;
        let newErrors = {};
        const userData = {
            email: this.state.email.toLowerCase(),
            password: this.state.password
        };

        if (isEmpty(userData.email)) {
            newErrors.email = 'Email must not be empty'
            valid = false
        } else if (!isValidEmail(userData.email)){
            newErrors.email = 'Email must be valid'
            valid = false
        }

        if (isEmpty(userData.password)) {
            newErrors.password = 'Password must not be empty'
            valid = false
        }

        if (valid) {
            this.props.clearErrors()
            this.props.loginUser(userData);
        } else {
            this.setState({ errors: newErrors, loading: false })
        }
    }

    handlePasswordReset() {
        let valid = true;
        let newErrors = {};
        if (isEmpty(this.state.recoverEmail)) {
            newErrors.recoverEmail = 'Email must not be empty'
            valid = false
        } else if (!isValidEmail(this.state.recoverEmail)){
            newErrors.recoverEmail = 'Email must be valid'
            valid = false
        }

        if (!valid) {
            this.setState({ errors: newErrors, loading: false })
        } else {
            axios.post('/forgotPassword', {
                email: this.state.recoverEmail
            })
            .then(data => {
                this.setState({ passwordResetSent: true, errors: {} })
            })
            .catch(err => {
                console.log(err)
                this.setState({ errors: {...this.state.errors, recoverEmail: err.response.data.error} })
            })
        }

    }

    render() {
        return (
            <div style={{height: '100vh', overflow: 'hidden', marginTop: '-75px'}}>
            <Container maxWidth='xs'>
            <form noValidate>
            <Grid container item direction='column' justify='center' alignItems='center' spacing={2} style={{background: 'white', padding: '50px 0px', borderRadius: '10px', marginTop: '150px'}}>
                <Grid item>
                    <Typography variant='h2'>
                        Login
                    </Typography>
                </Grid>
                
                <Grid item spacing={5}>
                    <TextField 
                        name='email' 
                        label='Email' 
                        placeholder='your@email.com' 
                        variant='outlined' 
                        helperText={this.state.errors.email}
                        error={this.state.errors.email ? true: false}
                        onChange={this.handleInputChange} 
                    />
                </Grid>
                <Grid item container direction='column' justify='center' alignItems='center' spacing={1}>
                    <Grid item>
                        <TextField 
                            name='password' 
                            label='Password' 
                            placeholder='*********' 
                            variant='outlined' 
                            type='password' 
                            helperText={this.state.errors.password}
                            error={this.state.errors.password ? true: false} 
                            onChange={this.handleInputChange} />
                    </Grid>
                    <Grid item>
                        <Typography variant='caption' color='primary' style={{cursor: 'pointer'}} onClick={this.handleOpen}>Forgot your password?</Typography>
                    </Grid>
                </Grid>

                {
                    this.props.errors ? 
                    <Grid item>
                        <Typography variant='caption' style={{color: 'red'}}>{this.props.errors}</Typography>
                    </Grid> :
                    null
                }
                <Grid item style={{position: 'relative', marginTop: '15px'}}>
                    <Button variant='contained' color='primary' type='submit' onClick={this.handleSubmit} disabled={this.state.loading}>
                        Login
                    </Button>
                    {
                        this.state.loading ? 
                        <CircularProgress size={24} style={{position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px'}} /> :
                        null
                    }
                </Grid>
                
                <br />
                <small>
                    Don't have an account? Sign up <Link to='/signup' style={{textDecoration: 'none', color: 'blueviolet'}}>here</Link>!
                </small>
            </Grid>
            </form>
            </Container>
            <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="password-reset" maxWidth='sm'>
                <DialogTitle id="form-dialog-title">Password Reset</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        It happens! Toss us your email and we'll send you a link to reset your password so you can get back to wishing!
                    </DialogContentText>
                    <TextField
                        autoFocus
                        name='recoverEmail'
                        margin="dense"
                        id="recoverEmail"
                        label="Email Address"
                        type="email"
                        fullWidth
                        helperText={this.state.errors.recoverEmail}
                        error={this.state.errors.recoverEmail ? true: false} 
                        onChange={this.handleInputChange}
                    />
                    { this.state.passwordResetSent ? 
                    <Typography variant='caption' style={{fontWeight: 'bold', color: 'mediumseagreen'}}>Please check your email for further instructions!</Typography> :
                    null }
                </DialogContent>
                <DialogActions>
                <Button onClick={this.handleClose} style={{position: 'absolute', left: '8px'}}>
                    Cancel
                </Button>
                <Button onClick={this.handlePasswordReset} color="primary" variant='contained' disableElevation>
                    Send password reset link
                </Button>
                </DialogActions>
            </Dialog>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.userReducer.user,
    errors: state.userReducer.errors
})

const mapDispatchToProps = dispatch => {
    return {
        loginUser: (userData) => {
            dispatch(loginUser(userData))
        },
        clearErrors: () => {
            dispatch({ type: CLEAR_ERRORS })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(login)
