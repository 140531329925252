import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Conatiner from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { Helmet } from 'react-helmet'

const styles = theme => ({
    container: {
        backgroundColor: '#dfe6e9',
        paddingBottom: '45px',
        marginTop: '-75px'
    },
    spacer: {
        height: '100px'
    },
    avatarHolder: {
        height: '150px',
        width: '150px',
        backgroundColor: theme.palette.primary.main,
        border: '5px solid white',
        borderRadius: '100%',
        overflow: 'hidden'
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    initials: {
        textAlign: 'center',
        position: 'relative',
        top: '50%',
        transform: 'TranslateY(-50%)',
        color: 'white'
    },
    button: {
        width: '150px',
        marginTop: '15px'
    }
})

export class Header extends Component {
    render() {
        const { classes } = this.props

        return (
            <Conatiner maxWidth='xl' className={classes.container} >
                <Helmet>
                    {this.props.user ? <title>WishIt | @{this.props.user.handle}</title> : null }
                </Helmet>
                <div className={classes.spacer}></div>
                <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                    <Grid item>
                        <div className={classes.avatarHolder} >
                        { !this.props.user.profileImage ?
                            <Typography variant='h3' className={classes.initials}>{this.props.user.firstName[0]}{this.props.user.lastName[0]}</Typography> : 
                            <img className={classes.image} src={this.props.user.profileImage} /> }
                        </div>
                    </Grid>
                    <Grid item>
                        <Typography variant='h4'>{this.props.user.firstName} {this.props.user.lastName}</Typography>
                    </Grid>
                </Grid>
            </Conatiner>
        )
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Header)
