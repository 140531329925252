const isEmpty = (string) => {
    if (string.trim() === '') {
        return true
    } else {
        return false
    }
}

const isValidEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

const verifyUserDataInputs = (userData) => {

    let errors = {}

    if (userData.firstName !== undefined) {
        if (isEmpty(userData.firstName)) {
            errors.firstName = 'Must not be empty'
        }
    }

    if (userData.lastName !== undefined) {
        if (isEmpty(userData.lastName)) {
            errors.lastName = 'Must not be empty'
        }
    }

    if (userData.email !== undefined) {
        if (isEmpty(userData.email)) {
            errors.email = 'Must not be empty'
        } else if (!isValidEmail(userData.email)) {
            errors.email = 'Must be a valid email address'
        }
    }

    if (userData.handle !== undefined) {
        if (isEmpty(userData.handle)) {
            errors.handle = 'Must not be empty'
        }
    }

    if (userData.password !== undefined) {
        if (isEmpty(userData.password)) {
            errors.password = 'Must not be empty'
        }
    }

    if (userData.confirmPassword !== undefined) {
        if (userData.password !== userData.confirmPassword) {
            errors.confirmPassword = 'Passwords must match'
        }
    }

    return errors

}

module.exports = {
    verifyUserDataInputs
}
