import store from '../redux/store'
import { SET_UNAUTHENTICATED, SET_AUTHENTICATED, SET_USER } from '../redux/types';
import { getCurrentUserData } from '../redux/actions/userActions'

import axios from 'axios';
import jwtDecode from 'jwt-decode'

export const authHandler = () => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('authUser'));
    const loggedIn = sessionStorage.getItem('loggedIn');
    if (token) {
      const decodedToken = jwtDecode(token)
      const now = Date.now().valueOf() / 1000
      if (decodedToken.exp < now) {
        localStorage.removeItem('token');
        store.dispatch({ type: SET_UNAUTHENTICATED })
      } else {
        axios.defaults.headers.common['x-access-token'] = token
        if (user && loggedIn) {
          store.dispatch({ type: SET_AUTHENTICATED })
          store.dispatch({ type: SET_USER, user: {...user} })
        } else {
          store.dispatch(getCurrentUserData(decodedToken.id));
        }
      }
    }
}