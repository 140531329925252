import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

const styles = {
    link: {
        textDecoration: 'none',
        color: 'black',
        borderRadius: '20px',
        transition: '0.25s',
        '&:hover': {
            backgroundColor: '#eee'
        }
    },
    holder: {
        height: '200px',
        width: '300px',
        border: '2px solid grey',
        borderRadius: '10px',
        textAlign: 'center',
        cursor: 'pointer'
    },
    addIcon: {
        position: 'relative',
        top: '50%',
        transform: 'TranslateY(-50%)',
        fontSize: '40px'
    },
    title: {
        fontWeight: 'bold',
        lineHeight: '0.1',
        marginLeft: '5px'
    }
}

export class AddNewListButton extends Component {
    render() {
        const { classes } = this.props;

        return (
            <Grid item className={classes.link} component={Link} to='/wishlist/new'>
                <div className={classes.holder} >
                    <AddCircleRoundedIcon className={classes.addIcon} color='primary' />
                </div>
                <p className={classes.title}>Add List</p>
            </Grid>
        )
    }
}

AddNewListButton.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AddNewListButton)
