import React, { Component } from 'react'
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { setSnackbarMessage, clearSnackbar } from '../redux/actions/userActions'
import { CLEAR_SNACKBAR } from '../redux/types';

export class SnackbarUtil extends Component {
    constructor(props) {
        super(props)

    }

    handleClose = () => {
        this.props.clearSnackbar()
    }

    render() {
        return (
            <Snackbar open={this.props.snackbarOpen} autoHideDuration={5000} onClose={this.handleClose}>
                <MuiAlert elevation={6} variant="filled" onClose={this.handleClose} severity={this.props.snackbarSeverity}>
                    {this.props.snackbarMessage}
                </MuiAlert>
            </Snackbar>
        )
    }
}

const mapStateToProps = state => ({
    snackbarOpen: state.userReducer.snackbarOpen,
    snackbarMessage: state.userReducer.snackbarMessage,
    snackbarSeverity: state.userReducer.snackbarSeverity
})

const mapDispatchToProps = dispatch => {
    return {
        setSnackbarMessage: (message, severity) => {
            dispatch(setSnackbarMessage(message, severity))
        },
        clearSnackbar: () => {
            dispatch({ type: CLEAR_SNACKBAR })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarUtil)
