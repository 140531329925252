import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'

import giftImage from '../imgs/gift.png'
import giftImageDark from '../imgs/giftDark.png'

import WishImage from './Utils/WishImage'

import axios from 'axios'

const styles = {
    link: {
        textDecoration: 'none',
        color: 'black',
        borderRadius: '20px',
        transition: '0.25s',
        '&:hover': {
            backgroundColor: '#eee'
        }
    },
    holder: {
        position: 'relative',
        height: '200px',
        width: '300px',
        borderRadius: '10px',
        overflow: 'hidden',
        cursor: 'pointer'
    },
    main: {
        position: 'absolute',
        height: '200px',
        width: '200px',
        overflow: 'hidden'
    },
    topRight: {
        position: 'absolute',
        right: '-2px',
        height: '100px',
        width: '100px',
        overflow: 'hidden'
    },
    bottomRight: {
        position: 'absolute',
        right: '-3px',
        bottom: '-3px',
        height: '100px',
        width: '100px',
        overflow: 'hidden'
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    infoHolder: {
        position: 'relative',
        marginLeft: '10px'
    },
    title: {
        fontWeight: 'bold',
        lineHeight: '0.1'
    },
    items: {
        position: 'absolute',
        color: 'grey',
        lineHeight: '0'
    }
}


export class ListPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            images: []
        }

        this.fetchPreviewImages = this.fetchPreviewImages.bind(this)
    }

    componentDidMount() {
        this.fetchPreviewImages()
    }

    fetchPreviewImages() {
        axios.get('/getPreviewImages', {
            params: {
                listId: this.props.listId
            }
        })
        .then(images => {
            this.setState({
                loading: false,
                images: images.data.images
            })
        })
        .catch(err => {
            this.setState({
                loading: false
            })
            console.log(err)
        })
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid item className={classes.link} component={Link} to={`/@${this.props.userHandle}/wishlist/${this.props.listId}`}>
                <div className={classes.holder}>
                    <div className={classes.main}>
                        <WishImage src={this.state.images[0] ? this.state.images[0] : giftImage} />
                    </div>
                    <div className={classes.topRight}>
                        <WishImage src={this.state.images[1] ? this.state.images[1] : giftImage} />
                    </div>
                    <div className={classes.bottomRight}>
                        <WishImage src={this.state.images[2] ? this.state.images[2] : giftImage} />
                    </div>
                </div>
                <div className={classes.infoHolder}>
                    <p className={classes.title}>{this.props.listName}</p>
                    <small className={classes.items}>{this.props.giftCount} {this.props.giftCount == 1 ? 'Wish' : 'Wishes'}</small>
                </div>

            </Grid>
        )
    }
}

ListPreview.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ListPreview)
