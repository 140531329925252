import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';

import { 
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon
} from 'react-share'

const styles = {
    url: {
        '& .MuiInputBase-root.Mui-disabled': {
            color: '#666',
            backgroundColor: '#eee',
        },
        '& .MuiInputBase-input.Mui-disabled': {
            cursor: 'pointer'
        }
    }
}

export class ShareDialog extends Component {
    constructor(props) {
        super(props)

        this.state = {
            copied: false,
            timeout: 0
        }

        this.handleDialogClose = this.handleDialogClose.bind(this)
        this.copyToClipboard = this.copyToClipboard.bind(this)
        this.handleCopySwitch = this.handleCopySwitch.bind(this)
    }

    handleDialogClose() {
        this.props.handleClose()
    }

    copyToClipboard(e) {
        if (this.state.timeout) {
            clearTimeout(this.state.timeout)
        }
        let target = e.target
        target.disabled = false
        target.focus()
        target.select()
        document.execCommand('copy');
        window.getSelection().removeAllRanges()
        target.disabled = true
        this.setState({ 
            copied: true,
            timeout: setTimeout(() => {
                this.handleCopySwitch()
            }, 2000)
        })
    }

    handleCopySwitch() {
        this.setState({ copied: false })
    }

    render() {
        const { classes } = this.props

        return (
            <Dialog
                open={this.props.open} 
                onClose={this.handleDialogClose} 
                maxWidth='sm'
                scroll='body'
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Share Your Wishlist</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Ready to share you wishes? Send the link below to any of your friends or family and they'll be able to start claiming your wishes.
                    </DialogContentText>
                    <TextField
                        className={classes.url}
                        margin="dense"
                        id="url"
                        name='url'
                        type="text"
                        variant='outlined'
                        value={`https://wishit.xyz/@${this.props.handle}/wishlist/${this.props.wishlist._id}`}
                        fullWidth
                        disabled
                        onClick={this.copyToClipboard}
                    />
                    <Typography variant='caption' style={this.state.copied ? {color: '#6c5ce7'} : {color: 'grey'}}>{this.state.copied ? 'Copied!' : 'Click Text to Copy Link'}</Typography>
                    <Typography style={{textAlign: 'center', paddingTop: '15px', paddingBottom: '15px'}}>
                        Or share with a click
                    </Typography>
                    <Grid container direction='row' justify='center' alignItems='center' spacing={2}>
                        <Grid item>
                            <FacebookShareButton
                                url={`https://wishit.xyz/@${this.props.handle}/wishlist/${this.props.wishlist._id}`}
                                quote={`Checkout my new ${this.props.wishlist.name} wishlist on WishIt!`}
                            >
                                <FacebookIcon size={48} round={true} />
                            </FacebookShareButton>
                        </Grid>
                        <Grid item>
                            <TwitterShareButton
                                url={`https://wishit.xyz/@${this.props.handle}/wishlist/${this.props.wishlist._id}`}
                                title={`Checkout my new ${this.props.wishlist.name} wishlist on WishIt!`}
                                hashtags={['wishit', 'wishlist']}
                            >
                                <TwitterIcon size={48} round={true} />
                            </TwitterShareButton>
                        </Grid>
                        <Grid item>
                            <EmailShareButton 
                                url={`https://wishit.xyz/@${this.props.handle}/wishlist/${this.props.wishlist._id}`}
                                subject={`Checkout my new ${this.props.wishlist.name} wishlist on WishIt!`}
                                body={`Hey there! I would love you to checkout my awesome ${this.props.wishlist.name} wishlist on WishIt. Let me know if anything looks good ;)`}
                                separator={`\n\n`}
                            >
                                <EmailIcon size={48} round={true} />
                            </EmailShareButton>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleDialogClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

ShareDialog.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ShareDialog)
