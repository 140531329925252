import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputAdornment from '@material-ui/core/InputAdornment';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import NoImageButton from './NoImageButton'
import ImageButton from './ImageButton'
import AddNewImageButton from './AddNewImageButton'


const styles = {
    imageHolder: {
        height: '75px',
        width: '75px',
        borderRadius: '10px',
        backgroundColor: 'grey',
        cursor: 'pointer'
    },
    img: {
        height: '100%',
        width: '100%',
        objectFit: 'cover'
    },
    active: {
        padding: '2px',
        borderRadius: '10px',
        border: '3px solid #6c5ce7'
    },
    mostDesired: {
        position: 'relative',
        top: '29%'
    },
    icon: {
        display: 'inline-block',
        position: 'relative',
        cursor: 'pointer',
        color: '#6c5ce7'
    },
    iconText: {
        display: 'inline-block',
        position: 'relative',
        transform: 'TranslateY(-25%)',
        marginLeft: '10px'
    }
}

export class EditWishDialog extends Component {
    constructor(props) {
        super(props)

        this.state = {
            open: false
        }

        this.handleDeleteOpen = this.handleDeleteOpen.bind(this)
        this.handleDeleteClose = this.handleDeleteClose.bind(this)
        this.handleDeleteWish = this.handleDeleteWish.bind(this)
    }

    handleDeleteOpen() {
        this.setState({ open: true })
    }

    handleDeleteClose() {
        this.setState({ open: false })
    }

    handleDeleteWish() {
        this.props.handleDeleteWish(this.props.productData._id, this.props.owner._id, this.props.productData.listId)
    }

    render() {
        const { classes, productData, theme } = this.props
        const palette = theme.palette

        return (
            <div>
                <Dialog 
                    open={this.props.open} 
                    onClose={this.props.handleClose} 
                    fullWidth
                    maxWidth='sm'
                    scroll='body'
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Edit Wish Details</DialogTitle>
                <DialogContent>
                    <Grid container direction='column' justify='center' alignItems='stretch' spacing={2}>
                        <Grid item >
                            <TextField
                                margin="dense"
                                id="name"
                                name='name'
                                label="Wish Name"
                                type="text"
                                fullWidth
                                variant='outlined'
                                value={productData.name}
                                helperText={this.props.errors.name}
                                error={this.props.errors.name ? true: false}
                                onChange={this.props.handleChange}
                            />
                        </Grid>
                        <Grid item container direction='row' justify='space-between' alignItems='stretch' alignContent='stretch'>
                            <Grid item sm={6}>
                                <TextField
                                    margin="dense"
                                    id="price"
                                    name='price'
                                    label="Price"
                                    placeholder='0.00'
                                    type="text"
                                    fullWidth
                                    variant='outlined'
                                    value={productData.price}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }} 
                                    helperText={this.props.errors.name}
                                    error={this.props.errors.name ? true: false}
                                    onChange={this.props.handleChange}
                                />
                            </Grid>
                            <Grid item>
                                <div className={classes.mostDesired}>
                                    { productData.mostDesired ? 
                                    <FavoriteRoundedIcon className={classes.icon} onClick={this.props.handleMostDesired} /> :
                                    <FavoriteBorderRoundedIcon className={classes.icon} onClick={this.props.handleMostDesired} /> }
                                    <Typography className={classes.iconText}>Most Desired</Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <TextField 
                                margin="dense"
                                id="url"
                                name='storeUrl'
                                label="URL"
                                type="text"
                                fullWidth
                                variant='outlined'
                                placeholder='Link to a product (Not Required)'
                                value={productData.storeUrl}
                                helperText={this.props.errors.name}
                                error={this.props.errors.name ? true: false}
                                onChange={this.props.handleChange}
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant='subtitle1' style={{color: 'grey', textAlign: 'center'}}>Pick an image for your wish</Typography>
                        </Grid>
                        <Grid container item direction='row' justify='center' alignItems='center' spacing={3} >
                            <NoImageButton activeImageIndex={productData.activeImageIndex} handleClick={this.props.handleImageChange} />
                            { this.props.productData.images ? this.props.productData.images.map((image, i) => {
                                return <ImageButton key={i} imageIndex={i+1} activeImageIndex={productData.activeImageIndex} image={image} handleClick={this.props.handleImageChange} />
                            }) : null }
                            <AddNewImageButton handleNewImage={this.props.handleNewImage} />
                        </Grid>
                        <Grid item>
                            <TextField 
                                variant='outlined' 
                                name='notes'
                                fullWidth 
                                label='Wish Notes' 
                                placeholder="For example: 'This one in red', or 'Size medium, please!'" 
                                multiline 
                                rows={3} 
                                value={productData.notes}
                                onChange={this.props.handleChange}
                            />
                        </Grid>
                    </Grid>
                    
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={this.handleDeleteOpen}
                        style={{position: 'absolute', left: '10px', color: palette.error.main}}
                        startIcon={<DeleteForeverIcon />}
                    >
                        Delete Wish
                    </Button>
                    <Button onClick={this.props.handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button variant='contained' onClick={this.props.handleEditWishSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                    open={this.state.open}
                    onClose={this.handleDeleteClose}
                    maxWidth='xs'
                    fullWidth
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle style={{textAlign: 'center'}} id="alert-dialog-title">Delete this wish?</DialogTitle>
                    <DialogContent>
                    <DialogContentText style={{textAlign: 'center'}} id="alert-dialog-description">
                        Are you sure? Deleting this wish will:
                        <List style={{color: palette.error.main}} aria-label="main mailbox folders">
                            <ListItem>
                                <ListItemIcon>
                                    <HighlightOffRoundedIcon style={{color: palette.error.main}} />
                                </ListItemIcon>
                                <ListItemText primary="Permanently remove this wish from your wishlist" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <HighlightOffRoundedIcon style={{color: palette.error.main}} />
                                </ListItemIcon>
                                <ListItemText primary="Remove this wish from any of your friends claimed wishes" />
                            </ListItem>
                        </List>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleDeleteClose} style={{position: 'absolute', left: '10px'}} color="primary" variant='outlined'>
                        Cancel
                    </Button>
                    <Button onClick={this.handleDeleteWish} variant='outlined' style={{color: palette.error.main, marginLeft: '25px'}}>
                        Yes, Delete this Wish
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.userReducer.user
    }
}

export default connect(mapStateToProps, null)(withStyles(styles, { withTheme: true })(EditWishDialog))
