import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

import Friend from './Friend'

import axios from 'axios'

const styles = theme => ({
    spacer: {
        marginTop: '25px'
    }
})

export class FriendSearch extends Component {
    constructor(props) {
        super(props)

        this.state = {
            search: '',
            searchedFriends: [],
            firstSearch: true,
            noneFound: false,
            typingTimeout: 0
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout)
        }

        if (value != '') {
            this.setState({
                [name]: value,
                errors: {
                    ...this.state.errors,
                    [name]: null
                },
                typingTimeout: setTimeout(() => {
                    this.handleSearch()
                }, 1000)
            })
        } else {
            this.setState({
                [name]: value,
                errors: {
                    ...this.state.errors,
                    [name]: null
                }
            })
        }
    }

    handleSearch() {
        axios.get('/searchUsers', {
            params: {
                searchTerm: this.state.search
            }
        })
        .then(data => {
            console.log(data.data.users)
            let users = data.data.users
            if (users.length > 0) {

                for (let x = 0; x < users.length; x++) {
                    let { friends, pending, sent } = this.props
                    let found = false
                    for (let i = 0; i < pending.length; i++) {
                        if (users[x]._id === pending[i]._id) {
                            users[x] = {
                                ...users[x],
                                status: 'pending'
                            }
                            found = true
                            break
                        }
                    }

                    if (!found) {
                        for (let i = 0; i < sent.length; i++) {
                            if (users[x]._id === sent[i]._id) {
                                users[x] = {
                                    ...users[x],
                                    status: 'sent'
                                }
                                found = true
                                break
                            }
                        }
                    }

                    if(!found) {
                        for (let i = 0; i < friends.length; i++) {
                            if (users[x]._id === friends[i]._id) {
                                users[x] = {
                                    ...users[x],
                                    status: 'friend'
                                }
                                found = true
                                break
                            }
                        }
                    }

                    if (!found) {
                        users[x] = {
                            ...users[x],
                            status: 'notFriends'
                        }
                    }

                };

                this.setState({ searchedFriends: users, firstSearch: false, noneFound: false })
            } else {
                this.setState({ searchedFriends: [], firstSearch: false, noneFound: true })
            }

        })
        .catch(err => {
            console.log(err)
        })
    }

    render() {
        const { classes } = this.props

        const friends = this.state.searchedFriends

        return (
            <Grid className={classes.spacer} container direction='column' justify='center' alignItems='flex-start' spacing={5}>
                <Grid item container justify='center' alignItems='stretch'>
                    <Grid item xs={12}>
                        <TextField
                            placeholder='Search for Users'
                            name='search'
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><SearchRoundedIcon /></InputAdornment>,
                            }}
                            variant="outlined"
                            onChange={this.handleInputChange}
                        />
                    </Grid>
                </Grid>
                {this.state.firstSearch ? <Grid item><Typography style={{textAlign: 'center'}}>Use the search bar to search for users!</Typography></Grid> : null}
                {this.state.noneFound ? 
                <Grid item>
                    <Typography style={{textAlign: 'center'}}>No users found :(</Typography>
                </Grid>
                :
                    <Grid item container direction='column'>
                        { friends.map(friend => {
                            return <Friend 
                                        key={friend._id} 
                                        friend={friend} 
                                        acceptFriendRequest={this.props.acceptFriendRequest} 
                                        unfriend={this.props.unfriend} 
                                        cancelFriendRequest={this.props.cancelFriendRequest}
                                        declineFriendRequest={this.props.declineFriendRequest}
                                        sendFriendRequest={this.props.sendFriendRequest}
                                    />
                        }) }
                    </Grid>
                }
            </Grid>
        )
    }
}

FriendSearch.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FriendSearch)
