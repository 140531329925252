import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { logoutUser } from '../redux/actions/userActions'
import SignedInNav from './SignedInNav'

const styles = {
    bar: {
        backgroundColor: 'rgba(255, 255, 255, 0.0)',
        boxShadow: 'none'
    },
    title: {
        position: 'relative',
        top: '1px',
        textDecoration: 'none',
        fontWeight: 'bold',
        fontSize: '1.5rem',
        fontFamily: 'Kaushan Script, cursive'
    }
}

export class NavBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            prevScrollPos: window.pageYOffset,
            visible: true
        }

    }

    render() {
        const auth = this.props.authenticated
        const { classes } = this.props;

        return (
            <AppBar className={classes.bar} position="static">
                <Toolbar>
                    <Grid container justify='space-between'>
                        <Grid item>
                            <Typography component={Link} to='/' color='primary' className={classes.title}>WishIt</Typography>
                        </Grid>
                        { auth ?
                            <SignedInNav user={this.props.user} />
                            :
                            <Grid item >
                                <Button component={Link} to='/login' variant='outlined' style={{marginRight: '25px'}} startIcon={<LockOutlinedIcon />} >Log In</Button>
                                <Button component={Link} to='/signup' variant='contained' color='primary'>Sign Up</Button>
                            </Grid>
                        }
                    </Grid>
                </Toolbar>
            </AppBar>
        )
    }
}

const mapStateToProps = state => {
    return {
        authenticated: state.userReducer.authenticated,
        user: state.userReducer.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logoutUser: () => {
            dispatch(logoutUser());
        }
    }
}

NavBar.propTypes = {
    classes: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NavBar))
