import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#6c5ce7'
        },
        secondary: {
            main: '#51E89C'
        }
    },
    typography: {
        fontFamily: 'Open Sans, sans-serif',
        fontWeightLight: '300',
        fontWeightRegular: '400',
        fontWeightMedium: '600',
        fontWeightBold: '700',
        button: {
            textTransform: 'capitalize'
        }
    },

})
