import React, { Component } from 'react'

export class noMatch extends Component {
    render() {
        return (
            <div>
                404 - Page not found
            </div>
        )
    }
}

export default noMatch
