import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import ClaimedWish from './ClaimedWish'

import axios from 'axios'

const styles = {
    container: {
        marginTop: '45px',
        marginBottom: '45px'
    },
    link: {
        textDecoration: 'none',
        color: 'black',
        borderRadius: '20px',
        transition: '0.25s',
        '&:hover': {
            backgroundColor: '#eee'
        }
    },
    holder: {
        height: '200px',
        width: '300px',
        border: '2px solid grey',
        borderRadius: '10px',
        textAlign: 'center',
        cursor: 'pointer'
    },
    addIcon: {
        position: 'relative',
        top: '50%',
        transform: 'TranslateY(-50%)',
        fontSize: '40px'
    },
    title: {
        fontWeight: 'bold',
        lineHeight: '0.1',
        marginLeft: '5px'
    }
}

export class ClaimedWishes extends Component {
    constructor(props) {
        super(props)

        this.state = {
            wishes: []
        }

        this.fetchClaimedWishes = this.fetchClaimedWishes.bind(this)
        this.handleUnclaimWish = this.handleUnclaimWish.bind(this)
        this.removeUnclaimedWish = this.removeUnclaimedWish.bind(this)
    }

    componentDidMount() {
        this.fetchClaimedWishes()
    }

    fetchClaimedWishes() {
        axios.get('/getClaimedWishes', {
            params: {
                userId: this.props.userId
            }
        })
        .then(wishes => {
            this.setState({ wishes: wishes.data })
        })
        .catch(err => {
            console.log(err)
        })
    }

    handleUnclaimWish(wishId) {
        axios.post('/unclaimWish', {
            params: {
                wishId: wishId,
                claimId: this.props.user.uid
            }
        })
        .then(wish => {
            this.removeUnclaimedWish(wish.data)
        })
        .catch(err => {
            console.log(err)
        })
    }

    removeUnclaimedWish(wish) {
        let newWishes = [...this.state.wishes]

        let index = newWishes.findIndex(oldWish => oldWish._id === wish._id)
        newWishes.splice(index, 1)

        this.setState({
            wishes: newWishes
        })
    }

    render() {
        const { classes } = this.props

        return (
            <Container maxWidth='lg' className={classes.container}>
                <Grid container direction="row" justify='center' alignItems="center" spacing={5}>
                    { this.state.wishes.length > 0 ? this.state.wishes.map(wish => {
                        return (
                            <ClaimedWish 
                                key={wish._id} 
                                wish={wish} 
                                handleUnclaimWish={this.handleUnclaimWish} />)
                    }) :
                    <p>You haven't claimed any wishes yet!</p> }

                </Grid>
            </Container>
        )
    }
}

ClaimedWishes.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        user: state.userReducer.user
    }
}

export default connect(mapStateToProps, null)(withStyles(styles)(ClaimedWishes))
