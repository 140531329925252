import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
    title: {
        margin: '50px 0px 35px 0px',
        textAlign: 'center'
    },
    header: {
        fontWeight: '600'
    },
    wishit: {
        fontFamily: 'Kaushan Script, cursive',
        color: theme.palette.primary.main
    },
    primaryAccent: {
        fontWeight: 'bold',
        color: theme.palette.primary.main
    },
    accentText: {
        fontWeight: 'bold',
        color: '#333'
    },
    link: {
        color: theme.palette.primary.main,
        fontWeight: '600',
        textDecoration: 'none'
    }
})

export class faq extends Component {
    constructor(props) {
        super(props)

        this.getToLink = this.getToLink.bind(this)
    }

    getToLink(desination) {
        let to = '/login'
        if (this.props.authenticated) {
            switch(desination) {
                case 'profile':
                    to = `/@${this.props.user.handle}`
                    break
                case 'settings':
                    to = `/@${this.props.user.handle}/settings`
            }
            
        }

        return to
    }

    render() {

        const { classes } = this.props

        return (
            <div >
                <Container maxWidth='md'>
                    <Grid container direction="column" justify="center" alignItems="center" spacing={5} >
                        <Grid item>
                            <Typography variant='h4' className={classes.title}>Frequently Asked Questions</Typography>
                        </Grid>
                        <Grid item>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.header}>What even is this?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container direction='column' justify='flex-start' spacing={2}>
                                        <Grid item>
                                            <Typography variant='h6'>
                                                This is <span className={classes.wishit}>WishIt!</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography >
                                                Are you tired of always having to remember which wishlist you used to saved that awesome product? {<br/>}
                                                Are you tired of the hundreds of other sites just like this one? {<br/>} {<br />}
                                                <span className={classes.accentText}>Well you're in luck!</span> <span className={classes.wishit}>WishIt</span> allows you to create the ultimate wishlists from products
                                                you find anywhere on the web using only a URL.
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography >
                                                The best part? Once you curate the perfect wishlist, you can share it with your 
                                                friends and family so they can know exactly what you want the most.
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography >
                                                No more duplicates! Once a wish has been claimed, no one else will be able to claim it, so you can be sure each gift will be unique.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.header}>Will my friends see which wishes I claim?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container direction='column' justify='flex-start' spacing={2}>
                                        <Grid item>
                                            <Typography className={classes.accentText}>
                                                Shhh... It's a secret!
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography >
                                                True friends keep secrets, and <span className={classes.wishit}>WishIt</span> is no exception.
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography >
                                                We understand that the best part of giving is the suprise on their face, so we've made sure that your
                                                friends will only know you've claimed a wish once they're unwrapping it.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.header}>Do I need an account to claim wishes?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container direction='column' justify='flex-start' spacing={2}>
                                        <Grid item>
                                            <Typography className={classes.accentText}>
                                                Nope!
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography >
                                                While creating an account allows you to easily keep track and find all of the wishes you claim, you absolutely don't need one.
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography >
                                                Using our innovative PIN system, you can still claim and unclaim your friends' wishes using only a few numbers or letters.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.header}>Can I see which wishes have been claimed from my lists?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container direction='column' justify='flex-start' spacing={2}>
                                        <Grid item>
                                            <Typography className={classes.accentText}>
                                                Don't even think about it!
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography >
                                                You wouldn't look in the closet or under the bed for your gifts, would you?
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography >
                                                Suprise can be the best part of getting a gift, so we've made sure you won't see which of your wishes have been claimed.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.header}>How do I buy wishes I claim?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container direction='column' justify='flex-start' spacing={2}>
                                        <Grid item>
                                            <Typography >
                                                Once you've claimed a wish, you will see a button that will take you directly to the website to buy the wish.
                                                You can find all of the wishes you've claimed on <Link className={classes.link} to={this.getToLink('profile')} >your profile.</Link>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.header}>What occasion is Wishit best suited for?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container direction='column' justify='flex-start' spacing={2}>
                                        <Grid item>
                                            <Typography className={classes.accentText}>
                                                Anything!
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography >
                                                Birthdays, holidays, weddings. Oh my! <span className={classes.wishit}>WishIt</span> is the perfect solution to make sure you
                                                get the perfect gifts to make every occasion unforgettable.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.header}>How do I share my wishlists?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container direction='column' justify='flex-start' spacing={2}>
                                        <Grid item>
                                            <Typography >
                                                Once you've created your perfect wishlist you can use the handy-dandy share button you'll find right below your wishlist name.
                                                You can either send your friends and family a direct link to your wishlist, or let everyone know using your favorite social media platform.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.header}>Can I customize my profile handle?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container direction='column' justify='flex-start' spacing={2}>
                                        <Grid item>
                                            <Typography className={classes.accentText}>
                                                You sure can!
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography >
                                                Just visit your <Link className={classes.link} to={this.getToLink('settings')}>profile settings</Link> and update your profile handle to anything you like!
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.header}>Can I add a profile picture?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container direction='column' justify='flex-start' spacing={2}>
                                        <Grid item>
                                            <Typography className={classes.accentText}>
                                                Of course!
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography >
                                                Just visit your <Link className={classes.link} to={this.getToLink('settings')}>profile settings</Link> and upload your favorite picture to customize your profile!
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.header}>How much does Wishit cost?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        For you? <span className={classes.accentText}>Free forever</span>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>

                </Container>
            </div>
        )
    }
}

faq.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        authenticated: state.userReducer.authenticated,
        user: state.userReducer.user
    }
}

export default connect(mapStateToProps, null)(withStyles(styles)(faq))
