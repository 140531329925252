import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'

import OnlineWish from '../imgs/Online_wishes.svg'
import OnlineShopping from '../imgs/Online_shopping.svg'
import Share from '../imgs/Share.svg'
import Claim from '../imgs/Claim.svg'
import BirthdayGirl from '../imgs/Birthday_girl.svg'
import ChristmasTree from '../imgs/Christmas_tree.svg'
import Wedding from '../imgs/Wedding.svg'
import Questions from '../imgs/Questions.svg'

const styles = theme => ({
    subtitle:{
        color: '#777',
        fontWeight: '400'
    },
    ctaButtons: {
        marginTop: '24px',
        '@media (max-width: 600px)': {
            marginTop: '0px'
        }
    },
    heroImage: {
        maxHeight: '50vh',
        maxWidth: '80vw',
        marginTop: '24px'
    },
    midContainer: {
        backgroundColor: '#6c5ce7',
        color: 'white'
    },
    fancyText: {
        fontFamily: 'Kaushan Script, cursive'
    },
    sideImage: {
        width: '100%',
        maxWidth: '500px'
    },
    item1: {
        [theme.breakpoints.down('sm')]: {
            order: 1
        }
    },
    item2: {
        [theme.breakpoints.down('sm')]: {
            order: 2
        }
    }
})

export class home extends Component {
    render() {
        const { classes } = this.props

        return (
            <div>
                <Container style={{height: '85vh'}}>
                    <Grid container justify='center' direction="column" alignItems="center" spacing={3} style={{textAlign: 'center'}}>
                        <Grid item style={{marginTop: '40px'}}>
                            <Typography variant='h3'>The Last Wishlist You Will Ever Need</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='h6' className={classes.subtitle}>Add gifts from anywhere. Share them with anyone.</Typography>
                        </Grid>
                        <Grid item container justify='center' direction="row" alignItems="center" spacing={1} className={classes.ctaButtons}>
                            <Grid item>
                                <Button 
                                    variant='contained' 
                                    color='primary' 
                                    component={Link} 
                                    to={this.props.authenticated ? `/@${this.props.user.handle}` : '/signup'} 
                                    disableElevation
                                >
                                        Get Started
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant='outlined' color='primary' component={Link} to='/faq'>Learn More</Button>
                            </Grid>
                        </Grid>
                        <Grid item container justify='center' alignItems='flex-start'>
                            <Grid item>
                                <img className={classes.heroImage} src={OnlineWish} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                <Container className={classes.midContainer} maxWidth={false} style={{paddingBottom: '75px'}}>
                    <Grid container justify='center' direction="column" alignItems="center" style={{paddingTop: '100px'}}>
                        <Typography className={classes.fancyText} style={{color: '#51E89C', textAlign: 'center'}} variant='h2'>How's it Work?</Typography>
                    </Grid>
                    <Grid container justify='center' direction="row" alignItems="center" spacing={2} style={{paddingTop: '75px', paddingBottom: '50px', borderBottom: '1px solid #42388c'}}>
                        <Grid item md={4}>
                            <img className={classes.sideImage} src={OnlineShopping} />
                        </Grid>
                        <Grid item md={6} style={{textAlign: 'center'}}>
                            <Grid item>
                                <Typography variant='h3' className={classes.fancyText}>Add Wishes from Anywhere</Typography>
                            </Grid>
                            <Grid item style={{marginTop: '50px'}}>
                                <Typography style={{maxWidth: '600px', position: 'relative', margin: 'auto'}}>Add any prodcut from around the web using only it's URL! No url? No worries! You can manually add any wish you can imagine!</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify='center' direction="row" alignItems="center" spacing={2} style={{paddingTop: '75px', paddingBottom: '50px', borderBottom: '1px solid #42388c'}}>
                        <Grid item md={6} style={{textAlign: 'center'}} className={classes.item2}>
                            <Grid item>
                                <Typography variant='h3' className={classes.fancyText}>Share with your Friends</Typography>
                            </Grid>
                            <Grid item style={{marginTop: '50px'}}>
                                <Typography style={{maxWidth: '600px', position: 'relative', margin: 'auto'}}>Send your wishlists to your friends and family so they can see what you've been wishing for most.</Typography>
                            </Grid>
                        </Grid>
                        <Grid item md={4} className={classes.item1}>
                            <img className={classes.sideImage} src={Share} style={{maxWidth: '400px'}}/>
                        </Grid>
                    </Grid>
                    <Grid container justify='center' direction="row" alignItems="center" spacing={2} style={{paddingTop: '75px'}}>
                        <Grid item md={4}>
                            <img className={classes.sideImage} src={Claim} />
                        </Grid>
                        <Grid item md={6} style={{textAlign: 'center'}}>
                            <Grid item>
                                <Typography variant='h3' className={classes.fancyText}>Claim and Be Claimed</Typography>
                            </Grid>
                            <Grid item style={{marginTop: '50px'}}>
                                <Typography style={{maxWidth: '600px', position: 'relative', margin: 'auto'}}>The second best thing to getting a gift is giving one! Easily claim wishes from your friends' lists while they're busy claiming yours.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth={false} style={{paddingBottom: '150px'}}>
                    <Grid container justify='center' direction="column" alignItems="center" style={{paddingTop: '150px'}}>
                        <Typography className={classes.fancyText} style={{color: '#6c5ce7'}} variant='h2'>Perfect for Any Occassion</Typography>
                    </Grid>
                    <Grid container justify='center' direction="row" alignItems="center" spacing={2} style={{paddingTop: '75px'}}>
                        <Grid item md={3}>
                            <img className={classes.sideImage} src={BirthdayGirl} />
                        </Grid>
                        <Grid item md={3}>
                            <img className={classes.sideImage} src={ChristmasTree} />
                        </Grid>
                        <Grid item md={3}>
                            <img className={classes.sideImage} src={Wedding} />
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth={false} style={{backgroundColor: '#51E89C', paddingBottom: '75px'}}>
                    <Grid container justify='center' direction="row" alignItems='center' spacing={2} style={{paddingTop: '75px'}}>
                    <Grid item md={4}>
                            <img className={classes.sideImage} src={Questions} />
                        </Grid>
                        <Grid item md={6} style={{textAlign: 'center'}}>
                            <Grid item>
                                <Typography variant='h4' className={classes.fancyText} color='primary'>Still have Questions?</Typography>
                            </Grid>
                            <Grid item style={{marginTop: '50px', marginBottom: '25px'}}>
                                <Typography style={{maxWidth: '600px', position: 'relative', margin: 'auto'}}>Check out our frequently asked questions!</Typography>
                            </Grid>
                            <Grid item>
                                <Button variant='outlined' color='primary' component={Link} to='/faq'>Frequently Asked Questions</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth={false} style={{backgroundColor: '#aaa', paddingTop: '25px', paddingBottom: '25px'}}>
                    <Grid container justify='center' direction="column" alignItems="center">
                        <Typography className={classes.fancyText} style={{color: 'white'}} variant='caption'>Wishit &copy; 2020</Typography>
                    </Grid>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        authenticated: state.userReducer.authenticated,
        user: state.userReducer.user
    }
}

export default connect(mapStateToProps, null)(withStyles(styles)(home))
