import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

class AuthRoute extends React.Component {
    render() {
        const Component = this.props.component;
        const isAuthenticated = this.props.authenticated;

        return isAuthenticated ? (
            <Component />
        ) : (
            <Redirect to='/login' />
        )
    }
}

const mapStateToProps = state => ({
    authenticated: state.userReducer.authenticated
})

export default connect(mapStateToProps, null)(AuthRoute);


