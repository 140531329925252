import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Header from '../Components/Profile/Header'
import AuthHeader from '../Components/Profile/AuthHeader'
import AuthLists from '../Components/Profile/AuthLists'
import ClaimedWishes from '../Components/Profile/ClaimedWishes'

import axios from 'axios'

import ProfileSkeleton from '../Components/skeletons/ProfileHeaderSkeleton'


export class profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            userProfile: {},
            friendStatus: '',
            refresh: false,
            activeView: 'wishlists',
            fetched: false,
            handle: null
        }

        this.fetchUser = this.fetchUser.bind(this)
        this.sendFriendRequest = this.sendFriendRequest.bind(this)
        this.acceptFriendRequest = this.acceptFriendRequest.bind(this)
        this.cancelFriendRequest = this.cancelFriendRequest.bind(this)
        this.denyFriendRequest = this.denyFriendRequest.bind(this)
        this.resetRefresh = this.resetRefresh.bind(this)
        this.handleSwitchToLists = this.handleSwitchToLists.bind(this)
        this.handleSwitchToClaimed = this.handleSwitchToClaimed.bind(this)
    }

    componentDidMount() {
        const { userHandle } = this.props.match.params;
        this.setState({ handle: userHandle })
        this.fetchUser(userHandle);
    }

    componentDidUpdate() {
        const { userHandle } = this.props.match.params;

        if (this.state.userProfile.handle) {
            if (userHandle.toLowerCase() !== this.state.userProfile.handle.toLowerCase()) {
                this.fetchUser(userHandle)
            }
        } else {
            if (this.state.handle != userHandle) {
                this.fetchUser(userHandle)
            }
        }
    }

    fetchUser(handle) {
        axios.get(`/getUserByHandle/${handle}`)
            .then(res => {
                return res.data.user
            })
            .then(user => {
                if (this.props.authenticated) {
                    if (user.handle != this.props.user.handle) {
                        axios.get(`/friendStatus`, {
                            params: {
                                id: this.props.user.uid,
                                friendId: user.userId
                            }
                        })
                        .then(res => {
                            this.setState({ 
                                loading: false,
                                userProfile: user,
                                friendStatus: res.data.friendStatus,
                                refresh: true,
                                handle: this.props.match.params.userHandle
                             })
                        })
                    } else {
                        this.setState({ 
                            loading: false,
                            userProfile: user,
                            friendStatus: 'notFriends',
                            refresh: true,
                            handle: this.props.match.params.userHandle
                         })
                    }
                } else {
                    this.setState({
                        loading: false,
                        userProfile: user,
                        friendStatus: 'notFriends',
                        refresh: true,
                        handle: this.props.match.params.userHandle
                    })
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({ 
                    loading: false,
                    userProfile: {},
                    fetched: true,
                    handle: this.props.match.params.userHandle
                 })

            })
    }

    sendFriendRequest() {
        axios.post('/newFriendRequest', {
            params: {
                id: this.props.user.uid,
                friendId: this.state.userProfile.userId
            }
        })
        .then(res => {
            this.setState({ friendStatus: 'sent' })
        })
        .catch(err => {
            console.log(err)
        })
    }

    acceptFriendRequest() {
        axios.post('/acceptFriendRequest', {
            params: {
                requesterId: this.state.userProfile.userId,
                acceptingId: this.props.user.uid
            }
        })
        .then(res => {
            this.setState({ friendStatus: 'friends' })
        })
        .catch(err => {
            console.log(err)
        })
    }

    cancelFriendRequest() {
        axios.delete('/cancelFriendRequest', {
            params: {
                id: this.props.user.uid,
                friendId: this.state.userProfile.userId
            }
        })
        .then(res => {
            this.setState({ friendStatus: 'notFriends' })
        })
        .catch(err => {
            console.log(err)
        })
    }

    denyFriendRequest() {
        axios.delete('/cancelFriendRequest', {
            params: {
                id: this.state.userProfile.userId,
                friendId: this.props.user.uid
            }
        })
        .then(res => {
            this.setState({ friendStatus: 'notFriends' })
        })
        .catch(err => {
            console.log(err)
        })
    }

    resetRefresh() {
        this.setState({ refresh: false })
    }

    handleSwitchToLists() {
        this.setState({ activeView: 'wishlists' })
    }

    handleSwitchToClaimed() {
        this.setState({ activeView: 'claimed' })
    }

    render() {
        const { userHandle } = this.props.match.params;
        const auth = this.props.authenticated
        const user = this.props.user
        let myProfile
        if (user && userHandle) {
            myProfile = (auth && userHandle.toLowerCase() == user.handle.toLowerCase())
        }

        if (this.state.loading) {
            return (
                <ProfileSkeleton />
            )
        } else {
            if (this.state.userProfile.handle) {
                return (
                    <div>
                        { myProfile ?
                            <AuthHeader 
                                user={this.state.userProfile} 
                                activeView={this.state.activeView}
                                handleSwitchToLists={this.handleSwitchToLists}
                                handleSwitchToClaimed={this.handleSwitchToClaimed}
                            />
                        :
                            <Header user={this.state.userProfile} />
                        }
                        { this.state.activeView == 'wishlists' ?
                        <AuthLists 
                            auth={this.props.authenticated}
                            myProfile={myProfile} 
                            friendStatus={this.state.friendStatus}
                            user={this.state.userProfile}
                            refresh={this.state.refresh}
                            resetRefresh={this.resetRefresh}
                            sendFriendRequest={this.sendFriendRequest}
                            acceptFriendRequest={this.acceptFriendRequest}
                            cancelFriendRequest={this.cancelFriendRequest}
                            denyFriendRequest={this.denyFriendRequest}
                        /> :
                        <ClaimedWishes userId={this.props.user.uid} />
                        }

                    </div>
                )
            } else {
                return <div>Profile not found...</div>
            }
        }
    }
}

const mapStateToProps = state => {
    return {
        authenticated: state.userReducer.authenticated,
        user: state.userReducer.user
    }
}

export default connect(mapStateToProps, null)(profile)
