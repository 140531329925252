import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'

const styles = theme => ({
    friendHolder: {
        borderTop: '1px solid lightgrey',
        padding: '5px 0px 10px 0px',
        position: 'relative'
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        textDecoration: 'none'
    },
    nameHolder: {
        textDecoration: 'none'
    },
    name: {
        fontWeight: 'bold',
        color: 'rgba(0, 0, 0, 0.87)',
        textDecoration: 'none'
    },
    handle: {
        fontWeight: '400',
        color: 'grey',
        textDecoration: 'none'
    },
    buttonHolder: {
        position: 'absolute',
        right: '0px',
        top: '43%',
        transform: 'TranslateY(-50%)'
    }
})

export class Collaborator extends Component {
    constructor(props) {
        super(props)

        this.handleAdd = this.handleAdd.bind(this)
        this.handleRemove = this.handleRemove.bind(this)
    }

    handleAdd() {
        this.props.handleCollaboratorAdd(this.props.friend)
    }

    handleRemove() {
        this.props.handleCollaboratorRemove(this.props.friend)
    }

    render() {
        const { classes, friend } = this.props

        return (
            <Grid className={classes.friendHolder} item container direction='row' justify='flex-start' alignItems='center' spacing={1}>
                <Grid item>
                    <Avatar className={classes.avatar} src={friend.profileImage} component={Link} to={`/@${friend.handle}`}>
                        { friend.firstName ?
                        friend.firstName[0] + friend.lastName[0] :
                        '- -'}
                    </Avatar>
                </Grid>
                <Grid item className={classes.nameHolder} component={Link} to={`/@${friend.handle}`}>
                    <Grid item>
                        <Typography className={classes.name}>{friend.firstName} {friend.lastName}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.handle} variant='caption'>@{friend.handle}</Typography>
                    </Grid>
                </Grid>
                <Grid item className={classes.buttonHolder}>
                    <Grid item>
                        { this.props.friend.status === 'added' ?
                        <Button size='small' variant='outlined' style={{color: 'red'}} onClick={this.handleRemove} >Remove</Button>
                        :
                        <Button size='small' variant='contained' color='primary' onClick={this.handleAdd} >Add</Button>
                        }
                    </Grid> 
                </Grid>
            </Grid>
        )
    }
}

Collaborator.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Collaborator)
