import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputAdornment from '@material-ui/core/InputAdornment';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';

import NoImageButton from './NoImageButton'
import ImageButton from './ImageButton'
import AddNewImageButton from './AddNewImageButton'


const styles = {
    imageHolder: {
        height: '75px',
        width: '75px',
        borderRadius: '10px',
        backgroundColor: 'grey',
        cursor: 'pointer'
    },
    img: {
        height: '100%',
        width: '100%',
        objectFit: 'cover'
    },
    active: {
        padding: '2px',
        borderRadius: '10px',
        border: '3px solid #6c5ce7'
    },
    mostDesired: {
        position: 'relative',
        top: '29%'
    },
    icon: {
        display: 'inline-block',
        position: 'relative',
        cursor: 'pointer',
        color: '#6c5ce7'
    },
    iconText: {
        display: 'inline-block',
        position: 'relative',
        transform: 'TranslateY(-25%)',
        marginLeft: '10px'
    }
}

export class ConfirmDetailsDialog extends Component {
    constructor(props) {
        super(props)

    }

    render() {
        const { classes, productData } = this.props

        return (
            <div>
                <DialogTitle id="form-dialog-title">Wish Details</DialogTitle>
                <DialogContent>
                    <Grid container direction='column' justify='center' alignItems='stretch' spacing={2}>
                        <Grid item >
                            <TextField
                                margin="dense"
                                id="name"
                                name='name'
                                label="Wish Name"
                                type="text"
                                fullWidth
                                variant='outlined'
                                value={productData.name}
                                helperText={this.props.errors.name}
                                error={this.props.errors.name ? true: false}
                                onChange={this.props.handleChange}
                            />
                        </Grid>
                        <Grid item container direction='row' justify='space-between' alignItems='stretch' alignContent='stretch'>
                            <Grid item sm={6}>
                                <TextField
                                    margin="dense"
                                    id="price"
                                    name='price'
                                    label="Price"
                                    placeholder='0.00'
                                    type="text"
                                    fullWidth
                                    variant='outlined'
                                    value={productData.price}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }} 
                                    helperText={this.props.errors.name}
                                    error={this.props.errors.name ? true: false}
                                    onChange={this.props.handleChange}
                                />
                            </Grid>
                            <Grid item>
                                <div className={classes.mostDesired}>
                                    { this.props.productData.mostDesired ? 
                                    <FavoriteRoundedIcon className={classes.icon} onClick={this.props.handleMostDesied} /> :
                                    <FavoriteBorderRoundedIcon className={classes.icon} onClick={this.props.handleMostDesied} /> }
                                    <Typography className={classes.iconText}>Most Desired</Typography>
                                </div>
                            </Grid>
                        </Grid>
                        { this.props.stage === 'manual' ?
                        <Grid item>
                            <TextField 
                                margin="dense"
                                id="url"
                                name='url'
                                label="URL"
                                type="text"
                                fullWidth
                                variant='outlined'
                                placeholder='Link to a product (Not Required)'
                                helperText={this.props.errors.name}
                                error={this.props.errors.name ? true: false}
                                onChange={this.props.handleChange}
                            />
                        </Grid> :
                        null }
                        <Grid item>
                            <Typography variant='subtitle1' style={{color: 'grey', textAlign: 'center'}}>Pick an image for your wish</Typography>
                        </Grid>
                        <Grid container item direction='row' justify='center' alignItems='center' spacing={3} >
                            <NoImageButton activeImageIndex={productData.activeImageIndex} handleClick={this.props.handleImageChange} />
                            { this.props.productData.images ? this.props.productData.images.map((image, i) => {
                                return <ImageButton key={i} imageIndex={i+1} activeImageIndex={productData.activeImageIndex} image={image} handleClick={this.props.handleImageChange} />
                            }) : null }
                            <AddNewImageButton handleNewImage={this.props.handleNewImage} />
                        </Grid>
                        <Grid item>
                            <TextField 
                                variant='outlined' 
                                name='notes'
                                fullWidth 
                                label='Wish Notes' 
                                placeholder="For example: 'This one in red', or 'Size medium, please!'" 
                                multiline 
                                rows={3} 
                                onChange={this.props.handleChange}
                            />
                        </Grid>
                    </Grid>
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button variant='contained' onClick={this.props.handleNewWishSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </div>
        )
    }
}

export default withStyles(styles)(ConfirmDetailsDialog)
