import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import FavoriteIcon from '@material-ui/icons/Favorite';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Typography } from '@material-ui/core';

import giftImage from '../../imgs/gift.png'
import WishImage from '../Utils/WishImage'

import axios from 'axios'


const styles = {
    link: {
        textDecoration: 'none',
        color: 'black',
        borderRadius: '20px',
        cursor: 'pointer',
        transition: '0.25s',
        '&:hover': {
            backgroundColor: '#eee'
        }
    },
    holder: {
        position: 'relative',
        height: '200px',
        width: '200px',
        borderRadius: '10px',
        overflow: 'hidden',
        cursor: 'pointer'
    },
    main: {
        position: 'absolute',
        height: '200px',
        width: '200px',
        overflow: 'hidden'
    },
    claimedHolder: {
        position: 'absolute',
        height: '200px',
        width: '200px',
        background: 'rgb(151,51,238)',
        background: 'linear-gradient(45deg, rgba(151,51,238,0.75) 0%, rgba(218,34,255,0.75) 100%)',
        top: '0'
    },
    claimedInner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'Translate(-50%, -50%)',
        textAlign: 'center',
        color: 'white',
        fontWeight: 'bold'
    },
    claimedIcon: {
        fontSize: '2rem'
    },
    topRight: {
        position: 'absolute',
        right: '-2px',
        height: '100px',
        width: '100px',
        overflow: 'hidden'
    },
    bottomRight: {
        position: 'absolute',
        right: '-3px',
        bottom: '-3px',
        height: '100px',
        width: '100px',
        overflow: 'hidden'
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    infoHolder: {
        position: 'relative',
        marginLeft: '8px',
        maxWidth: '190px'
    },
    title: {
        fontWeight: 'bold',
        lineHeight: '1',
        overflowWrap: 'break-word'
    },
    mostDesired: {
        display: 'inline-block',
        height: '.75rem'
    },
    items: {
        position: 'absolute',
        color: 'grey',
        lineHeight: '1'
    },
    notesHolder: {
        position: 'relative',
        maxHeight: '100px',
        backgroundColor: '#eeeeee',
        borderRadius: '10px',
        overflowY: 'auto'
    }
}

const parseSource = storeUrl => {
    let split = storeUrl.split('.')
    let website = split[1]
    let rest = split[2]
    split = rest.split('/')
    let source = website + '.' + split[0]

    return source
}

const trimLongNames = (string) => {
    if (string.length > 82) {
        let shorten = string
        shorten = shorten.slice(0, 83)
        shorten += '...'
        return shorten
    } else {
        return string
    }
}

export class ClaimedWish extends Component {
    constructor(props) {
        super(props)

        this.state = {
            open: false,
            confirmUnclaim: false
        }

        this.handleOpen = this.handleOpen.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.fetchWishlistData = this.fetchWishlistData.bind(this)
        this.confirmUnclaim = this.confirmUnclaim.bind(this)
        this.unconfirmUnclaim = this.unconfirmUnclaim.bind(this)
        this.unclaimWish = this.unclaimWish.bind(this)
        this.handleHashChange = this.handleHashChange.bind(this)
    }

    componentDidMount() {
        this.fetchWishlistData(this.props.wish.ownerId, this.props.wish.listId)
        window.addEventListener("hashchange", this.handleHashChange);
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', this.handleHashChange);
    } 

    handleHashChange() {
        if (window.location.hash === '') {
            this.handleClose()
        }
    }

    fetchWishlistData(ownerId, listId) {
        axios.get('/getClaimedWishInfo', {
            params: {
                ownerId: ownerId,
                listId: listId
            }
        })
        .then(data => {
            this.setState({ 
                firstName: data.data.firstName,
                lastName: data.data.lastName,
                handle: data.data.handle,
                wishlistName: data.data.wishlistName,
                wishlistId: data.data.wishlistId
            })
        })
        .catch(err => {
            console.log(err)
        })
    }

    handleOpen() {
        window.location.hash = `#${this.props.wish._id}`
        this.setState({ open: true })
    }

    handleClose() {
        if (window.location.hash !== '') {
            window.history.back()
        }
        this.setState({ open: false })
    }

    confirmUnclaim() {
        this.setState({ confirmUnclaim: true })
    }
    
    unconfirmUnclaim() {
        this.setState({ confirmUnclaim: false })
    }

    unclaimWish() {
        this.props.handleUnclaimWish(this.props.wish._id)
    }

    render() {
        const { classes } = this.props

        return (
            <Grid item className={classes.link}>
            <Grid item  >
                <div className={classes.holder} onClick={this.handleOpen}>
                    <div className={classes.main}>
                        <WishImage src={this.props.wish.imageUrl ? this.props.wish.imageUrl : giftImage} />
                    </div>
                </div>
                <div className={classes.infoHolder}>
                    <p className={classes.title}>{ this.props.wish.mostDesired ? <FavoriteIcon className={classes.mostDesired} color='primary' /> : null }{trimLongNames(this.props.wish.name)}</p>
                    <small className={classes.items}>{this.props.wish.price ? '$' : null }{this.props.wish.price}</small>
                </div>
            </Grid>
            <Dialog 
                    open={this.state.open} 
                    onClose={this.handleClose} 
                    fullWidth
                    maxWidth='md'
                    scroll='body'
                    aria-labelledby="form-dialog-title">
                        <DialogContent dividers={true}>
                            <Grid container style={{padding: '25px 0px'}} direction='row' justify='center' alignItems='center' spacing={3}>
                                <Grid item sm={4}>
                                    <div className={classes.imgHolder}>
                                        <WishImage src={this.props.wish.imageUrl ? this.props.wish.imageUrl : giftImage} rounded />
                                    </div>
                                </Grid>
                                <Grid item sm={8} container direction='column' justify='stretch' alignItems='center' spacing={2}>
                                    <Grid item>
                                        <Typography variant='h6' style={{textAlign: 'center'}}>{this.props.wish.name}</Typography>
                                    </Grid>
                                    <Grid item container direction='row' justify='center' alignItems='center'>
                                        { this.props.wish.price ? 
                                        <Grid item>
                                            <Typography>${this.props.wish.price}{this.props.wish.storeUrl ? ' | ' : null}</Typography>
                                        </Grid> :
                                        null }
                                        { this.props.wish.storeUrl ?
                                        <Grid item>
                                            <Typography>{parseSource(this.props.wish.storeUrl)}</Typography>
                                        </Grid> :                                       
                                        null}   
                                    </Grid>
                                    <Grid item>
                                        <Typography color='primary' style={{fontWeight: 'bold', cursor: 'pointer', textDecoration: 'none'}} component={Link} to={`/@${this.state.handle}/wishlist/${this.state.wishlistId}`}>{this.state.firstName} {this.state.lastName}'s {this.state.wishlistName}</Typography>
                                    </Grid> 
                                    <Grid item container direction='column' justify='stretch' alignItems='stretch'>
                                        <Typography variant='subtitle2' >Notes from {this.state.firstName ? this.state.firstName : null}</Typography>
                                        <div className={classes.notesHolder}>
                                            <Typography fullWidth style={{padding: '10px'}}>
                                                {this.props.wish.notes ? 
                                                this.props.wish.notes :
                                                <Typography style={{color:'grey'}} >None</Typography>}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    { this.props.wish.storeUrl ? 
                                    <Grid item>
                                        <a target='_blank' href={this.props.wish.storeUrl} style={{textDecoration: 'none'}}>
                                            <Button variant='contained' color='primary'>Buy Online</Button>
                                        </a>
                                    </Grid> :                                   
                                    null }
                                    <Grid item style={{marginTop: '25px'}}>
                                        <Typography >{!this.state.confirmUnclaim ? 'Unclaim this wish?' : 'Are you sure?'}</Typography>
                                    </Grid>
                                    { !this.state.confirmUnclaim ? 
                                        <Grid item >
                                            <Button variant='outlined' style={{color: 'red'}} onClick={this.confirmUnclaim}>Unclaim This Wish</Button>
                                        </Grid> 
                                        :
                                        <Grid item container direction='row' justify='center' alignItems='center' spacing={3}>
                                            <Grid item >
                                                <Button onClick={this.unconfirmUnclaim}>Cancel</Button>
                                            </Grid> 
                                            <Grid item >
                                                <Button variant='outlined' style={{color: 'red'}} onClick={this.unclaimWish}>Yes, unclaim wish</Button>
                                            </Grid> 
                                        </Grid>                                        
                                    }

                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions style={{padding: '15px'}}>
                            <Grid item container direction='row' justify='flex-end' alignItems='center' spacing={3}>
                                <Grid item>
                                    <Button disableElevation disableRipple onClick={this.handleClose}>Close</Button>
                                </Grid>

                            </Grid>
                        </DialogActions>
                </Dialog>
            </Grid>
        )
    }
}

ClaimedWish.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ClaimedWish)
