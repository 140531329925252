import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Badge from '@material-ui/core/Badge';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import ContactsRoundedIcon from '@material-ui/icons/ContactsRounded';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

import { logoutUser } from '../redux/actions/userActions'


const styles = theme => ({
    avatarHolder: {
        display: 'inline-block',
        marginLeft: '25px'
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        textDecoration: 'none',
        '@media (max-width: 600px)': {
            marginTop: '7px'
        }
    },
    menuIcon: {
        color: '#555',
        display: 'inline-block',
        cursor: 'pointer',
        fontSize: '2rem',
        marginLeft: '25px'
    },
    buttons: {
        marginLeft: '20px',
        '@media (max-width: 600px)': {
            display: 'none'
        }
    },
    drawer: {
        backgroundColor: '#eee',
        color: 'white'
    },
    list: {
        width: '250px',
        marginTop: '50px'
    },
    divider: {
        margin: '25px 0px'
    },
    listIcon: {
        color: theme.palette.primary.main
    },
    listText: {
        fontWeight: 'bold',
        color: '#333'
    },
    badge: {
        right: '-25px',
        top: '15px'
    }
})

export class SignedInNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            open: false
        }

        this.handleLogOut = this.handleLogOut.bind(this);
        this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
        this.handleDrawerClose = this.handleDrawerClose.bind(this);
    }

    handleDrawerOpen() {
        this.setState({ open: true })
    }

    handleDrawerClose() {
        this.setState({ open: false })
    }

    handleLogOut() {
        this.props.logoutUser();
        this.handleDrawerClose();
    }

    render() {
    const { classes } = this.props

        return (
            <Grid item>
                <Grid item container justify='center' alignItems='center'>
                    <Grid item>
                        <Button color='primary' className={classes.buttons} component={Link} to='/friends'>View Friends</Button>
                    </Grid>
                    <Grid item>
                        <Button variant='outlined' color='primary' className={classes.buttons} component={Link} to={`/@${this.props.user.handle}`}>My Wishlists</Button>
                    </Grid>
                    <Grid item>
                        <div className={classes.avatarHolder}>
                            <Avatar id='proflie-avatar' className={classes.avatar} src={this.props.user.profileImage} component={Link} to={`/@${this.props.user.handle}`}>
                                { this.props.user.firstName ?
                                this.props.user.firstName[0] + this.props.user.lastName[0] :
                                '- -'}
                            </Avatar>
                        </div>
                    </Grid>                
                    <Grid item>
                        <Badge color='secondary' badgeContent={this.props.notifications}>
                            <MenuRoundedIcon className={classes.menuIcon} onClick={this.handleDrawerOpen} />
                        </Badge>
                    </Grid>

                    <SwipeableDrawer classes={{paper: classes.drawer}} anchor='right' open={this.state.open} onClose={this.handleDrawerClose}>
                        <List className={classes.list}>
                            <ListItem button component={Link} to={`/@${this.props.user.handle}`} onClick={this.handleDrawerClose}>
                                <ListItemIcon><AccountCircleRoundedIcon className={classes.listIcon} /></ListItemIcon>
                                <ListItemText classes={{primary: classes.listText}} primary='My Wishlists' />
                            </ListItem>
                            <ListItem button onClick={this.handleDrawerClose} component={Link} to='/friends'>
                                <ListItemIcon><ContactsRoundedIcon className={classes.listIcon} /></ListItemIcon>
                                <Badge color='secondary' badgeContent={this.props.notifications} classes={{badge: classes.badge}}>
                                    <ListItemText classes={{primary: classes.listText}} primary='Friends' />
                                </Badge>
                            </ListItem>
                            <ListItem button component={Link} to='/wishlist/new' onClick={this.handleDrawerClose}>
                                <ListItemIcon><AddCircleRoundedIcon className={classes.listIcon} /></ListItemIcon>
                                <ListItemText classes={{primary: classes.listText}} primary='New Wishlist' />
                            </ListItem>                                
                        </List>
                        <Divider className={classes.divider} />
                        <List>
                            <ListItem button component={Link} to='/FAQ' onClick={this.handleDrawerClose}>
                                <ListItemIcon><HelpRoundedIcon className={classes.listIcon} /></ListItemIcon>
                                <ListItemText classes={{primary: classes.listText}} primary='FAQ' />
                            </ListItem>
                        </List>
                        <Divider className={classes.divider} />
                        <List>
                            <ListItem button component={Link} to={`/@${this.props.user.handle}/settings`} onClick={this.handleDrawerClose} >
                                <ListItemIcon><SettingsRoundedIcon className={classes.listIcon} /></ListItemIcon>
                                <ListItemText classes={{primary: classes.listText}} primary='My Account' />
                            </ListItem>
                            <ListItem button onClick={this.handleLogOut}>
                                <ListItemIcon><ExitToAppRoundedIcon className={classes.listIcon} /></ListItemIcon>
                                <ListItemText classes={{primary: classes.listText}} primary='Logout' />
                            </ListItem>
                        </List>
                    </SwipeableDrawer>
                </Grid>
            </Grid>
        )
    }
}

SignedInNav.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        notifications: state.userReducer.user.notifications
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logoutUser: () => {
            dispatch(logoutUser());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignedInNav))
