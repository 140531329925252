import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import giftImage from '../../imgs/gift.png'

const styles = {
    imageHolder: {
        height: '65px',
        width: '65px',
        borderRadius: '10px',
        overflow: 'hidden',
        cursor: 'pointer'
    },
    img: {
        height: '100%',
        width: '100%',
        objectFit: 'cover'
    },
    active: {
        padding: '3px',
        borderRadius: '10px',
        border: '3px solid #6c5ce7'
    }
}

export class NoImageButton extends Component {
    constructor(props) {
        super(props)

        this.handleImageClick = this.handleImageClick.bind(this)
    }

    handleImageClick() {
        this.props.handleClick(null, 0)
    }

    render() {
        const { classes } = this.props

        return (
            <Grid item>
                <div className={this.props.activeImageIndex === 0 ? classes.active : null}>
                    <div className={classes.imageHolder} onClick={this.handleImageClick}>
                        <img className={classes.img} src={giftImage} />
                    </div>
                </div>
            </Grid>
        )
    }
}

NoImageButton.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NoImageButton)
