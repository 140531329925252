import React, { Component } from 'react'
import { connect } from 'react-redux'

import WishlistHeader from '../Components/Wishlists/WishlistHeader'
import Wishes from '../Components/Wishlists/Wishes'

import axios from 'axios'


export class wishlist extends Component {
    constructor(props) {
        super(props);

        this.state = {
            owner: {},
            wishlist: {},
            collaborators: [],
            loading: true
        }

        this.getWishlist = this.getWishlist.bind(this)
        this.removeCollaborator = this.removeCollaborator.bind(this)
    }

    componentDidMount() {
        const { userHandle } = this.props.match.params;
        const { id } = this.props.match.params;
        this.getWishlist(id, userHandle);
    }

    getWishlist(id, handle) {
        axios.get('/getFullWishlistById', {
            params: {
                id: id
            }
        })
        .then(wishlist => {
            this.setState({
                wishlist: wishlist.data.wishlist, 
                owner: wishlist.data.owner, 
                collaborators: wishlist.data.collaborators,
                loading: false
            })
        })
        .catch(err => {
            console.error(err)
            this.setState({
                wishlist: {},
                loading: false
            })
        })
    }

    getOwner(handle) {
        axios.get(`/getUserByHandle/${handle}`)
        .then(res => {
            return res.data.user
        })
        .then(user => {
            this.setState({ 
                owner: user,
                loading: false
             })
        })
        .catch(err => {
            console.log(err);
            this.setState({ 
                owner: {},
                loading: false
             })
        })
    }

    removeCollaborator(collaboratorId) {
        axios.post('/removeCollaborator', {
            wishlistId: this.state.wishlist._id,
            collaboratorId: collaboratorId
        })
        .then(() => {
            let newCollaborators = this.state.collaborators
            let index = 0

            for (let i = 0; i < newCollaborators.length; i++) {
                if (newCollaborators[i]._id === collaboratorId) {
                    index = i
                    break
                }
            }
            
            newCollaborators.splice(index, 1)

            this.setState({ collaborators: [...newCollaborators] })
        })
        .catch(err => {
            console.log(err)
        })
    }

    render() {
        const wishlist = this.state.wishlist
        const myProfile = this.props.user.uid === wishlist.ownerId
        if (!this.state.loading) {
            return (
                <div>
                    <WishlistHeader wishlist={this.state.wishlist} myProfile={myProfile} owner={this.state.owner} collaborators={this.state.collaborators} removeCollaborator={this.removeCollaborator} />
                    <Wishes myProfile={myProfile} wishlist={this.state.wishlist._id} owner={this.state.owner} collaborators={this.state.collaborators} auth={this.props.authenticated} uid={this.props.user.uid} />
                </div>
            )
        } else {
            return (
                <p>Loading...</p>
            )
        }

    }
}

const mapStateToProps = (state) => {
    return {
        authenticated: state.userReducer.authenticated,
        user: state.userReducer.user
    }
}

export default connect(mapStateToProps, null)(wishlist)
