import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core';

const styles = {
    pinTextBox: {
        top: '-10px'
    }
}

const isEmpty = string => {
    if (string.trim() === '') {
        return true
    } else {
        return false
    }
}

const verifyPin = pin => {
    var re = /^[a-zA-Z0-9]+$/
    return re.test(pin);
}

const checkPinLength = pin => {
    if (pin.trim().length < 4 || pin.trim().length > 6) {
        return false
    } else {
        return true
    }
}

export class ClaimButtons extends Component {
    constructor(props) {
        super(props)

        this.state = {
            usingPIN: false,
            pin: '',
            errors: {}
        }

        this.switchToPin = this.switchToPin.bind(this)
        this.handleAuthedClaim = this.handleAuthedClaim.bind(this)
        this.handleAuthedUnclaim = this.handleAuthedUnclaim.bind(this)
        this.handlePinClaim = this.handlePinClaim.bind(this)
        this.handlePinUnclaim = this.handlePinUnclaim.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    switchToPin() {
        this.setState({ usingPIN: true })
    }

    handleAuthedClaim() {
        console.log('Calling Auth Claim!')
        this.props.handleClaimWish(this.props.wishId, this.props.user.uid)
    }

    handleAuthedUnclaim() {
        this.props.handleUnclaimWish(this.props.wishId, this.props.user.uid)
    }

    handlePinClaim() {
        let pin = this.state.pin
        let cleanPin = pin.trim()
        let errors = {}

        if (isEmpty(cleanPin)) {
            errors.pin = 'Please enter a PIN'
        } else if (!verifyPin(cleanPin)) {
            errors.pin = 'PIN must only use numbers or letters'
        } else if (!checkPinLength(cleanPin)) {
            errors.pin = 'PIN must be between 4-6 characters'
        }

        if (Object.keys(errors).length > 0) {
            this.setState({ errors: errors })
        } else {
            this.props.handleClaimWish(this.props.wishId, cleanPin)
        }
    }

    handlePinUnclaim() {
        let pin = this.state.pin
        let cleanPin = pin.trim()
        let errors = {}

        if (isEmpty(cleanPin)) {
            errors.pin = 'Please enter a PIN'
        } else if (!verifyPin(cleanPin)) {
            errors.pin = 'PIN must only use numbers or letters'
        } else if (!checkPinLength(cleanPin)) {
            errors.pin = 'PIN must be between 4-6 characters'
        }

        if (Object.keys(errors).length > 0) {
            this.setState({ errors: errors })
        } else {
            this.props.handleUnclaimWish(this.props.wishId, cleanPin)
        }
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        this.props.handlePinErrorReset()

        this.setState({
            [name]: value,
            errors: {
                ...this.state.errors,
                [name]: null
            }
        })
    }

    render() {
        const { classes } = this.props

        if (this.props.auth) {
            if (this.props.claimed) {
                return (
                    <Grid item container direction='row' justify='center' >
                        <Grid item>
                            {this.props.claimed == this.props.user.uid ? 
                            <Button variant='contained' onClick={this.handleAuthedUnclaim} disableFocusRipple disableRipple>Unclaim Wish</Button> :
                            <Button variant='contained' disabled>Wish Already Claimed</Button>}
                        </Grid>
                    </Grid>
                )
            } else {
                return (
                    <Grid item container direction='row' justify='center' spacing={3}>
                        <Grid item>
                            <Button variant='contained' color='primary' onClick={this.handleAuthedClaim} disableFocusRipple disableRipple>Claim Wish</Button>
                        </Grid>
                    </Grid>
                )
            }
        } else {
            if (this.props.claimed) {
                if (!this.state.usingPIN) {
                    return (
                        <Grid item container direction='column' justify='center' spacing={2}>
                            <Grid item container direction='row' justify='center' >
                                <Grid item>
                                    <Button variant='contained' disabled>Wish Already Claimed</Button>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography variant='caption' color='primary' onClick={this.switchToPin} style={{fontWeight: 'bold', textAlign: 'center', cursor: 'pointer'}}>I claimed this wish using a PIN</Typography>
                            </Grid>
                        </Grid>
                    )
                } else {
                    return (
                        <Grid item container direction='column' justify='center' spacing={2}>
                            <Grid item>
                                <Typography variant='caption'>To unclaim this wish, please enter the PIN you used when claiming this wish</Typography>
                            </Grid>
                            <Grid item container direction='row' justify='center' spacing={3} >
                                <Grid item>
                                    <TextField 
                                        className={classes.pinTextBox}
                                        name='pin'
                                        label='PIN'
                                        placeholder='1a2b3c'
                                        helperText={this.state.errors.pin || this.props.pinError}
                                        error={this.state.errors.pin || this.props.pinError ? true: false}
                                        onChange={this.handleInputChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' color='primary' disableFocusRipple disableRipple onClick={this.handlePinUnclaim}>Unclaim Using PIN</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }

            } else {
                if (!this.state.usingPIN) {
                    return (
                        <Grid item container direction='column' justify='center'spacing={2} >
                            <Grid item>
                                <Typography variant='caption'>You must be logged in to track your claimed gifts, but you can use a pin to claim the gift without signing up</Typography>
                            </Grid>
                            <Grid item container direction='row' justify='center' spacing={3}>
                                <Grid item>
                                    <Button variant='contained' color='primary' disableFocusRipple disableRipple component={Link} to='/login'>Log In</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' color='primary' disableFocusRipple disableRipple onClick={this.switchToPin}>Use PIN</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                } else {
                    return (
                        <Grid item container direction='column' justify='center' spacing={2}>
                            <Grid item>
                                <Typography variant='caption'>Your PIN must be 4-6 numbers or letters (or a mix of the two). If you would like to unclaim this item in the future you will need to use this same PIN.</Typography>
                            </Grid>
                            <Grid item container direction='row' justify='center' spacing={3}>
                                <Grid item>
                                    <TextField 
                                        className={classes.pinTextBox}
                                        name='pin'
                                        label='PIN'
                                        placeholder='1a2b3c'
                                        helperText={this.state.errors.pin}
                                        error={this.state.errors.pin ? true: false}
                                        onChange={this.handleInputChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' color='primary' disableFocusRipple disableRipple onClick={this.handlePinClaim}>Claim With PIN</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
            }
        }

    }
}

const mapStateToProps = state => {
    return {
        auth: state.userReducer.authenticated,
        user: state.userReducer.user
    }
}

export default connect(mapStateToProps, null)(withStyles(styles)(ClaimButtons))
