import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';

const styles = {
    imageHolder: {
        height: '65px',
        width: '65px',
        borderRadius: '10px',
        backgroundColor: 'lightgrey',
        cursor: 'pointer'
    },
    icon: {
        fontSize: '1.5rem',
        color: 'grey',
        position: 'relative',
        left: '50%',
        top: '50%',
        transform: 'Translate(-50%, -50%)'
    }
}

export class AddNewImageButton extends Component {
    constructor(props) {
        super(props)

        this.handleEditPicture = this.handleEditPicture.bind(this)
        this.handleImageChange = this.handleImageChange.bind(this)
    }

    handleEditPicture() {
        const fileInput = document.getElementById('imageInput');
        fileInput.click();
    };

    handleImageChange(e) {
        const image = e.target.files[0];

        this.props.handleNewImage(image)
    }

    render() {
        const { classes } = this.props

        return (
            <Grid item>
                <div className={classes.imageHolder} onClick={this.handleEditPicture}>
                    <PublishRoundedIcon className={classes.icon} />
                    <input
                        type="file"
                        id="imageInput"
                        hidden="hidden"
                        accept='image/*'
                        onChange={this.handleImageChange}
                    />
                </div>
            </Grid>
        )
    }
}

AddNewImageButton.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AddNewImageButton)
