import * as firebase from 'firebase'

var firebaseConfig = {
    apiKey: "AIzaSyCZAaf0FkWLlCJ1sdtSANXPvaOK4QEJWe4",
    authDomain: "wishit-66302.firebaseapp.com",
    databaseURL: "https://wishit-66302.firebaseio.com",
    projectId: "wishit-66302",
    storageBucket: "wishit-66302.appspot.com",
    messagingSenderId: "897558086509",
    appId: "1:897558086509:web:528c2dbb69c505bd145064"
  };
  // Initialize Firebase
  if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
  }

  export default firebase
