import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'

import FriendOptions from './FriendOptions'

const styles = theme => ({
    friendHolder: {
        borderTop: '1px solid lightgrey',
        padding: '5px 0px 10px 0px',
        position: 'relative'
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        textDecoration: 'none'
    },
    nameHolder: {
        textDecoration: 'none'
    },
    name: {
        fontWeight: 'bold',
        color: 'rgba(0, 0, 0, 0.87)',
        textDecoration: 'none'
    },
    handle: {
        fontWeight: '400',
        color: 'grey',
        textDecoration: 'none'
    },
})

export class Friend extends Component {
    constructor(props) {
        super(props)

        this.state = {
            moreOptions: false
        }

        this.handleMoreOptions = this.handleMoreOptions.bind(this)
    }

    handleMoreOptions() {
        this.state.moreOptions ? this.setState({ moreOptions: false }) : this.setState({ moreOptions: true })
    }

    render() {
        const { classes, friend } = this.props

        return (
            <Grid className={classes.friendHolder} item container direction='row' justify='flex-start' alignItems='center' spacing={1}>
                <Grid item>
                    <Avatar className={classes.avatar} src={friend.profileImage} component={Link} to={`/@${friend.handle}`}>
                        { friend.firstName ?
                        friend.firstName[0] + friend.lastName[0] :
                        '- -'}
                    </Avatar>
                </Grid>
                <Grid item className={classes.nameHolder} component={Link} to={`/@${friend.handle}`}>
                    <Grid item>
                        <Typography className={classes.name}>{friend.firstName} {friend.lastName}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.handle} variant='caption'>@{friend.handle}</Typography>
                    </Grid>
                </Grid>
                <FriendOptions 
                    status={friend.status} 
                    friend={friend} 
                    acceptFriendRequest={this.props.acceptFriendRequest} 
                    unfriend={this.props.unfriend} 
                    cancelFriendRequest={this.props.cancelFriendRequest}
                    declineFriendRequest={this.props.declineFriendRequest}
                    sendFriendRequest={this.props.sendFriendRequest}
                    />
            </Grid>
        )
    }
}

Friend.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Friend)
